.notFountContainer {
  height: 100vh;
}
.jc-container {
  background: #fff9ef !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.jc-elevator {
  background: #fff9ef;
  width: 600px;
  height: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-left: 10px solid #0f4326;
  border-right: 10px solid #0f4326;
  border-top: 10px solid #0f4326;
}
.jc-floor {
  position: relative;
  top: 3%;
  left: 43%;
  background: #fff9ef;
  height: 30px;
  width: 60px;
  border: 2px solid #abb7b7;
}
.jc-floor h3 {
  color: #808080;
  position: absolute;
  top: -65%;
  left: 22.5%;
  text-align: center;
  font: 25px;
}
.jc-doors {
  z-index: 1;
  background: #fff9ef;
  width: 500px;
  height: 540px;
  position: absolute;
  bottom: 0;
  left: 7.5%;
  overflow: hidden;
  border-left: 10px solid #0f4326;
  border-right: 10px solid #0f4326;
  border-top: 10px solid #0f4326;
}
.jc-doors:before {
  content: "";
  background: #f4f2f2;
  border-right: 10px solid #0f4326;
  width: 240px;
  height: 540px;
  position: absolute;
  top: 0;
  left: 0;
  animation: left 1s linear 0.5s forwards;
}
.jc-doors:after {
  content: "";
  background: #f4f2f2;
  border-left: 10px solid #0f4326;
  width: 240px;
  height: 540px;
  position: absolute;
  top: 0;
  right: 0;
  animation: right 1s linear 0.5s forwards;
}

.test {
  scale: 0;
  animation: text 1s linear 0.5s forwards;
}
.jc-doors div {
  position: relative;
  z-index: -1;
  top: 20%;
  color: #808080;
  text-align: center;
  line-height: 2;
}
.jc-doors div:nth-child(1) {
  font-size: 8rem;
  font-weight: 700;
  color: #e3ac43;
  margin-top: -3.8rem;
}
.jc-doors div:nth-child(2) {
  font-size: 1.8rem;
  font-weight: 500;
  margin-top: -4.8rem;
  color: #1d1d1d;
}
.jc-doors p {
  width: 96%;
  font-size: 1rem;
  font-weight: 500;
  color: #1d1d1d;
  text-align: center;
}
.jc-switch {
  position: absolute;
  background: #dadfe1;
  top: 45%;
  left: 103%;
  height: 50px;
  width: 30px;
  border: 2px solid #abb7b7;
}
.jc-switch a:nth-child(1) {
  position: absolute;
  top: 15%;
  left: 12%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #808080;
}
.jc-switch a:nth-child(2) {
  content: "";
  position: absolute;
  bottom: 15%;
  left: 12%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #808080;
}

.not-found-logo-image {
  justify-content: center;
  align-items: center;
  width: 120px;
  display: flex;
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@keyframes left {
  0% {
    left: 0;
  }
  100% {
    left: -60%;
  }
}
@keyframes right {
  0% {
    right: 0;
  }
  100% {
    right: -60%;
  }
}

@keyframes text {
  0% {
    scale: 0;
  }

  100% {
    scale: 1;
  }
}

.not-found-redirect-button {
  margin-top: -7rem;
}
.not-found-redirect-button button {
  padding: 12px 80px;
  border-radius: 5px;
  border: 1px solid #f8ac1d;
  background: transparent;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.1s ease-in-out;
  transition-delay: 0.1s;
}
.not-found-redirect-button button:hover {
  background: #f8ac1d;
}
