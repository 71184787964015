.LoginPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url("../Assets//Images/backgroudImageLoginPage.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  background-origin: content-box;
}

.LoginPageBoxedContainer {
  width: 45%;
  /* background-color: #ffffff 0% 0% no-repeat padding-box; 
  border-radius: 10px;
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px); 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);  */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0rem;
  background: radial-gradient(#fff2 30%, rgba(255, 255, 255, 0.408));
  backdrop-filter: blur(15px);
  filter: opacity(1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transform: translateY(25px);
  gap: 1rem;
  transition: transform 0.25s;
  border-radius: 8px;
}

.loginHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loginHeading p {
  color: #1d1d1d;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 0.5rem;
  font-size: 1.4rem;
  font-family: "Poppins";
  font-weight: 500;
  text-transform: uppercase;
}
.loginInpuitField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 18px;
  margin-top: 1.8rem;
}
.loginInpuitField input {
  padding: 14px 16px;
  border-radius: 4px;
  outline: none;
  border: none;
  box-shadow: 1px 2px 5px #00000029;
  width: 50%;
  font-size: 1rem;
  font-family: "poppins";
}
.LoginPasswordInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.LoginPasswordInput p {
  font-size: 15px;
  margin-left: 18.5rem;
  margin-top: 0.5rem;
  font-weight: 400;
  color: #f8ac1d;
  cursor: pointer;
}
.loginButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.loginButton button {
  padding: 14px 18px;
  width: 25%;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 18px;
  text-align: center;
  background: #f8ac1d;
  cursor: pointer;
}

.loginButton a {
  padding: 14px 18px;
  width: 25%;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 18px;
  text-align: center;
  background: #f8ac1d;
  cursor: pointer;
  color: #1d1d1d;
}

.passwordInputIcon {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  border-radius: 4px;
  outline: none;
  border: none;
  box-shadow: 1px 2px 5px #00000029;
  width: 50%;
  font-size: 1rem;
  font-family: "poppins";
  background-color: white;
}
.passwordInputIcon input {
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0px 0px;
  margin-left: 0px;
}
.passwordEyeIcon {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
.passwordAssistanceHeading {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  gap: 0.3rem;
}
.passwordAssistanceHeading p:first-child {
  font-size: 1.4rem;
  color: #1d1d1dde;
  font-weight: 500;
}
.passwordAssistanceHeading p:last-child {
  font-size: 0.8rem;
  color: #1d1d1dde;
  font-family: "Poppins";
  font-weight: 500;
}
.sendOtpHeading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.SignInLink a {
  font-size: 0.9rem;
  text-decoration: underline;
  color: #f8ac1d;
  cursor: pointer;
}
.SignInLink {
  margin-top: 2rem;
  font-size: 0.9rem;
  text-decoration: underline;
  color: #f8ac1d;
  cursor: pointer;
}

.ReEnterPasswordInpuitField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 3px;
  margin-top: 1.8rem;
}
.ReEnterPasswordInpuitField input {
  padding: 14px 16px;
  border-radius: 4px;
  outline: none;
  border: none;
  box-shadow: 1px 2px 5px #00000029;
  width: 50%;
  font-size: 1rem;
  font-family: "poppins";
}
.ReEnterPasswordInpuitField p {
  font-size: 1rem;
  font-weight: 400;
  margin-left: 51%;
  width: 100%;
}

.ReEnterPasswordInpuitField {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  border-radius: 4px;
  outline: none;
  border: none;
  box-shadow: 1px 2px 5px #00000029;
  width: 50%;
  font-size: 1rem;
  font-family: "poppins";
  background-color: white;
}
.ReEnterPasswordInpuitField input {
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0px 0px;
  margin-left: 0px;
}
.insideP {
  font-size: 0.8rem;
  margin-top: 0.3rem;
  width: 50%;
  color:#f8ac1d ;
}
