.circular-progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;



}


.progress-ring {
  position: relative;
  width: 50px;
  height: 50px;
}

.progress-ring svg {
  transform: rotate(-90deg);

}

.progress-ring circle {
  fill: none;
  stroke-width: 5;
  box-shadow: inset 1px 2px 3px #00000029;

}

.progress-ring .background {
  stroke: #fff9ef;
  box-shadow: inset 1px 2px 3px #00000029;

}

.progress-ring .progress {
  stroke: #f8ac1d;
  transition: stroke-dasharray 0.3s;
  box-shadow: inset 1px 2px 3px #00000029;

}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.7rem;
}

/*----------------------------------------------------------------------------- media query for final responsive ness here by aayush malviya 26-07-2024 -------------------------------------------------------------------------------------- */

@media only screen and (min-width: 769px) and (max-width: 1550px) {
  .circular-progress-bar {
    margin-bottom: 1rem;
    margin-left: -0.5rem;
  }
}

/*-----------------------------------------------------------------------------end of media query for final responsive ness here by aayush malviya 26-07-2024 -------------------------------------------------------------------------------------- */