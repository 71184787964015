body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff9ef;
  scroll-behavior: smooth;
  cursor: default;

  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  z-index: 0;


}

code {
  font-family: "Poppins", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

/* Import Poppins font from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

/* ---------------- Author:- Armaan Singh --------------------------------*/

/* membership loader start */

.loder_Container {
  display: flex;
  height: 100%;
  align-items: center;
}

.loaderMembership {
  position: relative;
  margin-left: 50%;
  display: flex;
}

.dot_Loader {
  width: 0.7rem;
  height: 0.7rem;
  background-color: #fef3de;
  border-radius: 50%;
  position: absolute;
}

.dot1 {
  animation: gooeyEffect1 0.5s infinite alternate;
}

.dot2 {
  animation: gooeyEffect2 0.5s infinite alternate;
  left: 0.6rem;
}

@keyframes gooeyEffect1 {
  0% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

@keyframes gooeyEffect2 {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.2);
  }
}

/* membership loader ends */

/* ----------------- Membership Card css --------------------------- */

.membershipCards {
  margin-top: 4.8rem;
  transition: all 0.6s ease;
}

.non_expand_gap {
  display: grid;
  grid-template-columns: 49% 49%;
  justify-content: center;
  align-content: center;
  margin-top: 4.8rem;
  /* gap: 2rem; */
  /* margin-left: 2rem; */
  /* margin-right: 2rem; */
  /* margin-bottom: 1.5rem; */
}

.membership_card {
  background-color: #ffffff;
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.8rem;
  border: 1px solid #ffffff;
  box-shadow: 2px 4px 10px #99886b66;
  transition: box-shadow 0.3s ease;
  scale: 1;
  /* height: 41vh; */
  /* background-color: red; */
}

.non_expand_gap .membership_card {
  height: 41vh;

}

.membership_card_expand_non {
  /* height: 17vh; */
  margin: 1rem;
}

.membershipCards_expand .membership_card:hover .membership_card_counts {
  box-shadow: none;
}

.membership_card_warrenty {
  box-shadow: 0px 0px 5px #0f351d;
}

.membership_card_platinum {
  box-shadow: 0px 0px 5px #ff7f00;
}

.membership_card_gold {
  box-shadow: 0px 0px 5px #ff7f00;
}

.membership_card_silver {
  box-shadow: 0px 0px 5px #b7b7b7;
}

.membership_card_warrenty_hover:hover {
  box-shadow: 0px 0px 5px #0f351d;
}

.membership_card_platinum_hover:hover {
  box-shadow: 0px 0px 5px #ff7f00;
}

.membership_card_gold_hover:hover {
  box-shadow: 0px 0px 5px #f8ac1d;
}

.membership_card_silver_hover:hover {
  box-shadow: 0px 0px 5px #b7b7b7;
}

.membership_card_warrenty_shadow:hover {
  box-shadow: 0px 0px 5px #0f351d;
  scale: 0.99;
}

.membership_card_platinum_shadow:hover {
  box-shadow: 0px 0px 5px #ff7f00;
  scale: 0.99;
}

.membership_card_gold_shadow:hover {
  box-shadow: 0px 0px 5px #f8ac1d;
  scale: 0.99;
}

.membership_card_silver_shadow:hover {
  box-shadow: 0px 0px 5px #b7b7b7;
  scale: 0.99;
}

.membership_card_topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 1%;
}

/* .membership_card_topbar_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  } */

.membership_card_title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.5rem;
}

.membership_card_title_non_expand {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.5rem;
}

.membership_card_title_warrenty {
  color: #0f351d !important;
}

.membership_card_title_platinum {
  color: #ff7f00 !important;
}

.membership_card_title_gold {
  color: #f8ac1d !important;
}

.membership_card_title_silver {
  color: #8e8e8e !important;
}

.membership_card_revenue {
  text-transform: uppercase;
  color: #444444;
  font-weight: 500;
  font-size: 0.9rem;
}

.membership_card_counts {
  color: #ffffff;
  /* padding: 1.5% 2.2%; */
  width: 2.7rem;
  height: 2.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: smaller;
  box-shadow: inset 1px 2px 3px #00000029;
  border-radius: 50%;
}

.membership_card_counts_expand {
  width: 1.7rem;
  height: 1.9rem;
}

.membership_card_counts_expanded {
  box-shadow: none;
}

.membership_card_counts_warrenty {
  background-color: #0f351d;
}

.membership_card_counts_platinum {
  background-color: #ff7f00;
}

.membership_card_counts_gold {
  background-color: #f8ac1d;
}

.membership_card_counts_silver {
  background-color: #b7b7b7;
}

.membership_card_stats {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  font-size: medium;
  font-weight: 500;
  color: #444444;
}

.membership_card_expiring {
  width: 47%;
}

.membership_card_expiring_expanded {
  /* height: 41rem; */
  width: 40%;
}

.membership_card_scrollable_non_expand {
  height: 14rem;
}

.membership_card_scrollable_height {
  min-height: 67vh;
  height: 67vh;
}

.membership_card_expiring-title {
  display: flex;
  justify-content: space-between;
  font-size: medium;
  color: #444444;
}

.membership_card_expired-title {
  color: #c10000;
}

.membership_card_scrollable {
  overflow-y: auto;
  margin-top: 5%;
}

.membership_card_scrollable::-webkit-scrollbar {
  margin-top: 1rem;
  width: 2px;
  border-radius: 20px;
}

.membership_card_scrollable::-webkit-scrollbar:hover {
  display: block;
}

.membership_card_scrollable::-webkit-scrollbar-track {
  background: 0;
  margin-top: 12px;
  margin-bottom: 5px;
}

.membership_card_scrollable::-webkit-scrollbar-thumb {
  transition: background-color 0.3s ease;
  border: 1px solid #ffffff;
}

.membership_card_scrollable:hover::-webkit-scrollbar-thumb {
  background: 0;
  margin-top: 7px;
  margin-bottom: 5px;
  display: block;
}

.membership_card_scrollable_warrenty:hover::-webkit-scrollbar-thumb {
  background-color: #0f351d;
  border: 1px solid #0f351d;
}

.membership_card_scrollable_platinum:hover::-webkit-scrollbar-thumb {
  background-color: #ff7f00;
  border: 1px solid #ff7f00;
}

.membership_card_scrollable_gold:hover::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  border: 1px solid #f8ac1d;
}

.membership_card_scrollable_silver:hover::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border: 1px solid #b7b7b7;
}

.membership_card_scrollable_expired:hover::-webkit-scrollbar-thumb {
  background-color: #c10000;
  border: 1px solid #c10000;
}

.membership_card_data_expire {
  margin: 0.6rem 0.4rem 0rem 0.4rem;
  border-radius: 0.6rem;
  padding: 2%;
  position: relative;
  cursor: pointer;
}

.selectedSubCardExpired {
  background-color: #c10000;
}

.selecetedSubCardColor {
  color: #ffffff !important;
}

.seleceted_SubCard_Background_Warrenty {
  background-color: #0f351d;
}

.seleceted_SubCard_Background_Platinum {
  background-color: #ff7f00;
}

.seleceted_SubCard_Background_Gold {
  background-color: #f8ac1d;
}

.seleceted_SubCard_Background_Silver {
  background-color: #b7b7b7;
}

.membership_card_data_display_expired_border {
  border: 1px solid #c10000;
}

.membership_card_data_display_expiring_border {
  border: 1px solid #0f351d;
}

.membership_card_data_display_expiring_border_platinum {
  border: 1px solid #ff7f00;
}

.membership_card_data_display_expiring_border_gold {
  border: 1px solid #f8ac1d;
}

.membership_card_data_display_expiring_border_silver {
  border: 1px solid #b7b7b7;
}

.membership_card_data_display {
  display: flex;
  justify-content: space-evenly;
  color: #444444;
}

.membership_card_data_display_hover {
  display: none;
  cursor: pointer;
  font-weight: 500;
}

.membership_card_data_expire:hover .membership_card_data_display_hover {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  color: #fffdf8;
  border-radius: 0.5rem;
}

.membership_card_data_display_expiring {
  background-color: #0f351d;
}

.membership_card_data_display_expiring_platinum {
  background-color: #ff7f00;
}

.membership_card_data_display_expiring_gold {
  background-color: #f8ac1d;
}

.membership_card_data_display_expiring_silver {
  background-color: #b7b7b7;
}

.membership_card_data_display_expired {
  background-color: #c10000;
}

.membership_card_data_jon {
  align-items: center;
  border-right: 1px solid #e5e5e5;
  width: 22%;
  padding-right: 1rem;
}

.membership_card_data_info {
  display: flex;
  flex-direction: column;
}

.membership_card_data_jon_title {
  font-weight: 700;
  transform: translateY(0.2rem);
  font-size: 0.7rem;
  text-transform: uppercase;
}

.JON {
  font-weight: 700;
  font-size: 1rem;
  color: #444444;
}

.membership_card_data_jon_title_warrenty {
  color: #0f351d;
}

.membership_card_data_jon_title_platinum {
  color: #ff7f00;
}

.membership_card_data_jon_title_gold {
  color: #f8ac1d;
}

.membership_card_data_jon_title_silver {
  color: #8e8e8e;
}

.membership_card_data_jon_title_expiry {
  color: #c10000;
}

.membership_card_data_address {
  font-size: 0.7rem;
}

.nameAddress {
  width: 60%;
}

.membership_card_scrollable {
  /* height: 15rem;
  max-height: 15rem; */
  overflow-y: auto;
  /* background-color: red; */

}

/* <-------------------------------media query for membership card ---------------------------------------> */

@media (max-width: 1710px) {
  .membership_card_data_jon {
    padding-left: 2%;
  }

  .membership_card_data_info {
    font-size: small;
  }

  .membership_card_data_address {
    font-size: xx-small;
  }
}

/* <-------------------------------media query for membership card ends-----------------------------------> */

/* <------------------------------ membership card expanding css --------------------------------------->*/

.membershipCards_expand {
  display: grid;
  grid-template-columns: 77% 22%;
  gap: 2%;
  align-items: center;
  margin-top: 5.7rem;
}

.membership_card_expand {
  margin: 0rem;
  grid-column: span 1;
  grid-row: span 4;
  transition: border 1s ease;

}

.animation {
  animation: expandAnimation 2s ease-in-out forwards;
}

.animationExpand {
  animation: expandAnimationExpansion 0.6s ease-in-out forwards;
}

.expandCardName {
  font-size: 0.9rem;
  font-weight: 700;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes expandAnimation {
  from {
    transform: translateY(3rem);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes expandAnimationExpansion {
  from {
    opacity: 0.6;
  }

  to {
    opacity: 1;
  }
}

.membership_card_counts_expand {
  color: #ffffff;
  padding: 1% 1.5%;
  border-radius: 50%;
}

.expansion_labels {
  margin-top: 1rem;
  height: 100%;
}

.after_expansion_labels {
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  font-size: smaller;
  color: #444444;
}

.after_expansion_labels_expired {
  color: #c10000;
}

.membership_card_topbar_non_expand {
  padding-bottom: 3%;

}

.membership_card_expand_non:hover {
  transform: translateX(-5%);
  transition: all 0.3s ease;
}



.membership_card_topbar_expand {
  border: none;
}

/* ---------------------total revenue css  ----------------------------------- */

.total_revenue_outer {
  border: 1px solid #ffffff;
  color: #444444;
}

.total_revenue_outer_border {
  border: 1px solid #444444;
}

.total_revenue_outer_shadow {
  box-shadow: 0px 0px 5px #444444;
}

.total_revenue {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.total_revenue_heading {
  font-size: larger;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  text-align: center;
  padding-bottom: 5%;
}

.total_revenue_amount {
  margin-top: 5%;
  font-size: xx-large;
}

/* ---------------------total revenue css ends ----------------------------------- */

/* <------------------------------ membership card expanding css ends --------------------------------------->*/

/* <------------------------------ membership card expanded css start ---------------------------------------->*/

.expandedMembershipheading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.no_expire_membership {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 25%; */
  color: #444444;
  height: 71.8vh;
}

.headingCenter {
  border-bottom: 2px solid #e5e5e5;
  display: flex;
  justify-content: center;
  gap: 01rem;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding-bottom: 0.7rem;
}

.bottomheadingBorder {
  border-bottom: 2px solid #0f351d;
}

/* --------------------------------------------- client details css -------------------------------------------- */

.clientsContainer {
  display: flex;
  justify-content: space-between;
  width: 70%;
  gap: 10%;
  margin-right: 3rem;
}

.clients {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  min-height: 70vh;
  /* height: 67vh; */
  width: 50%;
  margin-top: 1rem;
}

.clientDetailContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  width: 100%;
  color: #444444;
  margin-top: 2rem;
}

.clientDetailLeft {
  width: 40%;
}

.clientDetailRight {
  width: 60%;
}

.clientName {
  font-size: large;
  font-weight: 600;
  text-transform: uppercase;
}

.address {
  font-size: smaller;
  z-index: 0;
  margin-top: 0.5rem;
}

.jonHeading {
  font-weight: 600;
}

.JOB {
  font-size: larger;
}

.clientDetail {
  margin-top: 0.8rem;
}

.historyMain {
  margin-top: 2rem;
  margin-bottom: 10px;
}

.no_history {
  border: 1px solid #444444;
  text-align: center;
  font-size: medium;
  margin-top: 1rem;
  cursor: default;
  padding: 1rem 0rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  color: #444444;
}

.no_history_subHeading {
  font-size: small;
}

.historyContainer {
  overflow-y: scroll;
  min-height: 13vh;
  height: 30vh;
  padding-right: 0.5rem;
}

.historyContainer::-webkit-scrollbar {
  margin-top: 1rem;
  width: 2px;
  border-radius: 20px;
}

.historyContainer::-webkit-scrollbar:hover {
  display: block;
}

.historyContainer::-webkit-scrollbar-track {
  background: 0;
  margin-top: 12px;
  margin-bottom: 5px;
}

.historyContainer::-webkit-scrollbar-thumb {
  transition: background-color 0.3s ease;
}

.historyContainer:hover::-webkit-scrollbar-thumb {
  border: 1px solid #0f351d;
  background: 0;
  margin-top: 7px;
  margin-bottom: 5px;
  display: block;
}

.historyExpiredScroll:hover::-webkit-scrollbar-thumb {
  border: 1px solid #c10000;
}

.history {
  font-size: 0.8rem;
  margin-top: 0.9rem;
  border-radius: 0.3rem;
  cursor: pointer;
}

.historyBorderWarrenty {
  border: 1px solid #0f351d;
}

.historyBorderPlatinum {
  border: 1px solid #ff7f00;
}

.historyBorderGold {
  border: 1px solid #f8ac1d;
}

.historyBorderSilver {
  border: 1px solid #8e8e8e;
}

.historyNumber {
  padding: 0.3rem;
  background-color: #ff7f001a;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.historyNumberWarrenty {
  background-color: #0f351d1a;
}

.historyNumberPlatinum {
  background-color: #ff7f001a;
}

.historyNumberGold {
  background-color: #f8ac1d1a;
}

.historyNumberSilver {
  background-color: #8e8e8e1a;
}

.historyClings {
  display: flex;
  flex-direction: column;
}

.historyDetails {
  display: grid;
  padding: 0.3rem;
  gap: 1rem;
  grid-template-columns: auto auto;
  justify-content: space-between;
}

.rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ratingStar {
  padding-top: 4px;
  padding-right: 4px;
  color: #f8ac1d;
}

.callsContainer {
  /* background-color: lightsalmon; */
  overflow-y: scroll;
  padding-right: 0.5rem;
  min-height: 10vh;
  height: 40vh;
}

.callMissed {
  border: 1px solid #c10000 !important;
}

.callsContainer::-webkit-scrollbar {
  margin-top: 1rem;
  width: 2px;
  border-radius: 20px;
}

.callsContainer::-webkit-scrollbar:hover {
  display: block;
}

.callsContainer::-webkit-scrollbar-track {
  background: 0;
  margin-top: 12px;
  margin-bottom: 5px;
}

.callsContainer::-webkit-scrollbar-thumb {
  transition: background-color 0.3s ease;
}

.callsContainer:hover::-webkit-scrollbar-thumb {
  border: 1px solid #0f351d;
  background: 0;
  margin-top: 7px;
  margin-bottom: 5px;
  display: block;
}

.callsContainer_platinum:hover::-webkit-scrollbar-thumb {
  background-color: #ff7f00;
  border: 1px solid #ff7f00;
}

.callsContainer_gold:hover::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  border: 1px solid #f8ac1d;
}

.callsContainer_silver:hover::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border: 1px solid #b7b7b7;
}

.callScrollExpired:hover::-webkit-scrollbar-thumb {
  border: 1px solid #c10000;
}

.clientDetailCalls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  margin-top: 1.2rem;
  line-height: 2rem;
  border-radius: 0.3rem;
  padding: 0.7% 2%;
  border: 1px solid #444444;
}

.clientCallInputAdd {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  margin-top: 0.9rem;
  /* margin-bottom: 0.1rem; */
  line-height: 2rem;
  border-radius: 0.3rem;
  padding: 0.7% 2%;
  border: 1px solid #444444;
  width: 97%;
}

.clientCallInfo {
  width: 100%;
  border-bottom: 1px solid #444444;
}

.clientNumber {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.callButtons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
}

.callButton {
  width: 100%;
  padding: 1rem;
  border-radius: 0.6rem;
  cursor: pointer;
  font-family: "Poppins";
}

.callNowButton {
  border: 1px solid #0f351d;
  background-color: #0f351d;
  color: #fff9ef;
}

.callNowButtonPlatinum {
  border: 1px solid #ff7f00;
  background-color: #ff7f00;
  color: #fff9ef;
}

.callNowButtonGold {
  border: 1px solid #f8ac1d;
  background-color: #f8ac1d;
  color: #fff9ef;
}

.callNowButtonSilver {
  border: 1px solid #8e8e8e;
  background-color: #8e8e8e;
  color: #fff9ef;
}

.callButtonHover:hover {
  filter: brightness(1.08);
}

.callNowButtonExpired {
  border: 1px solid #c10000;
  background-color: #c10000;
  color: #fff9ef;
}

.addCallButton {
  color: #0f351d;
  background-color: #ffffff;
  border: 1px solid #0f351d;
}

.addCallButton:hover {
  color: #ffffff;
  background-color: #0f351d;
}

.addCallButtonPlatinum {
  color: #ff7f00;
  background-color: #ffffff;
  border: 1px solid #ff7f00;
}

.addCallButtonPlatinum:hover {
  color: #ffffff;
  background-color: #ff7f00;
}

.addCallButtonGold {
  color: #f8ac1d;
  background-color: #ffffff;
  border: 1px solid #f8ac1d;
}

.addCallButtonGold:hover {
  color: #ffffff;
  background-color: #f8ac1d;
}

.addCallButtonSilver {
  color: #8e8e8e;
  background-color: #ffffff;
  border: 1px solid #8e8e8e;
}

.addCallButtonSilver:hover {
  color: #ffffff;
  background-color: #8e8e8e;
}

.addCallButtonExpired {
  color: #c10000;
  background-color: #ffffff;
  border: 1px solid #c10000;
}

.addCallButtonExpired:hover {
  color: #ffffff;
  background-color: #c10000;
}


.offerButton {
  background-color: #ffffff;
  color: #0f351d;
  font-weight: 600;
  border-radius: 0.5rem;
  border: 1px solid #0f351d;
  font-family: "Poppins";
}

.offerButtonExpired {
  color: #c10000;
  border: 1px solid #c10000;
}

.offerButtonExpired:hover {
  background-color: #c10000 !important;
  color: #fff9ef;
}

.offerButtons {
  /* display: flex; */
  display: none;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: -1.3vw;
  /* margin-bottom:2rem; */
  /* margin-bottom:-0.1rem; */
}

.offerButtonContainer {
  /* background-color: red; */
  position: relative;
  height: 4rem;
  margin-bottom: 0.5rem;
}

.offerButtonContainer:hover .offerButtons {
  display: flex;
}

.offerButtons-show {
  display: flex;
}



.offerButtons-hide {
  display: none;
}

.offer {
  width: 100%;
  padding: 0.2rem 0rem;
  cursor: pointer;
}

.offer:hover {
  background-color: #0f351d;
  color: #fff9ef;
}

.offerButtonMain {
  cursor: pointer;
  width: 100%;
  padding: 1rem 0%;
  transition: all 0.6 ease;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 1rem;
  height: 80%;
  position: absolute;
}

.offerMainExpired {
  border: 1px solid #c10000;
  color: #c10000;
}

.offerButtonMain:hover {
  /* color: #fff9ef; */
  background-color: #0f351d;
}

.offerButtonMainGold {
  color: #f8ac1d;
  background-color: #fff9ef;
  border: 1px solid #f8ac1d;
}

.offerButtonMainGold:hover {
  color: #fff9ef;
  background-color: #f8ac1d;
}

.offerButtonMainPlatinum {
  color: #ff7f00;
  background-color: #fff9ef;
  border: 1px solid #ff7f00;
}

.offerButtonMainPlatinum:hover {
  color: #fff9ef;
  background-color: #ff7f00;
}

.offerButtonMainSilver {
  color: #b7b7b7;
  background-color: #fff9ef;
  border: 1px solid #b7b7b7;
}

.offerButtonMainSilver:hover {
  background-color: #b7b7b7;
}

.offerButtonMainExpired:hover {
  color: #fff9ef;
  border: 1px solid #c10000;
  background-color: #c10000;
}

.offerButtonMainSelected {
  background-color: #0f351d;
  color: #fff9ef;
}

.offerButtonMainSelectedGold {
  color: #fff9ef;
  background-color: #f8ac1d;
}

.offerButtonMainSelectedPlatinum {
  color: #fff9ef;
  background-color: #ff7f00;
}

.offerButtonMainSelectedSilver {
  color: #fff9ef;
  background-color: #b7b7b7;
}

.offerButtonMainSelectedExpired {
  color: #fff9ef;
  background-color: #c10000;
}

.NotificationContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid black;
  border-radius: 8px;
}


.notificationmembershipwrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}



.inputNotification {
  padding: 0rem 1rem;
  height: auto;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  margin-top: 1rem;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.8rem;
}

.inputBox {
  outline: none;
  border: none;
  width: 100%;
  margin-right: 1.5rem;
  font-size: medium;
  font-family: "Poppins";
}

.chevronDownMembership {
  position: absolute;
  top: 63%;
  /* top: 20%; */

  right: 6rem;
  transform: translateY(-55%);
  cursor: pointer;
  color: #0f351d;
}

.notificationOptions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.options {
  background-color: #fff9ef;
  border: 1px solid #0f351d;
  border-radius: 0.5rem;
  width: 100%;
}

.option {
  border-bottom: 1px solid #0f351d;
  padding: 0.5rem 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  z-index: 9;
}

.option:last-child {
  border: none;
}

.sendButton {
  padding: 0rem 2rem;
  /* border: 1px solid #0f351d; */
  border-bottom-right-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  border-left: none;
  /* margin-top: 1rem; */
  cursor: pointer;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendButtonWarrenty {
  color: #0f351d;
}

.sendButtonPlatinum {
  color: #ff7f00;
}

.sendButtonGold {
  color: #f8ac1d;
}

.sendButtonSilver {
  color: #8e8e8e;
}

.sendButtonExpired {
  color: #c10000;
}

/* --------------------------------------------- client details css ends --------------------------------------- */
/* --------------------------------------------- calender csss start --------------------------------------- */

.DateHeading {
  border-bottom: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AttendancecalendarMembership {
  position: absolute !important;
  top: 75% !important;
  width: 97% !important;
  top: 0;
}

.Attendance {
  height: 100%;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 30% 60%;
}

.Attendacne-header {
  background-color: #fffbf4;
  border-bottom: none;
  padding: 1rem;
}

#monthYear {
  font-size: 0.8rem;
}

#aprevBtn,
#anextBtn {
  background: none;
  border: none;
  color: #343434;
  cursor: pointer;
  font-size: 0.6rem;
}

/* .aday {
  padding: .2rem;
  text-align: center;
  cursor: pointer;
  font-size: 0.7rem;
  color: black;
  display: flex;
  align-items: end;
  justify-content: center;
 border-radius: 50%;
 

} */

.aday {
  padding: 0.2rem;
  text-align: center;
  cursor: pointer;
  font-size: 0.8rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  /* Example width */
  height: 30px;
  /* Example height */
}

.aday:hover {
  background-color: #fef3de;
  color: #f8ac1d;
}

.adays {
  display: grid;
  grid-template-columns: repeat(7, 9%);
  grid-column-gap: 1rem;
  padding: 1rem 1rem;
  height: 70%;
  position: relative;
}



.adaysMembership {
  margin-top: 2%;
}

.ui-datepicker-prev {
  order: 1;
}

.aday.current {
  /* background-color: #FEF3DE; */
  color: #f8ac1d;
}

.aday.current:hover {
  /* background-color: white; */
  background-color: transparent;
  box-shadow: none;
}

.ui-datepicker-next {
  order: 3;
}

.aday.selected {
  background: #f8ac1d;
  box-shadow: inset 1px 2px 3px #00000080;
  color: black;
}

.days-header {
  /* display: flex; */
  /* justify-content: space-around; */

  font-weight: bold;
  width: 100%;
  padding-left: 1.2rem;
  position: absolute;
  display: grid;
  grid-template-columns: repeat(7, 9%);
  grid-column-gap: 0.9rem;
  font-size: 0.7rem;
  font-weight: 500;
  top: -2%;
}

.date-picker-days-header {
  font-weight: bold;
  width: 100%;
  padding-left: 0.9rem;
  position: absolute;
  display: grid;
  grid-template-columns: repeat(7, 11%);
  grid-column-gap: 0.7rem;

  font-size: 0.8rem;
  font-weight: 300;
  top: 18%;
  color: black;

}

/* .aayush{
  height: 200px;
  width: 200px;
  background-color: red;
} */
/* --------------------------------------------- calender csss ends --------------------------------------- */

/* <------------------------------ membership card expanded css ends ---------------------------------------->*/

/* <-------------------------------Clients CSS Start------------------------------------> */

/* client filter css start  */

.client-filter-option-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  /* gap: 0.5rem; */
}

.client-filter-option {
  margin-top: 0.5rem;
  width: fit-content;
  padding: 0.2rem 0.1rem;
  font-size: 0.6rem !important;
  margin-left: 1rem;
  background-color: #fef3de;
  color: #f8ac1d !important;
  border-radius: 6px !important;
}

/* client filter css ends  */

.ClientCatainer {
  margin-top: 5.5rem;
  width: 95%;
  text-transform: uppercase;
  display: grid;
  gap: 2rem;
  margin-left: 2rem;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-between;
  align-items: center;
  /* gap: 1rem; */
}

.clear_section {
  font-size: small;
  font-weight: 600;
}

.clientCard {
  background-color: #ffffff;
  font-size: small;
  padding: 1.7rem;
  width: 100%;
  border-radius: 1rem;
  box-shadow: 2px 4px 10px #00000029;
  cursor: pointer;
}

.clientCard:hover {
  scale: 1.05;
  transition: 0.2s ease;
}

.clientCardShadowWarrenty {
  box-shadow: inset 0px 0px 15px #0f351d, 2px 4px 10px #00000029;
}

.clientCardShadowPlatinum {
  box-shadow: inset 0px 0px 15px #ff7f0080, 2px 4px 10px #00000029;
}

.clientCardShadowGold {
  box-shadow: inset 0px 0px 15px #f8ac1d80, 2px 4px 10px #00000029;
}

.clientCardShadowSilver {
  box-shadow: inset 0px 0px 15px #b7b7b7, 2px 4px 10px #00000029;
}

.noMembershipP {
  background: #e5e5e5 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 10px #00000029;

}

.clientInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #4545451d;
  padding-bottom: 1rem;
  gap: 0.5rem;
  width: 100%;
}

.clientCards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  height: 2rem;
  width: 100%;
}

.client {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.7rem;
  width: 100%;
}

.locationArrow {
  background-color: black;
  color: white;
  padding: 0.3rem;
  width: 40%;
  height: 40%;
}

.clientInfo2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0rem 0rem 0rem;
}

.client2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: smaller;
  text-transform: capitalize;
}

.Info {
  font-size: medium;
}

/* Table View  */

.table_view,
.spare-part-table_view {
  width: 100%;
  height: 85%;
  position: relative;
  padding: 1.5rem;
  margin-top: 4%;
  padding-bottom: 0.5rem;
}

.client_table_view {
  height: 92%;
}

.spare-part-table_view {
  padding: 0;
  margin-top: 0;
  padding-bottom: 0;
}

.sub_table_view,
.spare-part-sub-table-view {
  height: 100%;
  width: 100%;
  background-color: white;
  position: relative;
  background-color: yellow;
  background: #ffffff;
  box-shadow: 0px 0px 3px #c1c1c1;
  border-radius: 10px;
  padding: 1rem 2rem;
}

.spare-part-sub-table-view {
  box-shadow: none;
  padding: 0rem 0rem;
}

.client_table-container,
.spare-part-table-container {
  max-height: 80vh;
  overflow-y: auto;
  width: 100%;
}

.client_table-container {
  max-height: 82vh;
}

.spare-part-table-container {
  max-height: 35vh;
  overflow-y: auto;
  width: 100%;
}

.client_table-container table,
.spare-part-table-container table {
  width: 100%;
  border-collapse: collapse;
}

.client_table-container thead,
.spare-part-table-container thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9;
  height: 4rem;
}

.spare-part-table-container thead {
  height: 3.5rem;
}

/* set body scroll property starts */
.client_table-container:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d;
}

/* set scroll behaviour  */
.client_table-container::-webkit-scrollbar {
  width: 2px;
}

.client_table-container::-webkit-scrollbar-track {
  background: 0;
  margin-top: 4rem;
  margin-bottom: 10px;
}

.informationTable-loader {
  position: absolute;
  top: 350%;
  left: 50%;
}

.left-align-td-SOS-table td {
  text-align: left;
}


.spare-part-table-container::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  border-radius: 20px;
  border: 1px solid #ffffff;
  transition: background-color 0.3s ease;
}

.spare-part-table-container:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d;
}

/* set scroll behaviour  */
.spare-part-table-container::-webkit-scrollbar {
  width: 2px;
}

.spare-part-table-container::-webkit-scrollbar-track {
  background: 0;
  /* margin-top: 4rem;
  margin-bottom: 10px; */
}

.spare-part-table-container::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  border-radius: 20px;
  border: 1px solid #ffffff;
  transition: background-color 0.3s ease;
}

.client_table-container table th {
  font-weight: 600;
  padding: 1rem 0.4rem;
  font-size: 0.8rem;
  text-align: left;
  font-family: "Poppins";
  color: #444444;
  opacity: 1;
}

.client_table-container table td {
  text-align: start;
  padding: 1.5rem 0.4rem;
  border-bottom: 1px solid #c1c1c180;
  font-size: 0.9rem;
  color: #444444;
  font-weight: 500;
  font-family: "Poppins";
}

.spare-part-table-container table th {
  font-weight: 600;
  padding: 1rem 0.4rem;
  font-size: 0.9rem;
  text-align: left;
  font-family: "Poppins";
  letter-spacing: 0px;
  color: #1d1d1d;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
}

.spare-part-table-container table td {
  text-align: center;
  padding: 1rem 0.4rem;
  border-bottom: 1px solid #c1c1c12c;
  font-size: 0.8rem;
  color: #1d1d1d;
  font-weight: 400;
  font-family: "Poppins";
}

/* <-------------------------------Clients CSS Start Ends -------------------------------> */

/* sidebar SOS button css start */

.circle {
  position: absolute;
  top: 90%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  width: 6rem;
  height: 6rem;
  /* background: #fd2424; */
  /* color: white; */
  color: #fd2424;
  border: 1px solid #fd2424;
  text-align: center;
  line-height: 6rem;
  border-radius: 50%;
  font-size: 1.3rem;
  display: none;

  &:hover {
    cursor: pointer;
  }

  &::after,
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 6rem;
    height: 6rem;
    /* background: #fd242499; */

    border-radius: 50%;
    z-index: -1;
    /* animation: grow 1.3s ease-in-out infinite; */
  }

  &::after {
    /* background: #fd242480; */

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 6rem;
      height: 6rem;
      /* background: #fd242499; */
      border-radius: 50%;
      z-index: -1;
      /* animation: grow 1.3s ease-in-out infinite; */
    }
  }

  &::before {
    /* background: #fd242499; */
    animation-delay: -0.5s;
  }
}

@keyframes grow {
  0% {
    transform: scale(1, 1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
}

/* ---------------------------------------------------------------client form css written by rahul kumar--------------------------------------- */
.add-client-wrapper {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  z-index: 5;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
}

.add-client-modal {
  height: 83%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 85%;
  background: white;
  z-index: 6;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  padding: 0 2rem;
  overflow: hidden;
}

.client-form-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12rem;
  margin-top: 1rem;
}

.client-form-left-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  /* width: 100%; */
}

.client-form-right-container {
  /* width: 100%; */
}

.client-form-details {
  padding: 3rem 2rem 0 2rem;
}

.client-salesman-details {
  padding: 3rem 2rem 0 2rem;
}

.client-membership-document {
  padding: 0 2rem;
}

.client-architect {
  padding: 2rem;
}

.file-uploader {
  margin-top: 0rem;
}

.client-form-details-heading {
  color: #444444;
  font-weight: 500;
  font-size: 1rem;
}

.client-form-input-wrapper {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 46.5% 46.5%;
  grid-column-gap: 3.1rem;
  grid-row-gap: 1.5rem;
  margin-top: 1rem;
}

.client-form-document-input-wrapper {
  display: grid;
  grid-template-columns: 46.5% 46.5%;
  grid-column-gap: 3.1rem;
  margin-top: 0.2rem;
  grid-row-gap: 0.2rem;
}

.client-form-md-discount {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

/* .percentage-input{
 width: 6.06rem;
height: 1.43rem;
border: 1px solid #8E8E8E;
outline: none;
border-radius: 5px;
opacity: 0.5;
text-align: center;
} */

/* ----------------------------------------------------------- */
.input-wrapper {
  position: relative;
  display: inline-block;
}

.percentage-input {
  width: 6.06rem;
  height: 1.43rem;
  border: 1px solid #8e8e8e;
  outline: none;
  border-radius: 5px;
  opacity: 0.5;
  text-align: center;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.percentage-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  transition: all 0.3s ease;
  pointer-events: none;
  margin-top: 0.07rem;
}

.percentage-label-with-value {
  position: absolute;
  top: 50%;
  left: 74%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
  transition: all 0.3s ease;
  pointer-events: none;
}

.input-wrapper.focused .percentage-label {
  left: calc(100% - 1rem);
  transform: translateY(-50%);
  opacity: 1;
}

.percentage-input:focus {
  opacity: 1;
}

/* ------------------------------------------------------ */
.clientDateContainer {
  position: relative;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  margin-top: -0.65rem;
}

.labelContainer {
  font-size: 0.9rem;
  color: #1d1d1d;
}

.inputWrapper {
  position: relative;
}

.inputLabel {
  position: absolute;
  /* left: 0.4em; */
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.3s;
  opacity: 0.5;

}

.inputLabel.hidden {
  opacity: 0;
}

.iconContainer {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.calendarContainer {
  position: absolute;
  z-index: 5;
}

.calendarIcon {
  width: 1.5rem;
  height: auto;
}

.clientDateInput {
  outline: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #44444473;
  cursor: text;
  width: 100%;
  padding-bottom: 0.5rem;
  padding-top: 0.55rem;
  font-size: 1rem;
  color: #444444;
}

/* ------------------------------------------------------ */
/* ----------------------------------------------------------- */
.client-form-input-wrapper>*:nth-child(7) {
  grid-column: 1 / span 2;
}

.client-form-hr {
  color: #444444;
  opacity: 0.54;
}

.client-warranty-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 1rem;
  color: #444444;
}

.warranty-button {
  width: 6rem;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
}

.warranty-button.selected {
  background-color: #f8ac1d;
}

.warranty-button-design {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0.2rem;
  width: 6rem;
  font-size: 1rem;
  font-weight: 500;
}

.client-document {
  margin-top: 2rem;
}




.client-salesman-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 0.6rem;
  color: #444444;
}

.quotation-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.quotation {
  color: #444444;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.md-discount-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2.5rem;
}

.client-architect-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.button-container {
  position: absolute;
  bottom: 0;
  right: 1.68rem;
  padding: 2rem;
  display: flex;
}

.client-form-button {
  /* padding: 1rem 1.2rem; */
  height: 2.6rem;
  width: 7.7rem;
  margin-left: 0.4rem;
  background-color: #fff;

  border-radius: 5px;
  color: #444444;
  cursor: pointer;
}

.client-form-button-red {
  border: 1px solid #c10000;
}

.client-form-button-yellow {
  border: 1px solid #f8ac1d;
}

.client-form-button-submit {
  background-color: #f8ac1d;
  border: none;
}

/* next form elevator css */

.client-form-next-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1rem;
  height: 69vh;
  grid-column-gap: 13rem;
  overflow: hidden;
  width: 100%;
}

.client-form-elevator-details {
  padding: 4rem 2rem;

  /* overflow-y: auto; */
}

.client-elevator-input-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1.8rem;
  margin-top: 1.3rem;
}

/* mm button css */
.mmBtn {
  background-color: #f8ac1d;
  color: #444444;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 0.1rem 0.3rem;
  font-family: "Poppins", sans-serif;
}

.mm-btn-possition {
  position: absolute;
  right: 0;
  top: 0;
}

.mmbtn-parent {
  position: relative;
}

/* capacity-container */

.capacity-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.2rem;
}

.selector-container {
  border: 1px solid #b7b7b7;
  color: #1d1d1d;
  border-radius: 10px;
  font-size: 1rem;

  display: flex;
  width: 9rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.12rem;
  transition: all ease-in 0.2s;
  height: 2rem;
}

.selector-child {
  width: 50%;
  display: inline-block;
  text-align: center;
  font-size: 0.8rem;
  padding: 0.2rem;
  color: #1d1d1d;
  cursor: pointer;
  transition: all ease-in 0.2s;
  font-weight: 500;
}

.selector-child-active {
  background-color: #f8ac1d;
  border-radius: 7px;
  transition: 0.5s ease;
}

.selector-type-active {
  background-color: #f8ac1d;
  border-radius: 7px;
  transition: 0.5s ease;
}

.b2b1-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  cursor: pointer;
}

.b1-btn,
.b2-btn {
  border: 1px solid #b7b7b7;
  color: #b7b7b7;
  padding: 0.1rem 1.7rem;
  font-size: 1rem;
  border-radius: 10px;
  display: inline-block;
  height: 1.8rem;
}

.b2-btn {
  margin-right: 0.4rem;
}

.btn-container {
  display: flex;
}

.btn-active {
  background-color: #f8ac1d;
  color: #1d1d1d;
}

.degree-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: 1px solid #b7b7b7;
  border-radius: 10px;
  color: #1d1d1d;
  font-size: 1rem;
  padding: 0.12rem;
  cursor: pointer;
  transition: all ease-in 0.2s;
  margin-top: 0.2rem;
}

.degree-selector {
  background-color: #f8ac1d;
  border-radius: 7px;
  transition: all ease-in 0.2s;
}

.degree-container-children {
  text-align: center;
  padding: 0.2rem;
}

/* level css code */

.level-main-container {
  display: grid;
  margin-top: 2rem;
  color: #444444;
  grid-row-gap: 1.2rem;
}

.level-heading {
  /* display: flex; */
  font-size: 1rem;
  font-weight: 500;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* justify-content: space-between; */
}

.level-title-wrapper {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  display: grid;
  width: 6.5rem;
}

.level-selector-parent {
  display: grid;
  font-size: 1rem;
  font-weight: 500;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* grid-gap: 1rem; */
  margin-bottom: 0.56rem;
  /* align-items: center; */
}

.heading-badge {
  background-color: #f8ac1d;
  width: 7.6rem;
  height: auto;
  text-align: center;
  border-radius: 5px;
  font-size: 0.81rem;
  padding: 0.3rem;
  margin-left: 8.3rem;
}

.heading-badge-dynamic {
  background-color: #f8ac1d;
  width: 7.6rem;
  height: auto;
  text-align: center;
  border-radius: 5px;
  font-size: 0.81rem;
  padding: 0.3rem;
  margin-left: 2.5rem;
}

.bg-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.level-selector {
  height: 1.4rem;
  width: 4.3rem;
  border: 1px solid #b7b7b7;
  border-radius: 5px;
  margin-left: 6rem;
  cursor: pointer;
}

.level-selector-active {
  background-color: #f8ac1d;
}

.level-title {
  color: #444444;
  opacity: 0.7;
  width: 100%;
}

.text-area-container {
  margin-top: 2rem;
}

.text-area-container textarea {
  width: 27rem;
  border: 1px solid #f8ac1d;
  border-radius: 10px;
  resize: none;
  outline: none;
  padding: 1rem;
}

.text-area-container textarea::placeholder {
  color: #8e8e8e;
  opacity: 0.54;
  font-size: 1rem;
}

.level-box-container {
  display: flex;
}

.level-opening {
  display: flex;
  justify-content: space-between;
}

/* client form dimenstions */

.client-form-dimensions {
  padding: 4rem 2rem;
  height: 70%;
  margin-bottom: -13rem;
  width: auto;
}

.basement-input-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1.8rem;
  grid-column-gap: 2rem;
}

.floor-header {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.floor-headers {
  display: flex;
  align-items: center;
  width: 31rem;
  justify-content: space-between;
}

.floor-heading {
  color: #444444;
  font-size: 1rem;
}

.site-photos {
  color: #444444;
  font-size: 1rem;
  margin-top: 1.2rem;
}

.dimension-btn {
  width: 10rem;
  height: 2.6rem;
  border-radius: 5px;
  border: 1px solid #f8ac1d;
  text-align: center;
  color: #444444;
  font-size: 1rem;
  padding: 0.1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dimension-btn-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.dimension-btn>input::file-selector-button {
  background-color: white;
  border: none;
  outline: none;
  color: #f8ac1d;
}

.dimension-btn>input {
  color: #f8ac1d;
}

.dimension-btn-background {
  background-color: #f8ac1d;
}

.file-name {
  color: #444444;
  text-decoration: underline;
  font-size: 13px;
  margin-top: 0.2rem;
}

/* .client-table-name{
  width: 13%;
  
} */
.hidden-input {
  display: none;
}

.dimension-upload-btn {
  width: 10rem;
  height: 2.6rem;
  border-radius: 5px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #444444;
  cursor: pointer;
}

.upload-icon {
  height: 15px;
  margin-left: 0.5rem;
}

.file-upload {
  border-bottom: 1px solid #444444;
  opacity: 0.4;
  position: relative;
  font-size: 0.88rem;
  font-family: poppins;
  padding-bottom: 5px;
  width: 18rem;
}

.file-upload>input {
  border: none;
  cursor: pointer;
}

.file-upload-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.fileInput {
  display: none;
}

.filename {
  cursor: pointer;
}

/*dimentions button*/
.dimention-btn {
  border: 1px solid #f8ac1d;
  border-radius: 5px;
  width: 13rem;
  height: 2.6rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.9rem;
  margin-top: 1rem;
  color: #444444;
  cursor: pointer;
}

.generateOpening {
  display: flex;
  justify-self: end;
}

.generateIcon {
  height: 16px;
  width: 16px;
}

.hide {
  display: none;
}

.client-dropdown {
  position: relative;
}

.dropdown-input {
  outline: none;
  border: none;
  border-bottom: 1px solid #1d1d1d;
  opacity: 0.5;
  padding-bottom: 0.4rem;
  width: 100%;
  font-family: "Poppins";
  cursor: pointer;
  position: relative;
}

.dropdown-input::placeholder {
  margin-bottom: 1rem;
  color: #1d1d1d;
  font-family: "Poppins";
  font-size: 0.9;
}

.dropdown-icon-container-client-form {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.option-container {
  position: absolute;
  border: 1px solid #70707080;
  border-radius: 10px;
  color: #8e8e8e;
  font-size: 1rem;
  width: 100%;
  height: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.6rem;
  opacity: 1;
  margin-top: 0.4rem;
  font-family: "Poppins";
  background-color: #fff;
  z-index: 10;
}

.option-container>ul {
  list-style-type: none;
  opacity: 0.54;
  font-size: 1rem;
}

.option-container>ul>li {
  cursor: pointer;
}

.option-li {
  padding: 0.2rem;
  transition: background-color 0.3s ease;
}

.option-li:hover {
  background-color: #fef3de;
  color: black;
  border-radius: 7px;
  padding: 0.2rem;
  opacity: 1;
  color: #f8ac1d;
}

.add-client-button {
  font-size: 0.8rem;
  border: 1px solid #f8ac1d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #444444;
  font-weight: 500;
  padding: 0.72rem 1.25rem;
  cursor: pointer;
  border-radius: 7px;
}

/* raj the legend form taabha starts ------------- */
.client-elevator-main .level-selector-active {
  background-color: #f8ac1dad;
  border: none;
}

/*------------ css for ClientElevatorDetails.js  page by Raj---------------- */
.add-client-elevator {
  height: 61%;
  position: fixed;
  top: 54%;
  left: 50%;
  width: 75%;
  background: white;
  z-index: 6;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  padding: 0 2rem;
  overflow: hidden;
}

.client-form-elevator {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50% 15%;
  grid-column-gap: 15rem;
  margin-top: -1rem;
}

.elevator-form-details {
  padding: 0.5rem 2rem;
}

.elevator-form-input-wrapper {
  padding-top: 1rem;
  display: grid;
  grid-template-columns: 46.5%;
  grid-column-gap: 3.1rem;
  grid-row-gap: 2rem;
  margin-top: 1rem;
}

.degree-form-details {
  margin-top: 2.6rem;
  width: 90%;
}

.viewdimension-Button {
  margin-top: 10rem;
}

.view-form-button {
  border: 1px solid #f8ac1d;
  height: 2.6rem;
  width: 16rem;
  margin-left: 2rem;
  background-color: #fff;
  font-size: 1rem;
  border-radius: 10px;
  color: #444444;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}

.view-form-button:hover {
  background-color: #f8ac1d;
}

.client-elevator-input-wrapper-left {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1.8rem;
  margin-top: -1.7rem;
}

.left-selector-container {
  margin-left: 1rem;
}

.b2-btnleft,
.b1-btnleft {
  border: 1px solid #b7b7b7;
  color: #b7b7b7;
  padding: 0.1rem 3rem;
  font-size: 1rem;
  border-radius: 10px;
  display: inline-block;
  height: 1.8rem;
}

.b2-btnleft {
  margin-right: 0.7rem;
}

.selector-container-left {
  border: 1px solid #b7b7b7;
  color: #1d1d1d;
  border-radius: 10px;
  font-size: 1rem;
  display: flex;
  width: 12rem;
  margin-left: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 0.12rem;
  transition: all ease-in 0.2s;
  height: 2rem;
}

.Elevator-Opening-Selection {
  width: 88%;
  margin-left: 2rem;
}

/*------- ClientElevatorForm CSS by Raj --------- */

.client-elevatorform-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10rem;
  margin-top: 1rem;
  padding: 2rem 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.client-elevatorform-main::-webkit-scrollbar {
  width: 3px;
  height: 20px;
}

.client-elevatorform-main::-webkit-scrollbar-thumb {
  background: #f8ac1d;
  border-radius: 4px;
}

.delhi {
  margin-top: 4vw;
}

.patna {
  height: 80vh;
  margin-bottom: -20.5rem;
}

.basement-form-dimensions {
  padding: 0rem 2rem;
  height: 50%;
  margin-top: -1vw;
}

.overhead-elevatorInput {
  position: absolute;
  top: 53.5%;
}

.dimension-name-wrapper {
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
  justify-content: space-between;
}

.dimension-name-wrapper p {
  font-size: 14px;
}

.dimenstions-container-point {
  margin-top: -1.5vw;
}

.client-form-heading-arrow {
  display: flex;
  margin-top: 2rem;
  gap: 0.7rem;
  text-transform: capitalize;
  align-items: center;
}

.client-form-heading-arrow h5 {
  font-size: 1.2rem;
  color: #1d1d1d;
}

.client-form-heading-line {
  width: 90%;
  height: 0.5px;
  background-color: #8e8e8e;
}

.client-form-left-arrow {
  font-size: 1.7rem;
  cursor: pointer;
  font-weight: 500;
}

.client-form-heading-arrowInner {
  display: flex;
  gap: 0.7rem;
}

.heading-badge-originals {
  background-color: #f8ac1d;
  width: 7.6rem;
  height: auto;
  margin-left: 5rem;
  text-align: center;
  border-radius: 5px;
  font-size: 0.81rem;
  padding: 0.3rem;
}

.heading-badges180 {
  background-color: #f8ac1d;
  width: 7.6rem;
  height: auto;
  margin-left: 3rem;
  text-align: center;
  border-radius: 5px;
  font-size: 0.81rem;
  padding: 0.3rem;
}

/* --------⇧⇧⇧⇧⇧ This Above css for Elevator -------------------------------------- */
/* --------------ElevatorInput css By Raj--------------------- */

.input-elevator {
  position: relative;
  z-index: 1;
  text-transform: capitalize;
}

.input-field-elevator {
  font-size: 18px;
  width: 100%;
  border: none;
  color: #8e8e8e;
  padding: 5px 0;
  background-color: transparent;
  outline: none;
}

.input-label-elevator {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: 0.91px;
  color: #a7a7a7;
  transition: all 0.3s ease;
  pointer-events: none;
  font-size: 0.8rem;
}

.input-exist-elevator {
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #ccc;
  transition: all 0.3s ease;
}

.input-highlight-elevator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #3301526c;
  transition: all 0.3s ease;
  z-index: 1;
}

.input-field-elevator:focus~.input-label-elevator {
  top: -20px;
  font-size: 1rem;
  font-weight: 500;
}

.input-field-elevator:focus~.input-label-elevator~.input-highlight-elevator {
  width: 100%;
}

.input-field:focus {
  cursor: default;
}

.input-field-elevator:hover+.input-label-elevator+.input-highlight-elevator {
  width: 100%;
  /* opacity: 0.7; */
}

/*------- ClientElevatorForm CSS by Raj --------- */
/* raj the legend form taabha end =------------------*/

/*--------------------------Author: Rahul Kumar ------28/05/2024--------------*/
/*-----------------dimention pagination CSS------------------------*/
.dimention-pagination-btn-wrapper {
  margin-top: 2rem;
  margin-left: auto;
  margin-right: 0;
  display: flex;
}

.dimention-pagination-btn-wrapper span {
  margin-left: 10px;
}

.dimention-pagination-btn-wrapper span:first-child {
  margin-left: 0;
}

.pagination-btn {
  display: inline-block;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}

.pagination-btn img {
  width: 30px;
  height: 30px;
}

.pagination-btn:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.pagination-btn.left:hover {
  background-image: url("../public/leftBtnDark.png");
}

.pagination-btn.right:hover {
  background-image: url("../public/rightBtn.png");
}

/*--------------------------Author: Rahul Kumar ------09/05/2024--------------*/

/*-----------------File Uploader Component CSS------------------------*/

/*--------------------------Author: Rahul Kumar ------02/05/2024--------------*/
.floor-section {
  margin-top: 2.5rem;
}

.floor-input-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1.8rem;
}

.floor-margin {
  margin-bottom: 1rem;
}

.floor-fl-fr-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.overhead-input {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 1.8rem;
}

.dimenstions-container {
  overflow-y: auto;
  max-height: 650px;
  overflow-x: hidden;

  display: grid;
  padding-right: 2rem;
  padding-bottom: 6rem;
}

.elevator-container {
  overflow-y: auto;
  max-height: 650px;
  overflow-x: hidden;
  display: grid;
  padding-bottom: 1rem;
}

/*Scrollbar*/
.dimenstions-container::-webkit-scrollbar {
  width: 3px;
  height: 20px;
}

.dimenstions-container::-webkit-scrollbar-thumb {
  background: #f8ac1d;
  border-radius: 4px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.display-block {
  display: none;
}

/*--------------------------Animated text Input css---------------*/


.input-field {
  font-size: 1.1rem;
  width: 100%;
  border: none;
  padding: 5px 0;
  background-color: transparent;
  outline: none;
  text-transform: initial;
  font-weight: 400;
  overflow: hidden;
  font-size: 1vw;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.91px;
  line-height: 1vw;
  color: #FFFFFF;
}

.text-field {
  padding-top: 8px;
}



.input-line {
  height: 1px;
  width: 100%;
  background-color: #cccccc77;
}

.input-highlight {
  position: absolute;
  bottom: 0;
  height: 1px;
  left: 0;
  width: 0;
  background-color: #FFFFFF;
  transition: all 0.3s ease;
  z-index: 1;
  opacity: 0.5;

}

.input-field:hover+.input-label,
.input-field:hover+.input-label+.input-highlight,
.input-field:hover+.input-label+.input-exist {
  opacity: 1;
}

.input-field:hover+.input-highlight {
  width: 100%;
}

.input-field:focus+.input-label {
  top: -20px;
  font-size: 14px;
  font-weight: 500;
  opacity: 1;
}

.input-field:focus+.input-label+.input-highlight {
  opacity: 1;
  width: 100%;
}

.input-field:focus+.input-label {
  opacity: 1;
}

.input-field:focus {
  cursor: none;
}

.input-field:hover+.input-label+.input-highlight {
  width: 100%;
  opacity: 0.7;
}

.input-field:hover+.input-label {
  opacity: 1;
}

/* .input-field {
  font-size: 18px;
  width: 100%;
  border: none;
  padding: 5px 0;
  background-color: transparent;
  outline: none;
} */


.input-label {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: 0.91px;
  color: #3301526c;
  transition: all 0.3s ease;
  pointer-events: none;
  font-size: 0.8rem;
}

.input-exist {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #ccc;
  transition: all 0.3s ease;
}

.input-highlight {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #3301526c;
  transition: all 0.3s ease;
  z-index: 1;
}

/* .input-field:focus+.input-label {
  top: -20px;
  font-size: 1rem;
  font-weight: 500;
  color: #330152;
}

.input-field:focus+.input-label+.input-highlight {
  width: 100%;
}  */

.input-field:focus~.input-label {
  top: -20px;
  font-size: 1rem;
  font-weight: 500;
  color: #330152;
}

.input-field:focus~.input-label~.input-highlight {
  width: 100%;
}




/* --------------------------------Animated Input CSS---------------------------------- */
input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-background-color: transparent !important;
}

.input-container {
  position: relative;
  z-index: 1;
}

.select-container {
  position: relative;
  width: 25rem;
}

.input-field {
  font-size: 1.1rem;
  width: 100%;
  border: none;
  border-bottom: 1px solid #44444462;
  opacity: 1;
  padding: 5px 0;
  background-color: transparent;
  outline: none;
  font-size: 1rem;
  font-family: "Poppins";
  color: #1d1d1d;
  cursor: default;
  font-weight: 400;
}

.input-label {
  position: absolute;
  top: 0;
  left: 0;
  letter-spacing: 0.91px;
  color: #1d1d1d;
  transition: all 0.3s ease;
  pointer-events: none;
  font-size: 1rem;
  opacity: 0.5;
  font-weight: 400;
  z-index: 1;
}

.input-highlight {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 0;
  background-color: #444444;
  transition: all 0.3s ease;
  opacity: 0.7;
}

.input-field:focus+.input-label {
  top: -20px;
  font-size: 14px;
  font-weight: 500;
  color: #1d1d1d;
  opacity: 1;
}

.input-field:focus+.input-label+.input-highlight {
  opacity: 1;
  width: 100%;
}

.input-field:focus+.input-label {
  opacity: 1;
}

.input-field:focus {
  cursor: none;
}

.input-field:hover+.input-label+.input-highlight {
  width: 100%;
  opacity: 0.7;
}

.input-field:hover+.input-label {
  opacity: 1;
}

/* ---------------------------------------------------------------------- */
@media only screen and (min-width: 769px) and (max-width: 1550px) {
  .client_table-container {
    max-height: 80vh;
  }

  .ClientCatainer {
    grid-template-columns: 30% 30% 30%;
  }

  .client_table-container table td,
  .client_table-container table th {
    font-size: 0.8rem;
  }

  .sub_table_view {
    height: 85vh;
  }

  .client_sub_table_view {
    height: 90vh;
  }

  .JON {
    font-size: 0.7rem;
  }

  .headingCenter {
    font-size: 1rem;
  }

  .clientDetailRight {
    margin-left: 1rem;
  }

  .clientName {
    font-size: 0.7rem;
  }

  .JOB {
    font-size: small;
  }

  .clientsContaine p {
    font-size: 0.8rem;
  }


  /* ------------------------------------spare part table css here------------------------------ */
  .spare-part-table-container table th,
  .spare-part-table-co ntainer table td {
    font-size: 0.7rem;
    white-space: nowrap;
  }

  .spare-part-table-container {
    max-height: 23vh;


  }



  .client-table-name {
    width: 12rem;
  }

  .clientCard {
    background-color: #ffffff;
    font-size: 0.8rem;
    padding: 1.36rem;
    width: 100%;
    border-radius: 1rem;
    box-shadow: 2px 4px 10px #00000029;
    cursor: pointer;
  }

  .clientCardShadowSilver {
    box-shadow: inset 0px 0px 15px #b7b7b7, 2px 4px 10px #00000029;
  }

  .clientCardShadowGold {
    box-shadow: inset 0px 0px 15px #f8ac1d80, 2px 4px 10px #00000029;
  }

  .clientCardShadowWarrenty {
    box-shadow: inset 0px 0px 15px #0f351d, 2px 4px 10px #00000029;
  }

  .clientCardShadowPlatinum {
    box-shadow: inset 0px 0px 15px #ff7f0080, 2px 4px 10px #00000029;
  }

  .clientInfo2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 0rem 0rem 0rem;
  }

  .Info {
    font-size: 0.8rem;
  }

  .service-history-client-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3.2rem !important;
  }

  .service-history-client-heading {
    color: #8E8E8E;
    font-size: 0.8rem !important;
  }

  .service-history-wrapper {
    display: flex;
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    margin-top: 0.8rem !important;
    text-transform: capitalize;
  }

  .service-history-heading>h6 {
    display: flex;
    align-items: center;
    font-size: 0.88rem !important;
    color: #1D1D1D;
    font-weight: 500;
  }

  .service-history-heading {
    display: flex;
    align-items: center;
  }

  .service-history-client-child-color {
    color: #444444;
    font-size: 0.8rem !important;
  }

  .hr-salesman-width {
    width: 85% !important;
  }

  .hr-client-width {
    width: 88% !important;
  }

  .hr-architect-width {
    width: 85% !important;
  }

  .client-modal-profile-container {
    height: 15%;
    width: 100%;
    position: relative;
    padding: 0 0.8rem;
    display: flex;
    justify-content: space-between;
  }

  .client-modal-profile-container-left {
    width: 20%;
    height: 100%;
    display: flex;
    cursor: pointer;
    gap: 0.4rem !important;
    align-items: center;
    justify-content: flex-start;
  }

  .client-modal-profile-container-right {
    width: 62% !important;
    height: 100%;
    display: flex;
    margin-right: 0.8rem !important;
    align-items: center;
    justify-content: space-around;
  }

  .client-modal-dropdown {
    height: 2.8rem;
    width: 6rem;
    border: 1px solid #8e8e8e;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
  }

  .left-side-heading {
    font-size: 0.96rem !important;
  }

  .react-calendar {
    max-width: 100% !important;
    background: white;
    border: none !important;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.76em !important;
    width: 100% !important;
    box-shadow: 0px 0px 3px #00000029 !important;
    border-radius: 7px !important;
    /* height: 37rem; */
    padding-bottom: 1.6rem !important;
    height: auto !important;
  }

  .react-calendar__navigation {
    height: 2.2rem !important;
    margin-bottom: 0em !important;
    margin-top: 0.8rem !important;
    border-bottom: 1px solid #00000029 !important;
    align-items: center !important;
  }

  .react-calendar__month-view__weekdays {
    font-size: 0.6em !important;
  }

  .react-calendar__navigation__label__labelText {
    font-size: 1rem !important;
    color: #343434 !important;
  }

  .date-swap-icon {
    background: #ffffff;
    padding: 0.9rem 0px !important;
    border-radius: 8px;
    box-shadow: 0px 0px 3px #00000029;
  }

  .swap-icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 0.9rem !important;
    color: #343434;
    cursor: pointer;
  }

  .calender-events-section {
    display: flex;
    justify-content: space-between;
    margin-top: 6rem !important;
  }

  .service-assign {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6rem 0.3rem !important;
    margin-top: 0.5rem !important;
  }

  .date-time span {
    font-size: 0.65rem !important;
    font-weight: 500;
    color: #f8ac1d;
  }

  .date-time p {
    font-size: 0.7rem !important;
    font-weight: 600;
    color: #343434;
  }

  .date-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #c1c1c1;
    padding: 0px 0.7rem !important;
    margin-right: 0px;
  }

  .name-jon {
    padding: 0px 0px;
    margin-left: -1.25rem !important;
  }

  .name-jon p {
    font-size: 0.8rem !important;
    font-weight: 500;
    color: #343434;
  }

  .jon-type p {
    font-size: 0.6rem !important;
    font-weight: 400;
    color: #343434;
  }

  .react-calendar__tile {
    padding: 0.85rem 0px !important;
    text-align: center !important;
    /* line-height: 5px!important; */
    font: inherit !important;
    font-size: 0.6664rem !important;
    margin-top: 20px;
  }

  .service-request-section-button {
    padding: 0.5rem 1.5rem !important;
    border: none;
    border-radius: 4px;
    background: #ffffff;
    font-size: 0.96rem !important;
    font-weight: 500;
    cursor: pointer;
    margin-left: 4px;
  }

  /* service and callbac form responsive code starts here --Rahul Kumar */

  .sub-child-modal-container {
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }



  .req-photo-upload-section {
    width: 25%;
    height: 90% !important;
    object-fit: cover;
    object-position: center;
    position: relative;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .req-photo-upload-section img {
    height: 10.4rem !important;
    width: 10.4rem !important;
    border-radius: 3px;
  }

  .req-client-information-section {
    width: 40% !important;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 0.8rem !important;
    justify-content: center;
  }

  .req-membership-information-section {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 86%;
    align-items: flex-end;
    position: relative;
  }

  .membership-form-row {
    width: 100%;
    height: 1.35rem !important;
    display: flex;
    gap: 1rem;
  }

  .req-client-form {
    width: 100%;
    padding: 0.4rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.48rem !important;
  }

  .col25 label {
    padding: 0px 0px 0px 0;
    display: inline-block;
    color: #272626;
    font-size: 0.64rem !important;
    font-weight: 500;
    font-family: "Poppins";
  }

  .membership-form-col1 p {
    font-size: 0.64rem !important;
  }

  .col75 textarea::placeholder {
    font-size: 0.64rem !important;
    font-family: "Poppins";
    opacity: 0.6;
  }

  .col75 p {
    color: #1d1d1d;
    font-size: 0.7rem !important;
    font-weight: 400;
    font-family: "Poppins";
  }

  .membership-form-col2 p {
    font-size: 0.7rem !important;
  }

  .col75 input[type="text"] {
    /* width: 100% !important; */
    padding: 0.48rem !important;
    border-radius: 4px;
    resize: vertical;
    font-size: 0.64rem !important;
    font-weight: 400;
  }

  .col75 input {
    width: 92.5%;
    padding: 0.48rem !important;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    resize: vertical;
    font-size: 0.64rem !important;
    font-weight: 400;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 0 2px 1px;
    border: none;
    font-family: "Poppins";
    outline-color: #f8ac1d;
  }

  .col75 textarea {
    width: 80%;
    height: 10px;
    padding: 8px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    resize: vertical;
    font-size: 15px;
    font-weight: 400;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 0 2px 1px;
    border: none;
    font-family: "Poppins";
    font-size: 0.72rem !important;
    font-family: "Poppins";
    outline-color: #f8ac1d;
  }

  .col75 textarea::placeholder {
    font-size: 0.64rem !important;
    font-family: "Poppins";
    opacity: 0.6;
  }

  .col75 p {
    color: #1d1d1d;
    font-size: 0.64rem !important;
    font-weight: 400;
    font-family: "Poppins";
  }

  .req-client-information-section .req-client-form .row .col75 select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid red;
    resize: vertical;
    font-size: 0.7rem !important;
    font-weight: 400;
  }

  .req-elevator-details h1 {
    font-size: 0.72rem !important;
    font-family: "Poppins";
    font-weight: 500;
    text-align: start;
  }

  /* .req-elevator-details {
    width: 100%;
    height: 30%;
    position: relative;
    padding-left: 4.1rem;
  } */

  .sub-req-elevator-details {

    gap: 0.24rem !important;
    padding-left: 0.8rem !important;
    margin-top: 0.4rem !important;
  }

  .req-elevator-col1 p {
    font-size: 0.64rem !important;
  }

  .req-elevator-col2 p {
    font-size: 0.64rem !important;
  }

  .col75 input::placeholder {
    font-size: 0.64rem !important;
  }

  .sub-engg-detail-section {
    font-size: 0.8rem !important;
  }

  .col-elevator75 input {
    font-size: 0.7rem !important;
  }



  .col-elevator25 label {
    font-size: 0.72rem !important;
  }

  .assign-button {
    padding: 0.6rem 2.5rem !important;
    font-size: 0.8rem !important;
  }

  .calendar-box {
    text-align: left !important;
  }


  .grid-form-container {
    padding-top: 1.64rem !important;
  }

  .col75-jon {
    width: 51% !important;
  }

  .col75-typeOfIssue {
    width: 52% !important;
  }

  .calendar {
    top: 26% !important;
    left: 12.7% !important;
    height: auto !important;
  }

  .days {
    gap: 0.6rem !important;
  }

  .day {
    font-size: 0.72rem !important;
  }

  /* engineer form media query --Rahul Kumar */

  .outerMainMoodule {
    gap: 1.2rem !important;
    padding: 2.2rem 0 0.32rem 0 !important;
    /* height: 90% !important;
  width: 78% !important;   */
  }

  .EnggDetailHeading {

    padding-bottom: 4px;
    font-size: 0.8rem !important;

  }

  .PersonalDetailInput>input::placeholder {
    font-size: 0.64rem !important;
  }

  .PersonalDetailInput>input {
    font-size: 0.64rem !important;
  }

  .PersonalDetailInputEmail>input::placeholder {
    font-size: 0.64rem !important;
  }

  .PersonalDetailInputEmail>input {
    font-size: 0.64rem !important;
  }

  .EnggAddressInput>input::placeholder {
    font-size: 0.64rem !important;
  }

  .EnggAddressInput>input {
    font-size: 0.64rem !important;
  }

  .smallifcCodeSuggestion>input::placeholder {
    font-size: 0.64rem !important;
  }

  .smallifcCodeSuggestion>input {
    font-size: 0.64rem !important;
  }

  .workExperienceCheckbox>input::placeholder {
    font-size: 0.64rem !important;
  }

  .workExperienceCheckbox>input {
    font-size: 0.64rem !important;
  }

  .inputWithAttachment>input::placeholder {
    font-size: 0.64rem !important;
  }

  .inputWithAttachment>input {
    font-size: 0.64 !important;
  }

  .additionalCourseData>input::placeholder {
    font-size: 0.64rem !important;
  }

  .additionalCourseData>input {
    font-size: 0.64rem !important;
  }

  .EnggDetailInputField .imageUploadDiv {
    height: 55% !important;
    width: 25% !important;
  }

  .button-69,
  .button-69-cancel {
    font-size: 0.8rem !important;
    padding: 0.64rem 2rem !important;
  }

  .css-qbdosj-Input>input {
    font-size: 0.64rem !important;
  }

  #additionalCourseData {
    font-size: 0.64rem !important;
  }

  #addharCardNumberInput {
    font-size: 0.64rem !important;
  }

  #pancardsInput {
    font-size: 0.64rem !important;
  }

  #drivingLisienceInput {
    font-size: 0.64rem !important;
  }

  .css-1dimb5e-singleValue {
    font-size: 0.64rem !important;
  }

  .css-1jqq78o-placeholder {
    font-size: 0.64rem !important;
  }

  .addform-modal-container {
    height: 89vh !important;
    width: 79vw !important;
  }

  .addEnggButtons-view {
    bottom: 10% !important;
  }

  /* ------------------------------------------------------------------------------------------------------------responsive by aayush membership card------------------------------------------------------------------------------ */

  .membership_card_scrollable {
    height: 24rem;
    max-height: 30rem;
    overflow-y: auto;
  }

  .non_expand_gap .membership_card {
    height: 37vh;
  }

  .membership_card_title {
    font-size: 1.2rem;
  }

  .membership_card_revenue {
    font-size: 0.8rem;
  }

  .expandCardName {
    font-size: 0.7rem;
    width: 120px;

  }


  .membership_card_expiring {
    width: 45%;
  }

  .membership_card_expiring_expanded {
    width: 45%;
  }

  .membership_card_expand_non {
    height: 17vh;
    margin: 0.5rem;
  }

  .membership_card_title_non_expand {

    font-size: 0.9rem !important;
  }


  .after_expansion_labels {
    font-size: 0.6rem;
  }

  .total_revenue_heading {
    font-size: 0.8rem;
    padding-bottom: 2%;
  }

  .total_revenue_amount {
    margin-top: 2%;
    font-size: 1rem;
  }

  .expansion_labels {
    margin-top: 0.2rem;
  }

  .membership_card_counts_expand {
    width: 1.4rem;
    height: 1.4rem;
  }


  .membershipCards_expand {
    margin-top: 5rem;
  }

  /* ------------------------------------------------------------------------------------------------------------end of responsive by aayush membership card------------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------------------------------------------all form responive here for index.css ------------------------------------------------------------------------------------------------*/
  .client-form-details {
    padding: 1rem 2rem 0 2rem;
  }


  .client-form-document-input-wrapper {
    display: grid;
    grid-template-columns: 46.5% 46.5%;
    grid-column-gap: 1.3rem;
    margin-top: 0.2rem;
    grid-row-gap: 0.2rem;
  }

  .button-container {
    padding: 1rem 2rem;
  }

  .input-field {
    font-size: 0.7rem;
    width: 100%;
    border: none;
    border-bottom: 1px solid #44444462;
    opacity: 1;
    padding: 3px 0;
    background-color: transparent;
    outline: none;
    font-family: "Poppins";
    color: #1d1d1d;
    cursor: default;
    font-weight: 400;
    margin-top: -1.2rem !important;
  }

  .client_table-container {
    max-height: 77vh;
  }




  .sub_table_view {
    height: 82vh;
  }

  /* ------------------------------------------------------------------------------------------------------------end of all form responive here for index.css ------------------------------------------------------------------------------------------------*/




  .clientDetailContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.7rem;
    width: 100%;
    color: #444444;
    margin-top: 1rem;
  }


  .clientsContainer {

    gap: 5%;
    margin-right: 1rem;
  }


  .clientDetailCalls {
    font-size: 0.8rem;
  }

  .inputBox {
    font-size: 0.8rem;

  }

  .sendButton {
    padding: 0.727rem 2rem;
  }

  .offerButtonMain {
    padding: 1rem 0%;

  }

  .offer {
    width: 100%;
    padding: 0.2rem 0rem;
    cursor: pointer;
  }


  .inputNotification {
    margin-top: 0.6rem;

  }


}




.imageUploadDiv img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}



/* sidebar SOS button css ends */


/* SOS CSS */
.SOS-Action-modal-container {
  width: 10%;
  height: 12rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 9999;
  border-radius: 15px;
}

.SOS-Action-modal-container-outer {
  width: 100%;
  position: relative;
  height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: medium;
}

.edit-SOS-Action-modal {
  position: absolute;
  top: 5%;
  cursor: pointer;
  left: 80%;
  font-size: 1.3rem;
}

.SOS-Action-modal-items {
  display: flex;
  align-items: center;
  width: 18vw;
  margin-top: -1rem;
  justify-content: center;
  flex-direction: column;
}

.SOS-SentEngineer-Modal {
  position: absolute;
  top: 30%;
  cursor: pointer;
  left: 45%;
  padding: 1.5rem;
  font-size: 1.3rem;
  background-color: white;
  /* padding: 10rem; */
}

.SOS-SentEngineer-Modal-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}


/* SOS CSS */

/* ---------------- Author:- Armaan Singh --------------------------------*/


@media only screen and (min-width: 1371px) and (max-width: 1550px) {

  .client-form-details {
    padding: 2.4rem 2rem 0 2rem;
  }

  .client_table-container {
    max-height: 80vh;
  }



  .sub_table_view {
    height: 85vh;
  }

  .non_expand_gap .membership_card {
    height: 39vh;
  }

  .expandCardName {
    font-size: 0.6rem;
    width: 90px;
  }

  .membership_card_scrollable {
    height: 19rem;
    max-height: 12rem;
    overflow-y: auto;
  }

}


@media screen and (min-width : 1024px) and (max-width:1440px) {
  .membership_card_expiring {
    height: 15rem;
    max-height: 15rem;
  }

  .membership_card_scrollable_non_expand {
    height: 6rem;
    overflow-y: auto;
  }

  .callButton {
    font-size: 0.8rem;
    padding: 0.5rem;
    margin-top: -6rem;
  }

  .offerButtonContainer {
    margin-bottom: 4.4rem;
    height: 1rem;
    position: relative;
  }

  .offerButtonMain {
    position: absolute;
    height: 1rem;
    top: 35%;
  }

  .historyContainer {
    font-size: smaller;
    height: 20vh;
  }



  .clients {
    /* height: 26rem; */
    /* min-height: 65vh; */
    max-width: 30rem;
    width: 50%;
    margin-top: 0.2rem;
  }

  .callsContainer {
    padding-right: 0.5rem;
  }

  .notificationmembershipwrapper {
    margin-bottom: 1.8rem;
  }

  .chevronDownMembership {
    top: 51%;
  }
}


@media screen and (min-width : 1440px) and (max-width:1550px) {
  .membership_card_expiring {
    height: 15rem;
    max-height: 15rem;
  }

  .membership_card_scrollable_non_expand {
    height: 8.3rem;
    overflow-y: auto;
  }

  .callButton {
    font-size: 0.8rem;
    padding: 0.5rem;
    margin-top: -6rem;
  }

  .notificationmembershipwrapper {
    margin-bottom: 1.5rem;
  }

  .offerButtonContainer {
    margin-bottom: 4.4rem;
    height: 1rem;
    position: relative;
  }

  .offerButtonMain {
    position: absolute;
    height: 1rem;
    top: 35%;
  }

  .historyContainer {
    font-size: smaller;
    height: 35vh;
  }

  .clients {
    /* height: 30rem; */
    /* min-height: 75vh; */
    max-width: 30rem;
    width: 50%;
    margin-top: 0.2rem;
  }

  .callsContainer {
    padding-right: 0.5rem;
    margin-top: 1rem;
  }

  .clientCallInputAdd {
    margin-bottom: -3rem;
  }

  .chevronDownMembership {
    top: 51%;
  }

  .membership_card_scrollable_height {
    min-height: 65vh;
    max-width: 30rem;
  }

}


.CarouselScrollDots {
  display: flex;
  justify-content: center;
  margin-top: 0.4rem;
}

.Reportdot {
  height: 6px;
  width: 6px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.Reportdot.active {
  background-color: #f9b83f;
}