* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Poppins";
}

/* set body scroll property starts */
/* set scroll behaviour  */
body::-webkit-scrollbar {
  width: 3px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #ffffff;
  display: none;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 1px solid #f8ac1d;
  /* creates padding around scroll thumb */
}

/* set body scroll property ends */

.small-screen {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .large-screen {
    display: none;
  }

  .small-screen {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.main-container {
  width: 100%;
  background: #fff9ef;
  position: relative;
}

.container {
  background: #fff9ef;
  display: flex;
}

main {
  display: flex;
  width: 100%;

  padding: 10px 20px;
  overflow: hidden;
}

.sidebar {
  /* height: auto; */
  background: #ffffff;
  color: rgb(20, 19, 19);
  /* height: auto; */
  transition: all 0.5s;
  position: relative;
  position: sticky;
  top: 0;
  box-shadow: 2px 4px 10px #99886b66;
  display: flex;
  z-index: 4;
  justify-content: center;
  align-items: baseline;
  height: 100vh;
}

.sidebar2 {
  background: #ffffff;
  color: rgb(20, 19, 19);
  /* height: auto; */
  transition: all 0.5s;
  position: relative;
  position: sticky;
  top: 0;
  box-shadow: 2px 4px 10px #99886b66;
  display: flex;
  z-index: 4;
  justify-content: center;
  align-items: baseline;
  height: 100vh;
  display: none;
}

.sidebarOpenWidth {
  width: 18.75rem;
}

.sidebarCloseWidth {
  width: 8.8rem;
}

.logo-image {
  justify-content: center;
  align-items: center;
  width: 100px;
  display: flex;
  transition: all 0.5s ease;
}

.logo-image-open {
  width: 100px;
}

.logo-image-close {
  width: 60px;
}

.top_section {
  display: flex;
  align-items: flex-start;
  padding: 0px 0px;
  justify-content: flex-start;
  gap: 40px;
  width: 0;
  transition: all 0.5s ease;
}

.logo {
  margin-top: 1.3rem;
  transition: all 0.5s ease;
}

.logo-open {
  margin-left: -6.5rem;
}

.logo-close {
  margin-left: -2.3rem;
}

.bars {
  display: flex;
  font-size: 25px;
  cursor: pointer;
  margin-bottom: 1.8rem;
  margin-left: 5rem;
}

.bars-open {
  margin-left: 3.1rem;
}

.bars-close {
  margin-left: -0.6rem;
}

.bars-close .Pimg img {
  object-fit: cover;
  object-position: top;
}

/* hamburger css starts */
/* .toggle-button {
  width: 8px;
  height: 19px;
  position: fixed;
  transform: translate(15px, -8px);
  padding: 4px;
  transition: 0.25s;
  z-index: 15;
}

.toggle-button:hover {
  cursor: pointer;
}

.menu-icon {
  transition: transform 0.8s ease-in-out;
  Adjust the duration and easing function as needed
} */
/* 
.rotate {
  transform: rotate(360deg);
  Rotate 180 degrees
} */

.toggle-button {
  width: 8px;
  height: 19px;
  position: fixed;
  transform: translate(15px, -8px);
  padding: 4px;
  transition: 0.25s;
  z-index: 15;
  cursor: pointer;
}

.toggle-button:hover {
  cursor: pointer;
}

.menu-icon {
  transition: 0.5s ease;
}

.rotate {
  transform: rotateZ(180deg);
  margin-top: -20px;
}

.toggle-button .menu-bar {
  position: absolute;
  border-radius: 2px;
  width: 80%;
  transition: 0.5s;
}

.toggle-button .menu-bar-top {
  border: 2px solid #343434;
  border-bottom: none;
  top: 4px;
}

.toggle-button .menu-bar-middle {
  height: 2px;
  background-color: #343434;
  margin-top: 7px;
  margin-bottom: 7px;
  top: 4px;
  width: 80%;
}

.toggle-button .menu-bar-bottom {
  border: 2px solid #343434;
  border-top: none;
  top: 12px;
}

.button-open .menu-bar-top {
  transform: rotate(40deg) translate(-3px, 1px);
  transition: 0.5s;
}

.button-open .menu-bar-middle {
  transform: translate(20px);
  transition: 0.1s ease-in;
  opacity: 0;
}

.button-open .menu-bar-bottom {
  transform: rotate(-40deg) translate(0px, -4.5px);
  transition: 0.5s;
}

/* hamburger css ends */

.toggle-button-menu {
  width: 20%;
  position: absolute;
  right: 8%;

  margin-left: 0.2rem;
}

.wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.menu-bar {
  height: 2px;
  background-color: #000;
  width: 12px;
  transition: 0.5s ease;
}

.menu-bar-top {
  border-radius: 5px;
}

.menu-bar-bottom {
  border-radius: 5px;
}

.link {
  display: flex;
  color: #343434;
  padding: 10px 15px;
  gap: 10px;
  transition: all 0.5s;
  border-radius: 10px;
  text-wrap: nowrap;
  margin-top: 0.18rem;
}

.link:hover {
  background: #fef3de;
  color: #f8ac1d;
  transition: all 0.5s;
}

.active {
  background: #fef3de;
  color: #f8ac1d;
  transition: all 0.5s;
}

.icon,
.link_text {
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 22px;
}

/* dropdown css in side bar starts*/

li a {
  text-decoration: none;
  color: #2d2f31;
}

.fixed-content-navbar {
  position: fixed;
  top: 0;
  padding: 0px 0.85rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

nav {
  width: 300px;
  background: #ffffff;
  margin: 12px auto;
  transition: 0.5s ease;
}

.nav-open {
  width: 14.3rem;
  display: block;
  flex-direction: column;
  align-items: start;
}

.nav-close {
  width: 100px;
  display: block;
  flex-direction: column;
  align-items: center;
}

.main-menu-adjust {
  padding: 0px 0px;
  background: #ffffff;
  color: #343434;
  font-size: 0.9rem;
  font-variant: small-caps;
  cursor: pointer;
  display: block;
  font-weight: 500;
  transition: 0.5s ease;
}

span::after {
  float: right;
  right: 10%;
  content: "";
}

.slide {
  clear: both;
  width: 100%;
  height: 0px;
  overflow: hidden;
  text-align: center;
  transition: height 0.4s ease;
  /* padding-top: 10px; */
}

.slide li {
  padding: 0px;
}

#touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}

/* #touch:checked + .slide {
  height: 344px;
} */

#touch2 {
  position: absolute;
  opacity: 0;
  height: 0px;
}

/* #touch2:checked + .slide {
  height: 200px;
} */

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-menu-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  cursor: pointer;
  margin-top: 8px;
}

/* when the sidebar down goes small css starts */
.main-menu-adjust-2 {
  background: #ffffff;
  color: #343434;
  font-size: 11px;
  font-variant: small-caps;
  cursor: pointer;
  display: block;
  font-weight: 700;
  margin-left: 5px;
}

.main-menue {
  margin-top: 10px;
}

/* dropdown csss sidebar ends */

/* Todo component CSS start*/
.sub_todo_view {
  height: 75vh;
  width: 81vw;
  margin-top: 8rem;
  margin-left: 2rem;
  background-color: white;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 3px #c1c1c1;
  border-radius: 10px;
  padding: 1rem 2rem;
  overflow: hidden;
}

.todo_table-container {
  height: 60vh;
  /* overflow-y: auto; */
  width: 80vw;
}

.todo_table_top {
  display: flex;
  /* padding: 0 2rem; */
  align-items: center;
  justify-content: space-between;
}

.todo_table_top h2 {
  margin-left: 2rem;
  font-weight: 500;
  color: #1d1d1d;
  font-size: 1.5rem;
}

.todo_table_top button {
  width: 123px;
  height: 45px;
  background: #f8ac1d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  opacity: 1;
}

.todo_table_top button p {
  font-size: 15px;
  font-weight: 600;
  color: #444444;
}

.plusIcon {
  font-size: 20px;
  color: #444444;
}

/* todo card css starts*/
.todo-card-parent {
  margin-left: 2.1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.todo-card {
  width: 46rem;
  height: 22rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 2px 4px 10px #99886b66;
  border-radius: 10px;
  overflow-y: hidden;
  margin-bottom: 3rem;
}

.todo-card-header {
  top: 60rem;
  left: 19.68rem;
  width: 46rem;
  height: 5.06rem;
  background: #389300 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.todo-number-of-task {
  height: 2.3rem;
  width: 2.3rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.2rem;
}

.todo-card-header-child-one {
  margin-left: 1.2rem;
  font: 1.2rem Poppins;
  color: #fffdf8;
  font-weight: 500;
}

.todo-card-body {
  margin-top: 1.8rem;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 7rem;
}

.todo-card-body::-webkit-scrollbar {
  width: 4px;
}

.todo-card-body::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
}

.single-todo-task {
  width: 93%;
  margin: auto;
  padding: 0.3rem;
  border-bottom: 1px solid #00000073;
  padding-bottom: 0.6rem;
  margin-bottom: 1.5rem;
}

.todo-task-number {
  border-right: 1px solid #00000073;
  padding: 0 2rem;
  font-size: 1rem;
}

.single-todo-task-data {
  display: flex;
  justify-content: space-between;
}

.todo-text {
  font-size: 0.8rem;
  color: #444444;
}

.todo-text-container {
  margin-left: 1rem;
}

.todo-upcoming {
  background: #0758b4 0% 0% no-repeat padding-box !important;
}

.todo-data-sub {
  display: flex;
}

.todo-urgent-badge {
  width: 98px;
  height: 28px;
  background: #c10000 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: #fffdf8;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub_todo_view {
  height: 75vh;
  width: 81vw;
  margin-top: 8rem;
  margin-left: 2rem;
  background-color: white;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 3px #c1c1c1;
  border-radius: 10px;
  padding: 1rem 2rem;
}

.todo_table-container {
  height: 76vh;
  width: 79vw;
  margin-left: -0.8rem;
}

.todo_table_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.todo_table_top h2 {
  margin-left: 2rem;
  font-weight: 500;
  color: #1d1d1d;
  font-size: 1.5rem;
}

.todo_table_top button {
  width: 123px;
  height: 45px;
  background: #f8ac1d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  opacity: 1;
}

.todo_table_top button p {
  font-size: 15px;
  font-weight: 600;
}

.plusIcon {
  font-size: 20px;
}

.Todocalendar {
  margin-top: 0.8rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* width: 100%; */
  position: relative;
  z-index: 1;
  display: block;
  height: 62vh;
  width: 55vw;
  background-color: #fffbf4;
  overflow-x: hidden;
  overflow-y: hidden;
}

.Attendance {
  height: 100%;
  width: 100%;
  position: relative;
  display: grid;
  padding: 0.5rem 0.5rem;
  grid-template-columns: 30% 70%;
}

.Todo-header {
  background-color: #fffbf4;
  border-bottom: none;
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  /* display: flex;
  justify-content: center; */
}

.week-header {
  text-align: center;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 10px;
  padding: 0.2rem 1.5rem;
  margin-top: 0.5rem;
  color: #444444;
  font-weight: 500;
}

#monthYear {
  font-size: 0.8rem;
}

#aprevBtn,
#anextBtn {
  background: none;
  border: none;
  color: #343434;
  cursor: pointer;
  font-size: 0.6rem;
}

/* .todoaday {
  padding:0.7rem;
  cursor: pointer;
    font-size: 0.8rem;
  color: black;
width: 144px;
  height:83px;
  border: 1px solid #707070;
} */

.aday:hover {
  background-color: #fef3de;
  color: #f8ac1d;
}

.days-header-todo {
  font-weight: bold;
  width: 100%;
  /* padding-left: 1.2rem; */
  position: absolute;
  display: grid;
  /* text-align: center; */
  grid-template-columns: repeat(7, 9%);
  grid-column-gap: 4rem;
  font-size: 0.9rem;
  font-weight: 500;
  /* background-color: lightseagreen; */
  top: 1%;
  padding: 0rem 3rem;
}

/* .adays-todo{
  display: grid;
  grid-template-columns: repeat(7, 9%);
  grid-column-gap:3.2rem;
  padding: 1rem;
  height:100%;
  position: relative;
  margin-top: 1.2rem;
} */

.calendar-box {
  text-align: center;
  width: 100%;
}

.headers {
  /* background-color: #ffffff; */
  /* background-color: red; */
  color: #343434;
  display: flex;
  justify-content: space-around;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 10vw;
  height: 4vh;
  border-radius: 7px;

  /* border-bottom: 1px solid #c1c1c180; */
}

#prevBtn,
#nextBtn {
  background: none;
  border: none;
  color: #343434;
  cursor: pointer;
  font-size: 12px;
}

#monthYearTodo {
  font-size: 16px;
  /* date font */
  font-weight: 500;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 12px;
  padding: 10px 16px;
}

.TodoformWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 10px 16px;
}

.calendar-summary {
  background-color: #fff9ef;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
}

.calendar-summary-heading {
  background-color: #fef3de;
  color: #444444;
  text-align: center;
  padding: 0.8rem;
  font-size: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-weight: 500;
}

.calendar-schedules {
  display: flex;
  border-bottom: 1px solid #7070705b;
  padding: 0.8rem;
  font-size: 0.9rem;
  font-weight: 500;
  justify-content: space-between;
}

.calendar-schedules-dot {
  background-color: #f8ac1d;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

.dot-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-schedules-date {
  font-size: 0.8rem;
  font-weight: normal !important;
}

.day-week-container {
  border-bottom: 1px solid #7070705b;
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
  padding: 0.5rem;
}

.day-week-button {
  display: flex;
  width: 220px;
  height: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 1px 2px 3px #00000029, 0px 3px 6px #00000029;
  border-radius: 5px;
  justify-content: space-between;
  text-align: center;
  padding: 0.11rem;
  font-size: 0.8rem;
  cursor: pointer;
}

.day-week-active {
  background-color: #f8ac1d;
}

.day-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-radius: 5px;
}

.week-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-radius: 5px;
}

.task-container {
  display: grid;
  border-bottom: 1px solid #7070705b;
  font-size: 0.9rem;
  grid-template-columns: 2fr 1fr 4fr 1fr 2fr;
}

.task-date-container {
  /* padding: 0.4rem 0 0.4rem 2rem;  */
  padding: 0.8rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.calendar-summary-border {
  height: 48px;
  background-color: #7070705b;
  width: 1px;
  margin-left: 2rem;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}

.meetings-flex {
  display: flex;
  justify-content: center;
  text-align: center;
}

.meetings-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropdownIcon {
  font-size: 2rem;
  color: #444444;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.all-task-container {
  height: 420px;
  overflow-y: scroll;
}

.all-task-container::-webkit-scrollbar {
  width: 3px;
}

.all-task-container::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
}

.addTask-main {
  font-family: "Poppins";
  width: 450px;
  position: absolute;
  top: 20%;
  left: 73.8%;
  z-index: 2;
  height: 626px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #70707033;
}

.task-content-inner p {
  font-size: 0.9rem;
}

.addtask-upper {
  width: 400px;
  height: 600px;
}

.task-content {
  margin-top: 2rem;
  position: relative;
  overflow-y: auto;
  height: 480px;
}

.task-content::-webkit-scrollbar {
  width: 3px;
}

.task-content::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
}

.create-task {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task-content-inner input {
  width: 365px;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  height: 43px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #8e8e8e;
  padding: 0px 1rem;
  background-color: transparent;
}

.validateInput {
  border: 1px solid red !important;
}

#todoInput {
  width: 365px;
  margin-top: 0.7rem;
  outline: none;
  color: #00000091;
  margin-bottom: 1rem;
  height: 43px;
  border-radius: 10px;
  border: 1px solid #8e8e8e;
  padding: 0px 0.7rem;
}

.closeAddtask {
  font-size: 1.7rem;
  cursor: pointer;
}

.ClientFormCalendar-todo {
  position: absolute;
  left: 4%;
  top: 55%;
}

.calenderIcon {
  position: absolute;
  left: 83%;
  top: 47%;
  cursor: pointer;
}

.task-time-container {
  padding: 0.8rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  font-size: 0.8rem;
}

.calendar-summary-time-border {
  height: 48px;
  background-color: #7070705b;
  width: 1px;
  justify-content: center;
  align-items: center;
}

.meetings-flex-time {
  display: flex;
  align-items: center;
}

.todo- {
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  outline: none;
  height: 2rem;
  width: 22.9rem;
  padding: 1.3rem 1rem;
  cursor: pointer;
}

.todo-dropdown-icon-container {
  margin-left: -2rem;
  cursor: pointer;
}

.todo-dropdown-icon {
  height: 12px;
  width: 12px;
}

.todo-option-container {
  position: absolute;
  border: 1px solid #70707080;
  border-radius: 10px;
  color: #8e8e8e;
  font-size: 1rem;
  width: 92.5%;
  height: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.6rem;
  opacity: 1;
  margin-top: 0.4rem;
  font-family: Poppins;
  background-color: #fff;
  z-index: 10;
}

.todo-option-container > ul {
  list-style-type: none;
  opacity: 0.54;
  font-size: 1rem;
}

.todo-option-container > ul > li {
  cursor: pointer;
}

.option-li {
  padding: 0.2rem;
  transition: background-color 0.3s ease;
}

.option-li:hover {
  background-color: #fef3de;
  color: black;
  border-radius: 7px;
  padding: 0.2rem;
  opacity: 1;
  color: #f8ac1d;
}

.task-input-wrapper {
  margin-bottom: 1rem;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  font-size: 1.3rem;
  opacity: 0.7;
  position: absolute;
  right: 44px;
  cursor: pointer;
}

.time-component-input {
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
}

.time-component-container {
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  width: 91%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0.8rem;
}

.todo-time-container {
  margin-bottom: 1.5rem;
}

.todo-time-container p {
  font-size: 0.9rem;
  margin-bottom: 0.8rem;
}

.time-icon {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.time-selector {
  display: flex;
  width: 11rem;
  background-color: #fff9ef;
  padding: 0.6rem 0.8rem;
  border-radius: 10px;
  position: absolute;
}

.time-selector-hour {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5rem;
}

.time-input {
  outline: none;
  width: 2.2rem;
  height: 2rem;
  border: 1px solid #f8ac1d;
  border-radius: 3px;
  padding: 0.5rem;
  text-align: center;
}

.time-selector-icon {
  color: #f8ac1d;
  font-size: 1.2rem;
  cursor: pointer;
}

.time-selector-icon-wrapper {
  margin-bottom: -0.28rem;
}

.time-colon {
  margin-top: 1.7rem;
}

.time-meridiem {
  font-size: 0.8rem;
  cursor: pointer;
}

.meridiem-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.6rem;
  margin-left: 0.5rem;
  align-items: center;
}

.meridiem-active {
  background: transparent linear-gradient(128deg, #f8ac1d 0%, #f5eee1 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #f8ac1d;
  border-radius: 3px;
  color: white;
  padding: 0.3rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*---------todo css-----ends---------------------*/

/* border css */
.seprate-line {
  border-top: 1px solid #343434;
}

/* side menu csss starts */

.sub-menu-wrap {
  position: relative;
  top: 0%;
  right: 0%;
  width: 250px;
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.5s;
  justify-content: center;
  display: flex;
  padding-top: 0px;
}

.sub-menu-wrap.open-menu {
  transition: max-height 0.5s;
  max-height: 400px;
}

.sub-menu {
  background: #ffffff;
  padding: 0;
  margin: 0;
  color: #343434;
  font-size: 1rem;
}

.user-info {
  display: flex;
  margin-top: 16px;
  cursor: pointer;
  background: #ffffff;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  border-radius: 12px;
  position: relative;
  align-items: flex-start;
  box-shadow: 1px 2px 5px #c1c1c1;
  transition: all 0.5s;
  animation-duration: 0.5s all;
  animation-timing-function: ease-in 0.5s all;

  /* animation: sliders; */
}

.user-info-open {
  width: 14.5rem;
  pointer-events: "auto";
  padding-left: 0.3rem;
}

.user-info-close {
  width: 5rem;
  pointer-events: none;
  padding-left: 0.8rem;
}

/* -----------------ToDO components-------- -------------------------- */

.sub_todo_view {
  height: 80vh;
  width: 81vw;
  margin-top: 8rem;
  margin-left: 2rem;
  background-color: white;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 3px #c1c1c1;
  border-radius: 10px;
  padding: 1rem 2rem;
}

.todo_table-container {
  height: 75vh;
  width: 79vw;
  margin-left: -0.8rem;
}

.todo_table_top {
  display: flex;
  /* padding: 0 2rem; */
  align-items: center;
  justify-content: space-between;
}

.todo_table_top h2 {
  margin-left: 2rem;
  font-weight: 500;
  color: #1d1d1d;
  font-size: 1.5rem;
}

.todo_table_top button {
  width: 123px;
  height: 45px;
  background: #f8ac1d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  opacity: 1;
}

.todo_table_top button p {
  font-size: 15px;
  font-weight: 500;
}

.plusIcon {
  font-size: 20px;
}

.Todocalendar {
  margin-top: 0.8rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  display: block;
  width: 55vw;

  background-color: #fffbf4;
  overflow-x: auto;
}

.Todocalendar-week {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  display: block;
  width: 55vw;
  background-color: #fffbf4;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 65vh;
}

.Todocalendar-day {
  margin-top: 0.8rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  display: block;
  width: 55vw;
  background-color: #fffbf4;
  overflow-x: auto;
  overflow-y: hidden;
}

.Attendance {
  height: 100%;
  width: 100%;
  position: relative;
  display: grid;
  padding: 0.5rem 0.5rem;
  grid-template-columns: 30% 70%;
}

.todoStatus {
  display: flex;
  align-items: center;
  width: 17vw;
  margin-left: 38.5vw;
  margin-top: 1.2rem;
  gap: 1.6rem;
}

.todo_green_circle {
  background-color: green;
  width: 12px;
  height: 12px;
  border-radius: 50px;
}

.todoStatus-child {
  display: flex;
  gap: 7px;
  align-items: center;
}

.todoStatus-child p {
  font-size: 0.8rem;
  color: #444444;
}

.Todo-header {
  background-color: #fffbf4;
  border-bottom: none;
  padding: 1rem;
}

#monthYear {
  font-size: 0.8rem;
}

#aprevBtn,
#anextBtn {
  background: none;
  border: none;
  color: #343434;
  cursor: pointer;
  font-size: 0.6rem;
}

.todoaday {
  padding: 0.7rem;
  cursor: pointer;
  font-size: 0.8rem;
  color: black;
  width: 144px;
  height: 100px;
  border: 1px solid #70707049;
}

.todo-dropdown-input {
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  outline: none;
  height: 2rem;
  width: 22.9rem;
  padding: 1.3rem 1rem;
  cursor: pointer;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
  box-shadow: 0 0 0 1000px white inset;
}

.current {
  border: 1px solid #f8ac1d;
}

.tasks-container {
  margin-left: -2rem;
  margin-top: 0.8rem;
}

.empty-cell {
  padding: 0.7rem;
  cursor: pointer;
  font-size: 0.8rem;
  color: black;
  width: 30px;
  height: 30px;
}

.empty-cell-todo {
  padding: 0.7rem;
  cursor: pointer;
  font-size: 0.8rem;
  color: black;
  width: 144px;
  height: 83px;
  border-top: 1px solid #70707049;
  border-left: 1px solid #70707049;
}

.sutrday {
  border-right: 1px solid #70707049;
}

.aday:hover {
  background-color: #fef3de;
  color: #f8ac1d;
}

.days-header-todo {
  font-weight: bold;
  width: 100%;
  position: absolute;
  display: grid;
  grid-template-columns: repeat(7, 9%);
  grid-column-gap: 4rem;
  font-size: 0.9rem;
  font-weight: 500;
  /* background-color: lightseagreen; */
  top: 1%;
  padding: 0rem 3rem;
}

/* .addTask-main {
  width: 450px;
  position: absolute;
  top: 23.6%;
  left: 73.8%;
  z-index: 2;
  height: 69%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #70707033;
} */

.task-content-inner p {
  font-size: 0.9rem;
}

.addtask-upper {
  width: 400px;
  height: 600px;
  /* background-color: lightgrey; */
}

.task-content {
  margin-top: 2rem;
  /* position: relative; */
}

.create-task {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task-content-inner input {
  width: 365px;
  margin-top: 0.7rem;
  margin-bottom: 1rem;
  height: 43px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #8e8e8e;
  padding: 0px 1rem;
  background-color: transparent;
}

#cars {
  width: 365px;
  margin-top: 0.7rem;
  outline: none;
  color: #00000091;
  margin-bottom: 1rem;
  height: 43px;
  border-radius: 10px;
  border: 1px solid #8e8e8e;
  padding: 0px 0.7rem;
  background-color: transparent;
}

.addtask-bottom button {
  background: #f8ac1d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  margin-top: 1rem;
  font-size: 1rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0.7rem 0.5rem;
}

.addtask-bottom button p {
  font-size: 15px;
  color: #444444;
  font-weight: 500;
}

.closeAddtask {
  font-size: 1.7rem;
  cursor: pointer;
}

.ClientFormCalendar-todo {
  position: absolute;
  left: 4%;
  top: 55%;
}

.adays-todo {
  display: grid;
  grid-template-columns: repeat(7, 9%);
  grid-column-gap: 3.2rem;
  padding: 1rem 1rem;
  padding-top: 2rem;
  height: 93%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.calendar-box {
  text-align: center;
  width: 100%;
}

.ui-datepicker-header,
.calendar-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.calendar {
  background-color: white;
  border-radius: 8px;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 37%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: none;
  top: 26% !important;
  left: 0% !important;
  height: 100%;
}

.Todo-header-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headers {
  /* background-color: #ffffff; */
  /* background-color: red; */
  color: #343434;
  display: flex;
  justify-content: space-around;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 10vw;
  height: 3.6vh;
  border-radius: 7px;
  border: 1px solid #707070;
  margin-top: 0.5rem;

  /* border-bottom: 1px solid #c1c1c180; */
}

#prevBtn,
#nextBtn {
  background: none;
  border: none;
  color: #343434;
  cursor: pointer;
  font-size: 12px;
}

#monthYearTodo {
  font-size: 16px;
  /* date font */
  font-weight: 500;
  /* background-color:blueviolet; */
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 12px;
  padding: 10px 16px;
}

.day {
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
  font-size: 0.9rem;
  color: black;
}

.day:hover {
  background: #f8ac1d;
  border-radius: 50%;
}

.ui-datepicker-prev {
  order: 1;
}

.day.current {
  color: #f8ac1d;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.day.current:hover {
  background-color: white;
}

.ui-datepicker-next {
  order: 3;
}

.day.selected {
  background-color: #fef3de;
  color: #f8ac1d;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.ui-datepicker-title {
  order: 2;
}

#dateInput {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  resize: vertical;
  font-size: 0.8rem;
  font-weight: 400;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0 2px 1px;
  border: none;
  font-family: "Poppins";
  outline-color: #f8ac1d;
}

.ui-datepicker {
  position: absolute;
}

#dateInput:hover {
  box-shadow: 0px 0px 5px #f8ac1d80;
  border: none;
}

.calendar-summary {
  background-color: #fff9ef;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
}

.calendar-summary-heading {
  background-color: #fef3de;
  color: #444444;
  text-align: center;
  padding: 0.8rem;
  font-size: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  font-weight: 500;
}

.calendar-schedules {
  display: flex;
  border-bottom: 1px solid #7070705b;
  padding: 0.8rem;
  font-size: 0.9rem;
  font-weight: 500;
  justify-content: space-between;
}

.calendar-schedules-dot {
  background-color: #f8ac1d;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

.dot-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-schedules-date {
  font-size: 0.8rem;
  font-weight: normal !important;
}

.day-week-container {
  border-bottom: 1px solid #7070705b;
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
  padding: 0.5rem;
}

.day-week-button {
  display: flex;
  width: 220px;
  height: 35px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: inset 1px 2px 3px #00000029, 0px 3px 6px #00000029;
  border-radius: 5px;
  justify-content: space-between;
  text-align: center;
  padding: 0.11rem;
  font-size: 0.8rem;
  cursor: pointer;
}

.day-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-radius: 5px;
}

.week-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  border-radius: 5px;
}

.task-container {
  display: grid;
  border-bottom: 1px solid #7070705b;
  font-size: 0.9rem;
  grid-template-columns: 2fr 1fr 4fr 1fr 2fr;
}

.task-date-container {
  padding: 0.8rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.calendar-summary-border {
  height: 48px;
  background-color: #7070705b;
  width: 1px;
  margin-left: 2rem;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
}

.meetings-flex {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-left: 2rem;
}

.meetings-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropdownIcon {
  font-size: 2rem;
  color: #444444;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.all-task-container {
  height: 420px;
  overflow-y: scroll;
}

.all-task-container::-webkit-scrollbar {
  width: 3px;
}

.all-task-container::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
}

.TodoformWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 10px 16px;
  height: 65vh;
}

.TodoDates {
  width: 15rem;
  height: 2rem;
  cursor: pointer;
  left: 52%;
  top: 20%;
  background-color: white;
  position: absolute;
  /* z-index: 999; */
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TodoDates-inner {
  display: flex;
  padding: 3px 14px;
  gap: 5px;
  color: #444444;
  border: 1px solid #7070702e;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;
}

.week-day-row-container {
  padding: 1rem;
  width: 95%;
  margin: -0.8rem auto 0;
  display: flex;
  align-items: center;
  font-family: "Poppins";
}

.todo-week-day {
  color: #444444;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
}

.todo-week-month {
  color: #8e8e8e;
  font-size: 0.8rem;
  text-align: center;
}

.todo-week-date-badge {
  background-color: #00000029;
  border-radius: 10px;
  width: 46px;
  height: 42px;
  color: #444444;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.week-day-month-wrapper {
  margin-right: 0.5rem;
}

.todo-task-badge {
  background-color: #fef3de;
  border-left: 2px solid #f8ac1d;
  height: 80%;
  margin-left: 1.8rem;
  padding-right: 2rem;
  padding-left: 0.3rem;
  position: relative;
  cursor: pointer;
}

.todo-task-badge:hover > .badge-popup {
  display: block;
}

.todo-task-hover-container {
  position: absolute;
  /* top: -100%;
  right: 20%;
  z-index: 5;
  display: none; */
}

.todo-task-hover-container.down::after {
  bottom: -15px;
  left: calc(50% - 15px);
}

.todo-task-hover-container.down::after {
  border-top-color: #a53d38;
  border-bottom: 0;
}

.todo-task-parent {
  position: relative;
}

.todo-task-parent:hover > .badge-popup {
  display: block;
}

.badge-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.badge-popup {
  font-family: "Poppins";
  position: absolute;
  padding: 8px 12px;
  margin: 20px 0;
  border-radius: 5px;
  width: 8rem;
  font-size: 1rem;
  text-align: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000001c;
  border: 1px solid #70707080;
  display: none;
  z-index: 2;
  top: -170%;
  cursor: pointer;
}

.badge-deleteIcon {
  color: #d6d3cd;
}

.badge-deleteIcon:hover {
  color: red;
}

.badge-icon {
  color: #d6d3cd;
}

.badge-icon:hover {
  color: #f8ac1d;
}

.badge-popup::after {
  content: "";
  position: absolute;
  bottom: -17px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 8px;
  border-style: solid;
  border-color: #70707080 transparent transparent transparent;
}

.todo-task-badge-task {
  font-size: 0.7rem;
  color: #f8ac1d;
}

.todo-task-badge-time {
  font-size: 0.6rem;
  color: #0f351d;
}

.todo-task-badge-dot {
  position: absolute;
  top: 2px;
  right: -10px;
  /* background-color:#C10000; */
  height: 5px;
  width: 5px;
  border-radius: 50%;
}

.todo-task-badge-dot-yellow {
  background-color: #f8ac1d;
}

.todo-task-badge-dot-green {
  background-color: #46a70a;
}

.todo-task-badge-dot-red {
  background-color: #c10000;
}

.todo-completed-check {
  color: #389300;
  font-size: 2rem;
}

.task-completed {
  color: #46a70a !important;
}

.custom-hr {
  height: 1px;
  background-color: #00000029;
  width: 88%;
  margin-left: 6.5rem;
}

.custom-vr {
  width: 1px;
  background-color: #00000029;
  height: 2.3rem;
  margin-left: 6.4rem;
  margin-top: -0.86rem;
  margin-bottom: 2px;
  opacity: 0.3;
}

.time-row {
  display: flex;
}

.time-row-hr {
  height: 1px;
  background-color: #00000029;
  width: 90%;
  margin: auto;
}

.time-row-time {
  font-size: 0.8rem;
  color: #444444;
  margin-left: 1rem;
}

.TodoTaskBageContainer {
  display: flex;
  margin-left: 4rem;
}

.highlight {
  background-color: #f8ac1d80;
}

.week-date-container {
  margin-top: -1rem;
  margin-bottom: 0.8rem;
  margin-left: 2rem;
  color: #444444;
  font-size: 1rem;
  overflow-x: hidden;
}

.font-size {
  font-size: 0.8rem;
  cursor: pointer;
}

.today-date {
  margin-top: -1rem;
  margin-bottom: 0.8rem;
  margin-left: 1.8rem;
  color: #444444;
  font-size: 1rem;
  font-weight: 500;
}

.time-task-container {
  display: grid;
  border-bottom: 1px solid #7070705b;
  font-size: 0.9rem;
  grid-template-columns: 4fr 1fr 2.5fr 1fr 1.5fr;
  padding: 0.6rem 0;
}

.task-time {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.icon-day-container {
  display: flex;
  align-items: center;
}

.meeting-name {
  margin-left: 0.8rem;
}

.time-slots {
  overflow-y: auto;
  height: 90%;
  padding-bottom: 2rem;
}

.time-slots::-webkit-scrollbar {
  width: 3px;
}

.time-slots::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
}

.badge-container {
  margin-left: 4rem;
  display: flex;
  height: 2.4rem;
}

.week-badge-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
}

/* ------------------------------------------------------- */

.user-info2 {
  display: flex;
  margin-top: 16px;
  cursor: pointer;
  background: #ffffff;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  border-radius: 12px;
  position: relative;
  align-items: center;
  box-shadow: 1px 2px 5px #c1c1c1;
  transition: all 0.5s;
  animation-duration: 0.5s all;
  animation-timing-function: ease-in 0.5s all;
  display: none;
}

@keyframes sliders {
  from {
    margin-left: 100%;
    width: 300%;
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    margin-left: 0%;
    width: 100%;
    opacity: 1;
    transform: translateX(0);
  }
}

.user-info h3 {
  font-weight: 500;
}

.user-info img {
  width: 60px;
  border-radius: 50%;
  margin-right: 4px;
}

.sub-menu hr {
  border: 0;
  height: 1px;
  width: 190px;
  background: #ccc;
  margin: 15px 0px 10px;
}

.sub-menue-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f8ac1d;
  margin: 12px 0px;
}

.sub-menue-link {
  width: 100%;
  align-items: center;
  justify-content: center;
  color: black;
}

.profile-icon {
  width: 40px;
  background: #ccc;
  border-radius: 50%;
  padding: 8px;
  margin-right: 15px;
}

.sub-menu-link span {
  font-size: 16px;
  transition: transform 0.1s;
}

.sub-menue-link:hover span {
  transform: translate(5px);
}

.sub-menue-link:hover p {
  background: #fef3de;
  color: #f8ac1d;
  transition: all 0.1s;
}

.main-profile-item {
  font-size: 11px;
  text-wrap: nowrap;
  font-weight: 600;
}

.main-profile-item h2 {
  font-weight: 500;
}

.main-profile-item p {
  font-weight: 400;
  font-size: 14px;
}

.user-profile {
  animation: user-details-animation 0.3s ease 0.3s forwards;
  opacity: 0;
  visibility: hidden;
}

@keyframes user-details-animation {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.user-leaves {
  transition: all 0.3s ease;
}

.user-leave-hide {
  display: none;
}

.user-leaves-show {
  display: block;
}

.sub-menue-link p {
  padding: 5px 50px;
  border-radius: 10px;
}

/* overlay property added */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  padding: 0;
}

.ineer-menue {
  display: flex;
  padding: 0px 0px;
  margin: 10px 0px;
  /* align-items: center; */
  justify-content: center;
  gap: 10px;
}

.ineer-menue img {
  height: 50px;
  width: 50px;
}

/* side menu csss ends */

/*------------------------------------------------------ dashboard css starts ------------------------------------------------------------------------*/
.container {
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  position: relative;
}

/* topbar css starts */
/* .top-bar {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  background: #ffff;
  padding: 15px 16px;
  margin-top: 0px;
  position: fixed;
  z-index: 3;
  box-shadow: rgba(99, 99, 99, 0.2) 6px 0px 8px 0px;
  gap: 65%;
} */

/* .left-side-heading {
  color: #191919;
  font-size: 24px;
  font-weight: 500;
  margin-left: 12%;
} */

.top-bar {
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 75px;
  flex-direction: row;
  align-items: center;
  background: #ffff;
  margin-top: 0px;
  position: fixed;
  top: 0;
  z-index: 3;
  padding-left: 18rem;
  box-shadow: 2px 4px 10px #99886b66;
  /* gap: 65%; */
}

.left-side-heading {
  color: #191919;
  font-size: 1.5rem;
  font-weight: 500;
  transition: 0.5s ease;
  /* margin-left: 12%; */
}

.left-side-heading-open {
  margin-left: 0;
}

.left-side-heading-close {
  margin-left: -9.5rem;
}

.right-side-icons {
  color: #191919;
  font-size: 26px;
  font-weight: 600;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3%;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #ff0000;
  border-radius: 50%;
  position: relative;
  top: 10px;
  right: 20px;
}

/* .top-icon {
  padding: 5px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  height: 33px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0 2px 1px;
} */

.top-icon-bell,
.filter-icon,
.filter-top-icon,
.admin-filter-top-icon,
.plus-icon {
  padding: 9px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  box-shadow: 1px 2px 5px #00000029;
  font-size: 25px;
  color: #343434;
  height: 45px;
  width: 45px;
  cursor: pointer;
}

.plus-icon {
  background: #f8ac1d;
}

.filter-icon {
  height: 45px;
  width: 77px;
  display: flex;
  gap: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  box-shadow: 1px 2px 5px #00000029;
}

.search-box {
  cursor: pointer;
}

/* .filter-top-icon {
  padding: 2px 6px;
} */

/* topbar css ends */

/* search bar css starts */

/* .search-input {
  background: #ffffff;
  color: #343434;
  outline: none;
  border: none;
  line-height: 30px;
  width: 0px;
  float: left;
  font-size: 14px;
  transition: 0.7s ease;
  font-family: sans-serif;
  border-radius: 9px;
  padding-left: 0px;
}

.search-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: #fffdfd;
  padding: 0px;
  padding-top: 3px;
  border-radius: 4%;
  float: left;
  color: #343434;
  transition: 0.4s ease;
  font-size: 24px;
}

.search-btn-ticket-section {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: #fffdfd;
  padding: 8px 2px 4px 1px;
   padding-top: 7px; 
  border-radius: 4%;
  float: left;
  color: #343434;
  transition: 0.4s ease;
  font-size: 22px;
   font-weight: 800; 
  margin-top: 0px;
  height: 38px;
  width: 26px;
}

.search-box {
  display: flex;
}

.search-box:hover > .search-input {
  width: 240px;
  margin: 0px 0px;
   padding-left: 10px; 
}

.search-input:focus {
  width: 240px;
  margin: 0px 8px;
}

.search-box:hover > .search-btn {
  background: #fff;
}

.search-input:focus + .search-btn {
  background: #fff;
}

.search-box:hover > .search-btn-ticket-section {
  background: #fff;
}

.search-input:focus + .search-btn-ticket-section {
  background: #fff;
} */

.search-input {
  color: #343434;
  outline: none;
  border: none;
  width: 0px;
  float: left;
  font-size: 14px;
  transition: 0.7s ease;
  font-family: sans-serif;
  border-radius: 9px;
}

/* 
.admin-search-input{
  color: #343434;
  outline: none;
  border: none;
  width: 0px;
  float: left;
  font-size: 14px;
  transition: 0.7s ease;
  font-family: sans-serif;
  border-radius: 9px;
width: 300px;
padding: 0.5rem;
display: none;
} */

.admin-filter-search-box {
  padding: 9px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  box-shadow: 1px 2px 5px #00000029;
  font-size: 25px;
  color: #343434;
  height: 45px;
  width: 45px;
  cursor: pointer;
}

.search-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 0px;
  border-radius: 4%;
  float: left;
  color: #343434;
  transition: 0.4s ease;
  font-size: 1.5rem;
}

.iconColor {
  color: #343434;
}

.excelIcon {
  margin-top: 0.38rem;
}

.excelIconParent {
  height: 2.74rem;
}

.search-box {
  display: flex;
}

.top-icon {
  padding: 1.4rem 0.7rem;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  height: 33px;
  box-shadow: 1px 2px 5px #00000029;
}

.top-icon:hover .search-input {
  width: 240px;
  padding: 0.8rem 0.1rem;
}

.filter-icon .search-input::selection {
  width: 240px;
  padding: 0.8rem 0.1rem;
}

.inputSearchWritten {
  width: 240px;
  padding: 0.8rem 0.1rem;
}

/* 
.search-input:focus {
  width: 240px;
  margin: 0px 8px;
} */

/* .search-box:hover>.search-btn {
  background: #fff;
} */

/* .search-input:focus+.search-btn {
  background: #fff;
} */

/* .search-box:hover>.search-btn-ticket-section {
  background: #fff;
}

.search-input:focus+.search-btn-ticket-section {
  background: #fff;
} */
footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #151515;
  height: 35px;
  text-align: center;
  line-height: 35px;
}

footer a {
  text-decoration: none;
  color: #ff5722;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  transition: 0.4s;
}

footer a:hover {
  color: #ff6b3b;
}

/* search bar css ends  */

/* dashboard carosel css starts */

.main-crouser {
  width: 100%;
  height: 10rem;
  background: #ffffff;
  padding-top: 0.1rem;
  padding-left: 0.5rem;
  border-radius: 9px;
}

.carosel-Navigators-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  top: 5.7rem;
  position: relative;
  cursor: pointer;
  font-size: 1.5rem;
  color: #343434;
}

.notification-all {
  animation-duration: 1s;
  animation-timing-function: ease-in 2s;
}

/* ---------------------------------------------------------------------- */
@keyframes NotificationRightToLeft {
  from {
    margin-left: 100%;
    width: 300%;
    opacity: 0;
    transform: translateX(-20px);
  }

  25% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(0px);
  }

  75% {
    transform: translateX(2px);
  }

  to {
    margin-left: 0%;
    width: 100%;
    opacity: 1;
    transform: translateX(0);
  }
}

/* croser arrow controllers start */

.carosel-controoler-button1 {
  position: relative;
  z-index: 1;
  left: -8px;
}

.carosel-controoler-button2 {
  position: relative;
  z-index: 1;
  right: -8px;
}

/* croser arrow controllers ends */

.second-carusel {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 8px;
}

/* .second-carusel:hover {
  cursor: pointer;
} */

.ineer-engg-crouser {
  display: flex;
  padding: 10px;
  gap: 12px;
}

.engg-profile {
  font-size: 0.9rem;
  text-wrap: nowrap;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  letter-spacing: 0px;
  color: #444444;
  font-family: "Poppins";
}

.star-icon {
  border: 1px solid #f8ac1d;
  border-radius: 4px;
  margin: 3px 0px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 4px;
  padding-left: 5px;
  padding-right: 0px;
}

.slick-slide {
  /* border: 1px solid #dfd5d580; */
  border-radius: 9px;
  margin-right: 20px;
  margin-left: 20px;
  gap: 4rem;
  display: flex;
  position: relative;
  /* box-shadow: ; */
  /* border-width: 1px; */
  /* box-shadow: 1px 2px 5px #f8ac1d80 */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 0 2px 1px; */
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
}

.slick-slide::selection {
  box-shadow: none;
}

.slick-dots {
  display: none !important;
}

.main-crouser:hover {
  box-shadow: 1px 2px 5px #f8ac1d80 !important;
  border-color: solid #f8ac1d80 !important;
}

.slick-slider .slick-track {
  /* gap: 20px !important; */
  display: flex;
  transition: all 2s ease 0s;
  justify-content: center;
}

.slick-list {
  overflow: visible;
}

.basic-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.8rem;
}

.basic-info-profile {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.message-icon {
  margin-right: 8px;
}

.message-icon span {
  font-size: 20px;
  padding: 2px 8px;
  border-radius: 6px;
  font-weight: 100;
  cursor: pointer;
  color: #343434;
  box-shadow: 1px 2px 5px #00000029;
}

/* badges on engg crouser ---------- */
.message-icon .serviceEnggCrouserBadges {
  margin-right: 10px;
  background-color: #fef3de;
  font-size: 10px;
  color: #f8ac1d;
  padding: 2px 4px 2px 4px;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 6px;
  border-radius: 2px;
}

/* --------------------------------- */

.file-icon {
  margin-top: 3px;
}

.mic-icon,
.send-icon,
.main-file-icon {
  font-size: 1rem;
}

.message-dot {
  height: 6px;
  width: 6px;
  background-color: #ff0000;
  border-radius: 50%;
  position: relative;
  top: -25px;
  right: -22px;
  display: none;
}

.skill-box {
  width: 50%;
  margin: 25px 0px;
}

.skill-box .title {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.skill-box .skill-bar {
  height: 2px;
  width: 100%;
  border-radius: 6px;
  margin-top: 6px;
  background: rgba(0, 0, 0, 0.1);
}

.skill-box .skill-per {
  position: relative;
  display: block;
  height: 100%;
  width: 95%;
  border-radius: 6px;
  background: #c1c1c1;
  animation: progress 0.4s ease-in-out forwards;
  opacity: 0;
}

.skill-per.css {
  width: 100%;
  animation-delay: 0.1s;
}

.skill-per.reactjs {
  width: 100%;
  animation-delay: 0.3s;
}

@keyframes progress {
  0% {
    width: 0;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.skill-per .tooltip {
  position: absolute;
  right: -14px;
  top: -28px;
  font-size: 9px;
  font-weight: 500;
  color: #444;
  padding: 2px 6px;
  border-radius: 3px;
  background: #f8ac1d;
}

.tooltip::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -3px;
  height: 6px;
  width: 6px;
  z-index: 0;
  background-color: #f8ac1d;
  transform: translateX(-50%) rotate(45deg);
}

/* skill box starts */

.skill-box {
  position: relative;
  width: 90%;
  margin: 20px 0px;
  padding: 0px 14px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.dots2 {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-left: 7px;
}

.dot-progress,
.dot-progress2 {
  width: 8px;
  height: 8px;
  background-color: #343434;
  border-radius: 50%;
  margin: -4px 19px;
  position: absolute;
  top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot-progress {
  right: 18px;
}

.dot-progress2 {
  left: 0;
}

.skill-box .title {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.skill-box .skill-bar {
  height: 2px;
  width: calc(100% - 40px);
  border-radius: 6px;
  background: #c1c1c1;
}

.skill-box .skill-per {
  position: relative;
  display: block;
  height: 100%;
  /* manupulate the pointer on the progress bar */
  width: 25%;
  border-radius: 6px;
  background: #c1c1c1;
  animation: progress 0.4s ease-in-out forwards;
  opacity: 0;
}

.hover-icon-service {
  display: flex;
  flex-direction: row;
  font-size: 0.8rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  letter-spacing: 0px;
}

.hover-icon-service span {
  font-weight: 400;
  color: #444444;
}

.initial-service {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
}

.next-service {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  cursor: pointer;
}

.dropdown,
.dropdown2 {
  color: black;
  cursor: pointer;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: perspective(1000px);
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

/* inside the table address hovering dropdown starts */
.address {
  position: relative;
  transition: 1s all ease-in;
  cursor: pointer;
}

.address:hover .dropdown-address span {
  opacity: 0;
}

.address:hover .dropdown-address-menu {
  opacity: 1;
  visibility: visible;
}

.dropdown-address-menu {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: white;
  transition: opacity 0.3s ease-out;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  top: 20%;
  left: 0%;
}

/* inside the table address hovering dropdown ends */

.dropdown2 {
  justify-content: flex-start;
  align-items: flex-end;
  /* z-index: 1; */
}

.dropdown3 {
  cursor: pointer;
  z-index: 9999;
  position: relative;
  display: flex;
  align-items: center;
  width: 14%;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -2rem;
  margin-right: 1rem;
}

.dropdown3-menu {
  left: -13.7rem;
  margin-top: -2.5rem;
}

.dropdown-menu {
  overflow: hidden;
  list-style-type: none;
  padding: 0;
  position: absolute;
  opacity: 0;
  text-align: center;
  top: 0;
  visibility: hidden;
  z-index: -99999;
  box-shadow: 0px 0px 20px #c1c1c1;
  border-radius: 10px;
  opacity: 1;
}

.dropdown-menu li:first-child {
  cursor: default;
}

.dropdown-menu a {
  color: #000;
  display: inline-block;
  width: 100%;
  text-decoration: none;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.dropdown:hover .dropdown1-menu {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* background: white; */
  opacity: 1;
  visibility: visible;
  top: 2%;
  /* margin-left: -0.2rem; */
  border-radius: 10px;
  /* width: 166px; */
  height: auto;
  overflow: hidden;
  z-index: 99999;
}

.dropdown1-menu {
  width: 166px;
  background-color: white;
}

.dropdown2 .dropdown-menu {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  opacity: 1;
  visibility: visible;
  top: 4%;
  border-radius: 10px;
  height: auto;
  margin-left: -6.2rem;
  z-index: 9999;
}

.dropdown2-menu {
  width: 166px;
  background: white;
}

.dropdown3:hover .dropdown-menu {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* background: white; */
  opacity: 1;
  visibility: visible;
  top: 75%;
  right: 0;
  margin-left: 2rem;
  /* transition: all 0.5s, background 2s 0.5s linear; */
  border-radius: 10px;
  /* width: 225px; */
  height: auto;
}

.dropdown3-menu {
  width: 250px;
  background-color: white;
}

.pie-chart-detail:hover .dropdown-menu {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background: white;
  opacity: 1;
  visibility: visible;
  top: 4%;
  transition: all 0.5s, background 2s 0.5s linear;
  border-radius: 10px;
  z-index: 99999;
  width: 166px;
  height: auto;
}

.drop-parent {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  border-radius: 10px;
}

.lower-sec {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.upper-sec {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.upper-sec p:first-child {
  font-size: 14px;
  font-weight: 700;
  color: #343434;
}

.upper-sec p:last-child {
  font-size: 14px;
  font-weight: 600;
  color: #343434;
}

.horizontal-row {
  border-bottom: 1px solid #c1c1c1;
  width: 120%;
  height: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.horizontal-row-container {
  width: 100%;
  height: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.horizontal-row2 {
  border-bottom: 1px solid #c1c1c1;
  width: 100%;
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.main-head-div {
  display: flex;
  justify-content: center;
  height: 122px;
  align-items: center;
  margin-top: -15px;
}

/* comment for resolve conflict of recharts libary comment by aayush */
/* .recharts-responsive-container {
  width: 74px !important;
  height: 85px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: -12px;
}

.recharts-surface {
  width: 92% !important;
  height: 65% !important;
}

.recharts-wrapper {
  position: relative;
  cursor: default;
  width: 100%;
  height: 100%;
  max-height: 118px;
  max-width: 160px;
  cursor: pointer !important;
} */

.task-dot {
  height: 8px;
  width: 8px;
  background-color: #dd4343;
  border-radius: 50%;
}

.dot-name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
}

.task-main-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.tasks-heading {
  position: relative;
  top: 11px;

  font-size: 18px;
  font-weight: 700;
  margin-bottom: 20px;
}

.taskmain-info {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  font-size: 14px;
  font-weight: 500;
}

/* dashboard carosel css ends */

/* taskLocationsection css starts */

.parent-full-div {
  z-index: 0;
}

.parent-full-div {
  border-radius: 10px;
  margin-top: 25px;
  /* padding-top: 5px; */
  background-color: red;
  background: #ffffff;
  z-index: -1;
}

.child-div {
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 10px;
  box-shadow: 1px 2px 5px #99886b66;
  border-radius: 10px;
}

.task-child-div {
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 10px;
  box-shadow: 1px 2px 5px #99886b66;
  border-radius: 10px;
}

.tasks-section {
  width: 33%;
}

.tasks-section-on-kanban {
  width: 56%;
  animation: move-words 0.4s linear forwards;
  transition: 0.3s;
}

@keyframes move-words {
  0% {
    left: 40%;
  }

  100% {
    left: 100%;
  }
}

.kanban-cursor {
  cursor: pointer;
}

.Report-section {
  width: 33%;
  animation: changeText 1s linear forwards;
}

/* .task-child-div {
  height: 41vh;
} */

.location-section {
  width: 33%;
  animation: changeText 1s linear forwards;
}

.task-top-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: -1;
}

.sub-components {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  z-index: 1;
  animation: changeKanban 1.5s ease forwards;
}

.task-description-section {
  display: grid;
  margin-top: 0.5rem;
  grid-template-columns: repeat(2, calc(50% - 1rem));
  justify-content: center;
  gap: 1rem;
  /* height: 300px; */
  max-height: 300px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  animation: changeText 0.7s linear forwards;
}

/* set scroll behaviour  */

.task-description-section:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d;
}

.task-description-section::-webkit-scrollbar {
  width: 2px;
  /* width of the entire scrollbar */
}

.task-description-section::-webkit-scrollbar-track {
  background: 0;
  /* color of the tracking area */
  margin-top: 10px;
  margin-bottom: 10px;
}

.task-description-section::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 1px solid #ffffff;
  /* creates padding around scroll thumb */
  transition: background-color 0.3s ease;
  /* Adjust the transition duration as needed */
}

.task-description-section .more-descriptive {
  height: 120px;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* border: 1px solid #c1c1c180; */
  cursor: pointer;
  /* padding-top: 4px; */

  margin-top: 6px;
}

.task-description-section .more-descriptive:hover {
  box-shadow: 0px 0px 5px #f8ac1d80;
}

/* change by aayush  */
.report-description-section {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(1, calc(100% - 1rem));
  justify-content: center;
  gap: 1rem;
  position: relative;
}

/* change by aayush  */

.report-description-section .more-descriptive {
  position: relative;
  height: 320px;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #c1c1c180;
  margin-top: -6px;
}

.task-top-section p {
  color: #191919;
  font-size: 1.2rem;
  font-weight: 500;
  margin-left: 0.8rem;
}

.sub-components span {
  padding: 4px 12px;
  border-radius: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  color: #343434;
  font-weight: 500;
  font-size: 16px;
}

.sub-components span:hover {
  color: #f8ac1d;
  background: #fef3de;
  transition: all 0.5s;
  cursor: pointer;
}

.sub-components p {
  font-size: 14px;
  margin-right: 10px;
}

.more-descriptive .detail {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  flex-wrap: nowrap;
  font-size: 12px;
  padding: 12px;
  line-height: 1;
  border-radius: 10px;
  height: 94px;
}

/* .more-descriptive .detail:hover{
  box-shadow: 0px 0px 5px #F8AC1D80
} */

.more-descriptive .detail .heading {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  gap: 3px;
  width: 100%;
  font-weight: 700;
}

.more-descriptive .detail .heading-detail {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  gap: 3px;
  width: 100%;
}

.kanban-button {
  /* padding: 4px 24px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid #f8ac1d;
  background: #f8ac1d;
  cursor: pointer;
  color: #444444;
  font-size: 16px;
  margin-left: auto;
  margin-right: 5px;
  margin-bottom: 2px; */
  /* change by ayyush */
  padding: 0.5rem 1.5rem;
  border: 1px solid #f8ac1d;
  background: #f8ac1d;
  font-size: 0.8rem;
  border-radius: 5px;
  margin-left: auto;
  margin-right: 5px;
  margin-bottom: 2px;
  background: #f8ac1d;
  cursor: pointer;
}

.kanban-button-location:hover {
  background: #f8ac1d;
}

.kanban-button-location {
  /* padding: 4px 24px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid #f8ac1d;
  cursor: pointer;
  color: #444444;
  font-size: 16px;
  margin-left: auto;
  margin-right: 5px;
  margin-bottom: 2px; */
  padding: 0.5rem 1.5rem;
  border: 1px solid #f8ac1d;
  /* background: #f8ac1d; */
  font-size: 0.8rem;
  border-radius: 5px;
  margin-left: auto;
  margin-right: 5px;
  margin-bottom: 2px;
  /* background: #f8ac1d; */
  cursor: pointer;
}

.kanban-Click-section {
  display: none;
}

.ticket-service-flex {
  display: flex;
}

.switch-button p {
  padding: 4px 24px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #191919;
  font-weight: 500;
  font-size: 16px;
  margin-left: 0px;
}

.switch-button-hover {
  color: #f8ac1d !important;
  background: #fef3de;
  transition: all 0.5s;
  cursor: pointer;
}

.switch-button-without-hover {
  color: #000000;
  background: #ffffff;
  transition: all 0.5s;
  cursor: pointer;
}

.Report-heading-detail,
.Report-heading-detail-ans {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  text-wrap: nowrap;
}

.Report-heading-detail {
  font-weight: 600;
}

.more-descriptive-report {
  height: 320px;
  width: 100%;
  border-radius: 10px;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* set body scroll property starts */
.more-descriptive-report:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d;
  /* Change the color to your desired hover color */
}

/* set scroll behaviour  */
.more-descriptive-report::-webkit-scrollbar {
  width: 2px;
  /* width of the entire scrollbar */
}

.more-descriptive-report::-webkit-scrollbar-track {
  background: 0;
  /* color of the tracking area */
  margin-top: 10px;
  margin-bottom: 10px;
}

.more-descriptive-report::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  /* color of the scroll thumb */
  border-radius: 10px;
  /* roundness of the scroll thumb */
  border: 1px solid #ffffff;
  /* creates padding around scroll thumb */
  transition: background-color 0.3s ease;
  /* Adjust the transition duration as needed */
}

/* set body scroll property ends */
/* change by aayush */
.child-descriptive {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 5px #c1c1c180;
  border-radius: 10px;
  opacity: 1;
  /* padding: 14px 28px; */
}

.star-icon2 {
  border: 1px solid #f8ac1d;
  border-radius: 4px;
  margin-left: 15px;
  padding: 2px 2px;
}

/* Table */
.customer-table,
.customer-table1 {
  font-family: "Noto Sans KR", sans-serif;
  width: 100%;
  max-width: 100%;
  margin: 10px auto;
  border-collapse: collapse;
  text-align: center;
}

.customer-table1 {
  margin: 2px auto;
}

.customer-table {
  font-size: 0.625em;
}

.customer-table1 th {
  padding: 3px 5px;
}

.customer-table1 th {
  padding: 5px 5px;
  font-weight: 600;
  color: #343434;
}

.customer-table1 tbody th,
.customer-table1 tbody td {
  text-align: start;
}

.customer-table1 tbody tr {
  /* border-bottom: 2px solid transparent; */
  color: #343434;
}

/* Media Queries */
@media (max-width: 320px) {
  .customer-table {
    font-size: 0.55em;
  }
}

@media (min-width: 411px) {
  .customer-table {
    font-size: 0.71875em;
    /*11.5px*/
  }
}

@media (min-width: 768px) {
  body {
    font-size: 1.125em;
    /*16px*/
  }
}

@media (min-width: 1024px) {
  body {
    font-size: 1.25em;
    /*20px*/
  }
}

/* taskLocationsection css ends */

/* tasks section css starts  */
.my_table-container {
  height: 380px;
  max-height: 380px;
  overflow-y: auto;
  border-radius: 20px;
  margin-top: 1rem;
}

.table-skeletron {
  width: 74vw;
}

.table-shadow,
.eng-table-shadow {
  height: 3.7rem;
  width: 96.5%;
  box-shadow: 0px 0px 5px #c1c1c180;
  position: absolute;
  background-color: white;
  border-radius: 10px;
}

.eng-table-shadow {
  width: 100%;
  border-radius: 5px;
}

.my_table-container table {
  width: 100%;
  border-collapse: collapse;

  position: relative;
}

.my_table-container thead {
  position: sticky;
  top: 0%;
  background-color: white;
  border-radius: 10px;
  z-index: 1;
}

.my_table-container:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d;
}

.my_table-container::-webkit-scrollbar {
  width: 2px;
}

.my_table-container::-webkit-scrollbar-track {
  background: 0;
  margin-top: 4rem;
  margin-bottom: 10px;
}

.my_table-container::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  border-radius: 20px;
  border: 1px solid #ffffff;
  transition: background-color 0.3s ease;
}

.my_table-container table th {
  font-size: 1rem;
  text-align: start;
  font-weight: 500;
  padding: 2px 6px;
  font-size: 1rem;
  text-align: start;
  font-weight: 500;
  padding: 20px 6px;

  font-size: 0.9rem;
  color: #444444;
  font-weight: 490;
  font-family: "Poppins";
  /* box-shadow: rgba(99, 99, 99, 0.2) 8px 2px 8px 0px; */

  line-height: 0.9rem;
}

.my_table-container table td {
  font-size: 1rem;
  text-align: start;
  font-weight: 500;
  padding: 20px 6px;
  border-bottom: 1px solid #c1c1c180;
  font-size: 0.9rem;
  color: #444444;
  font-weight: 490;
  font-family: "Poppins";
  line-height: 0.9rem;
}

/* ------------------------------------------------------------------------------------sevice req table by aayush malviya start------------------------------------------------------- */

.service-request-table {
  height: 380px;
  max-height: 380px;
  overflow-y: auto;
  border-radius: 20px;
  margin-top: 1rem;
  overflow-x: hidden;
}

.service-request-table table {
  width: 100%;
  border-collapse: collapse;
}

.service-request-table thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9;
}

/* set body scroll property starts */
.service-request-table:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d;
  /* Change the color to your desired hover color */
}

/* set scroll behaviour  */
.service-request-table::-webkit-scrollbar {
  width: 2px;
  /* width of the entire scrollbar */
}

.service-request-table::-webkit-scrollbar-track {
  background: 0;
  margin-top: 4rem;
  margin-bottom: 10px;
}

.service-request-table::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  border-radius: 20px;
  border: 1px solid #ffffff;
  transition: background-color 0.3s ease;
}

.service-request-table table th {
  font-size: 1rem;
  text-align: start;
  font-weight: 500;
  padding: 2px 6px;
  font-size: 1rem;
  text-align: start;
  font-weight: 500;
  padding: 20px 6px;
  font-size: 0.9rem;
  color: #444444;
  font-weight: 490;
  font-family: "Poppins";
  line-height: 0.9rem;
}

.service-request-table table td {
  font-size: 1rem;
  text-align: start;
  font-weight: 500;
  padding: 20px 6px;
  border-bottom: 1px solid #c1c1c180;
  font-size: 0.9rem;
  color: #444444;
  font-weight: 490;
  font-family: "Poppins";
  line-height: 0.9rem;
}

/*---------------------------------------------------------- service req table end by aayush------------------------------------------------------------------  */

/*---------------------------------------------------------- service schedule  table start by aayush------------------------------------------------------------------  */

.service-schedule-table {
  height: 380px;
  max-height: 380px;
  overflow-y: auto;
  border-radius: 20px;
  margin-top: 1rem;
  padding: 20x;
}

.service-schedule-table table {
  width: 100%;
  border-collapse: collapse;
}

.service-schedule-table thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9;
}

/* set body scroll property starts */
.service-schedule-table:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d;
  /* Change the color to your desired hover color */
}

/* set scroll behaviour  */
.service-schedule-table::-webkit-scrollbar {
  width: 2px;
  /* width of the entire scrollbar */
}

.service-schedule-table::-webkit-scrollbar-track {
  background: 0;
  margin-top: 4rem;
  margin-bottom: 10px;
}

.service-schedule-table::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  border-radius: 20px;
  border: 1px solid #ffffff;
  transition: background-color 0.3s ease;
}

.service-schedule-table table th {
  font-size: 1rem;
  text-align: start;
  font-weight: 500;
  padding: 2px 6px;
  font-size: 1rem;
  text-align: start;
  font-weight: 500;
  padding: 20px 6px;
  font-size: 0.9rem;
  color: #444444;
  font-weight: 490;
  font-family: "Poppins";
  /* box-shadow: rgba(99, 99, 99, 0.2) 8px 2px 8px 0px; */

  line-height: 0.9rem;
}

.service-schedule-table table td {
  font-size: 1rem;
  text-align: start;
  font-weight: 500;
  padding: 20px 6px;
  border-bottom: 1px solid #c1c1c180;
  font-size: 0.9rem;
  color: #444444;
  font-weight: 490;
  font-family: "Poppins";
  line-height: 0.9rem;
}

/*---------------------------------------------------------- service schedule  table end by aayush------------------------------------------------------------------  */
/* set body scroll property ends */

.child-ticket-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  padding-top: 8px;
  gap: 12px;
  position: relative;
}

.heading-icon-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
}

.icon-align-div {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  width: 143px;
}

/* Add your other styles as needed */

.ticket-section-heading {
  font-size: 1.2rem;
  font-weight: 600;
}

.ticket-section-heading span:nth-child(1) {
  color: #191919;

  font-size: 1.25rem;
  font-weight: 500;

  margin-left: -4px;

  font-family: "Poppins";
}

.ticket-section-heading span:nth-child(2) {
  color: #191919;
}

.ticket-section-heading span:nth-child(4) {
  color: #191919;
}

.sub-components-ticket-filter {
  box-shadow: none;
  padding: 0px 0px;
  border-radius: 6px;
}

.checkbox.style-c {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox.style-c input {
  cursor: pointer;
  height: 0;
  width: 0;
  opacity: 0;
}

.checkbox.style-c input:checked ~ .checkbox__checkmark {
  background-color: #ffffff;
}

.checkbox.style-c input:checked ~ .checkbox__checkmark:after {
  opacity: 1;
}

/* .checkbox.style-c:hover input~.checkbox__checkmark {
  /* background-color: #eee; */
/* }  */

.checkbox.style-c:hover input:checked ~ .checkbox__checkmark {
  background-color: #ffffff;
}

.checkbox.style-c .checkbox__checkmark {
  position: absolute;
  /* top: 2px; */
  top: 0%;
  left: 0;
  height: 20px;
  margin-left: 0.4rem;
  width: 20px;
  transition: background-color 0.25s ease;
  border-radius: 4px;
  border: 1px solid #c1c1c1;
}

.sosCheckbox {
  height: 18px;
  width: 18px;
  margin-left: 5px;
  border-radius: 4px;
  border: 1px solid #c1c1c1;
  transition: background-color 0.25s ease;
}

.checkbox.style-c .checkbox__checkmark:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #020202;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.25s ease;
}

.checkbox.style-c .checkbox__body {
  color: #000000;
  line-height: 1.4;
  font-size: 16px;
}

.assign-dropdown select {
  padding: 12px 0px;
  border-radius: 6px;
  border: 1px solid #fef3de;
  background: #fef3de;
  color: #f8ac1d;
}

.horizontal-row-filter {
  border-bottom: 1px solid #c1c1c1;
  width: 81%;
  height: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

/* check box css code ends here */

/* tasks section css end */

/* filter dropdown css starts */

.child-filter-dropdown {
  padding: 10px;
  /* background-color: red; */
}

.dropdown-content {
  position: absolute;
  z-index: 1;
  margin-top: 14.2rem;
  margin-left: 124px;
  background: rgb(253, 253, 253);
  width: 160px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 4px;
  top: 0;
}

.dropdown-content-filter {
  position: absolute;
  margin-top: 4rem;
  background: rgb(253, 253, 253);
  width: 160px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 4px;
  top: 0;
  margin-left: -115px;
  z-index: 99999;
}

.search-icon-filter,
.search-ticket-filter-icon {
  position: absolute;
  top: 17px;
  left: 5px;
}

.search-ticket-filter-icon {
  left: 15px;
}

.search-bar-div {
  border: 1px solid #c1c1c180;
  border-radius: 7px;
}

.search-bar-div input {
  background: transparent;
  border: 0;
  outline: 0;
  width: 120px;
  margin-left: 27px;
  padding: 7px 0px;
}

.filter-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 4px 14px;
}

.filter-lists {
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 12px;
  list-style-type: none;
  cursor: pointer;
  margin-top: 6px;
  font-weight: 500;
}

.filter-icons span {
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 700;
}

.filter-icons span:hover {
  background: white;
  color: black;
}

.listing-filter2 {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.filter-lists li {
  padding: 2px;
}

.filter-lists li:hover {
  background: #fef3de;
  color: #f8ac1d;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 6px;
}

.search-bar-div {
  border: 1px solid #c1c1c180;
  border-radius: 5px;
}

.horizontal-row-filter:hover {
  background-color: white !important;
}

.filter-dropdowns {
  margin-top: 10px;
}

.child-filter-dropdown {
  padding: 10px;
  position: relative;
  max-height: 300px;
}

.child-filter-dropdown {
  padding: 10px;
  position: relative;
  /* background-color: red; */
}

.dropdown-content {
  position: absolute;
  z-index: 1;
  margin-left: -7rem;
  width: 160px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 4px;
  background-color: #fff;
  top: 12%;
}

.search-icon-filter,
.search-ticket-filter-icon {
  position: absolute;
  top: 17px;
  left: 5px;
}

.search-ticket-filter-icon {
  left: 15px;
}

.search-bar-div {
  border: 1px solid #c1c1c180;
  border-radius: 7px;
}

.search-bar-div input {
  background: transparent;
  border: 0;
  outline: 0;
  width: 100px;
  margin-left: 27px;
  padding: 7px 0px;
}

.filter-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 4px 14px;
}

.filter-lists {
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  list-style-type: none;
  cursor: pointer;
  margin-top: 6px;
  overflow-y: scroll;
  /* height: 9.5rem; */
}

.filter-lists::-webkit-scrollbar {
  width: 2px;
}

.filter-lists::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
}

/* .listing-filter {} */

.filter-icons span {
  padding: 0;
  margin: 0;
  font-size: 12px;
  font-weight: 700;
}

.filter-icons span:hover {
  background: white;
  color: black;
}

.filter-list-status {
  height: auto;
}

.filter-list-clear {
  height: auto;
}

.filter-list-engineers {
  height: 9.5rem;
}

.filter-list-location {
  height: 9.5rem;
}

.filter-list-type {
  height: 9.5rem;
  padding: 2rem 0;
}

.listing-filter2 {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.filter-lists li {
  padding: 2px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 6px;
}

.selected-filter {
  background: #fef3de;
  color: #f8ac1d;
}

.filter-lists li:hover {
  background: #fef3de;
  color: #f8ac1d;
}

.search-bar-div {
  border: 1px solid #c1c1c180;
  border-radius: 5px;
}

/* filter dropdown css ends */

/* assign ticket modal css starts */
/*----------------------------------------------------------------------------changes by aayush malviya start from here---------------------------------------------------------------------- */
.modal-wrapper,
.client-modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  width: 100%;
}

.client-modal-wrapper {
  text-transform: lowercase;
}

.modal-container,
.client-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  /* width: 100vw; */
  background: white;

  z-index: 9999;
}

.client-modal-container {
  top: 9%;
  border-radius: 15px;
  left: 10%;
  width: 80%;
}

.modal-wrapper1 {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 4;
  width: 100%;
}

.child-modal-container {
  height: 45rem;
  width: 100%;
  position: relative;
  border-radius: 8px;
}

/*-------------- Membershippopup page css--------------- */
.membershippopup-modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.381);
  /* background-color: lightgray; */
  z-index: 99999;
  width: 100%;
}

.membershippopup-modal-wrapper {
  text-transform: none;
}

.membershippopup-modal-container {
  /* top: 9%; */
  border-radius: 15px;
  left: 10%;
  width: 80%;
}

.membershippopup-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 19%;
  height: 24vh;
  background: white;
  z-index: 999999;
}

.membershippopup-modal-container {
  border-radius: 15px;
}

.membershippopup-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.membershippopup-inner h4 {
  margin-top: 2.6vw;
  text-align: center;
  color: #444444;
  font: normal normal 600 17px/26px Poppins;
}

.membershippopup-btn {
  margin-top: 2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.membershippopup-btn button {
  width: 90px;
  height: 51px;
  background: transparent;
  border: 1px solid #f8ac1d;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  font: normal normal medium 16px/25px Poppins;
  transition: all ease-in 0.2s;
}

.membershippopup-btn button:hover {
  background-color: #f8ac1d;
}

.closeIconmembershippopup {
  position: absolute;
  left: 89%;
  top: 4%;
  cursor: pointer;
  font-size: 2rem;
  color: #a2a2a2;
}

/* ----------Innermembership-modal-container ------------*/
.innermembershippopup-modal-conntainer {
  /* top: 9%; */
  border-radius: 15px;
  left: 10%;
  width: 80%;
}

.innermembershippopup-modal-conntainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 23vw;
  height: 35vh;
  background: white;
  z-index: 999999999999999;
}

.innermembershippopup-modal-conntainer {
  border-radius: 20px;
}

.innermembershippopup-modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.381);
  z-index: 999999999999999999;
  width: 100%;
}

.innermembershippopup-modal-wrapper {
  text-transform: capitalize;
}

.innerMembershipPopup-center {
  margin-left: 2.4vw;
  margin-top: 0.3vw;
}

.innerMembershipPopup-inner input {
  width: 21.5rem;
  margin-top: 1.3rem;
  border: none;
  outline: none;
  border-bottom: 1px solid #707070;
  padding: 8px 0px;
}

.innerMembershipPopup-inner input::placeholder {
  font: 14px/23px Poppins;
  color: #444444;
}

.innerMembershipPopup-btn {
  display: flex;
  width: 18vw;
  margin-top: 2vw;
  /* background-color: lightcyan; */
  align-items: center;
  padding: 0px 1.6rem;
  justify-content: space-between;
}

.innerMembershipPopup-btn button {
  width: 128px;
  height: 49px;
  border: 1px solid #f8ac1d;
  border-radius: 5px;
  background: transparent;
  opacity: 1;
}

#submit-innermembership {
  background-color: #f8ac1d;
}

.file-upload {
  border-bottom: 1px solid #707070;
  /* opacity:0.4; */
  margin-top: 1.6vw;
  cursor: pointer;
  width: 18vw;
  position: relative;
  font-size: 0.9rem;
  font-family: poppins;
}

.invoise {
  color: #444444;
}

.file-upload input {
  border: none;
}

.file-upload-icon {
  position: absolute;
  right: 0;
  bottom: 4px;
  cursor: pointer;
}

.fileInput {
  display: none;
}

/* ------------EngeeniersAttendanceCard page CSS by Raj-------------- */

.engeenierattendance-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1006px;
  height: 683px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.engeeniersattendancefullImage {
  width: 1007px;
  height: 688px;
  top: -4.7%;
  left: -3.8%;
  position: absolute;
  z-index: 999;
  border-radius: 20px;
  overflow: hidden;
}

.engeeniersattendancefullImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.engeeniersattendancecard-main {
  width: 93%;
  height: 92%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.engeeniersattendancecard-left {
  /* width: 15%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.engeeniersattendancecard-right {
  width: 83%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.closeIconengeeniersattendancecard {
  position: absolute;
  left: 97%;
  top: 1.7%;
  cursor: pointer;
  font-size: 2rem;
  color: #202020;
  z-index: 999999999999999999;
}

.Arrow-engeeniersattendancecard {
  position: absolute;
  left: 0%;
  display: none;
  top: 1.5%;
  cursor: pointer;
  font-size: 2rem;
  color: #202020;
  z-index: 999999999999999999;
}

.Arrow-engeeniersattendancecardleft {
  position: absolute;
  right: 0%;
  /* display: none; */
  top: 45%;
  cursor: pointer;
  font-size: 2rem;
  color: #202020;
  z-index: 999999999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 50%;
  transition: all 0.5s ease;
}

.Arrow-engeeniersattendancecardRight {
  width: fit-content;
  left: 0%;
}

.Arrow-engeeniersattendancecardleft:hover {
  background: #f8ac1d;
  color: white;
}

.engeeniersattendancecard-right-inner {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}

.engeeniersattendancecard-right-inner button {
  width: 121px;
  height: 42px;
  background: #f8ac1dad 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  color: #000000;
  font-size: 14px;
  font-family: "Poppins";
}

.engeeniersattendancecard-left button {
  width: 121px;
  height: 42px;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  background: #f8ac1dad 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  font-family: "Poppins";
  margin-top: 10px;
}

.engeeniersattendancecard-box2 {
  width: 121px;
  height: 118px;
  border: 1px solid #c10000;
  border-radius: 20px;
  opacity: 1;
  overflow: hidden;
}

.engeeniersattendancecard-box2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all ease-in 0.2s;
}

.engeeniersattendancecard-box2:hover img {
  scale: 1.2;
}

.engeeniersattendancecard-box {
  width: 121px;
  height: 118px;
  border: 1px solid #389300;
  border-radius: 20px;
  opacity: 1;
  overflow: hidden;
}

.engeeniersattendancecard-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all ease-in 0.2s;
}

.engeeniersattendancecard-box:hover img {
  scale: 1.2;
}

.engeeniersattendancecard-right-inner {
  width: 100%;
  height: 6.6%;
}

.engeeniersattendancecard-right-inner2 {
  background-position: center;
  background-size: cover;
  width: 100%;
  /* height: 91%; */
  overflow: hidden;
  /* width: 765px; */
  height: 566px;
  border-radius: 10px;
}

.engeeniersattendancecard-description-section {
  display: grid;
  grid-template-columns: repeat(1, calc(100%));
  justify-content: center;
  gap: 1rem;
  position: relative;
}

.engeeniersattendancecard-more-descriptive {
  position: relative;
  height: 568px;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #c1c1c180;
}

/*------------------- EditEngineerDeatils page css--------------------- */

.engineer-modal-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.381);
  /* background-color: lightgray; */
  z-index: 9999;
  width: 100%;
  text-transform: capitalize;
}

.engineer-modal-container {
  /* top: 9%; */
  border-radius: 15px;
  left: 10%;
  width: 80%;
}

.engineer-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 23%;
  height: 45vh;
  background: white;
  z-index: 9999;
}

.engineer-modal-container {
  border-radius: 15px;
}

.bigg {
  width: 100%;
  position: relative;
  height: 46vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ttt {
  display: flex;
  align-items: center;
  width: 18vw;
  margin-top: -1rem;
  /* background-color: lightgray; */
  justify-content: center;
}

.uuu {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 1.5rem;
  gap: 10px;
}

.edit-engineer-modal {
  position: absolute;
  top: 5%;
  cursor: pointer;
  left: 88%;
  font-size: 1.6vw;
}

.addform-modal-wrapper {
  position: fixed;
  z-index: 99999999;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.381);
  /* background-color:lightpink; */
  width: 100%;
}

.addform-modal-wrapper {
  text-transform: capitalize;
}

.addform-modal-container {
  /* top: 9%; */
  border-radius: 7px;
  /* left: 10%; */
  /* width: 95%; */
}

.addform-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* wid width: 85%;th: 25%; */
  /* height: 46vh; */
  width: 85vw;
  height: 80vh;
  background: white;
  z-index: 99999999;
}

/* --------------------------------------------- -----------------------------*/

.client-child-modal-container {
  text-transform: lowercase;
  padding: 1.5rem;
  height: 100%;
}

.sub-child-modal-container {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.req-client-section {
  width: 100%;
  height: 40%;
  position: relative;
  display: flex;
  padding-top: 1rem;
}

.req-elevator-section {
  width: 100%;
  height: 60%;
  position: relative;
  display: flex;
}

.req-elevator-section .calendar {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 37%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: none;
  top: 22% !important;
  left: 11.7% !important;
  height: 70%;
}

.req-elevator-section-left {
  width: 25%;
  height: 100%;
  position: relative;
}

.req-elevator-details {
  width: 100%;
  height: 35%;
  position: relative;
  padding-left: 4.1rem;
}

.sub-req-elevator-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
}

.req-elevator-section-right {
  width: 75%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 8.2rem;
}

.req-el-left {
  width: 50%;
  height: 100%;
  position: relative;
  background: #000;
}

.req-el-right {
  width: 50%;
  height: 100%;
  position: relative;
  background: orange;
}

.req-elevator-row {
  width: 100%;
  display: flex;
}

.req-elevator-col1 {
  width: 25%;
}

.req-eng-details {
  width: 100%;
  height: 25%;
  position: relative;
  padding-left: 4.1rem;
}

.req-eng-details h1 {
  font-size: 0.9rem;
  font-family: "Poppins";
  font-weight: 500;
}

.req-elevator-col1 p {
  color: #272626;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: "Poppins";
}

.req-elevator-col2 p {
  color: #1d1d1d;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: "Poppins";
}

.modalLocation {
  font-size: 0.7rem !important;
  color: #343434;
  /* padding-left: 1rem; */
  font-weight: 500;
}

.req-elevator-col2 {
  width: 75%;
}

.req-elevator-details h1 {
  font-size: 0.9rem;
  font-family: "Poppins";
  font-weight: 500;
  text-align: start;
}

.req-photo-upload-section {
  width: 25%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: relative;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.req-photo-upload-section img {
  height: 13rem;
  width: 13rem;
  border-radius: 3px;
}

.req-client-information-section {
  width: 45%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 1rem;
  justify-content: center;
}

.modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 83%;
  background: white;
  z-index: 9999;
}

.notification-modal {
  top: 10%;
  left: 10%;
  border-radius: 6px;
}

.cross-icon {
  right: 17px;
  position: absolute;
  top: 12px;
  font-size: 30px;
  height: 40px;
  width: 40px;
  color: black;
  z-index: 9;
}

.modal-container.active {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.1) 1px 0px 3px 0px;
}

.upload-photo-secton {
  width: 20%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-photo-secton img {
  height: 200px;
  width: 200px;
  border-radius: 2px;
}

.client-information-section {
  width: 0%;
}

.req-membership-information-section {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 86%;
  align-items: flex-end;
  position: relative;
}

.membership-form-container {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: flex-end;
}

.membership-form-row {
  width: 100%;
  height: 27px;
  display: flex;
  gap: 1rem;
}

.membership-form-container p {
  font-size: 0.8rem;
  font-family: "Poppins";
  color: #444444;
  font-weight: 500;
}

.membership-form-col1 {
  width: 40%;
  height: 100%;
}

.membership-form-col2 {
  width: 70%;
  height: 100%;
  position: relative;
}

.membership-form-col22 {
  width: 70%;
  height: 100%;
  position: relative;
}

.client-section {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 0px;
  position: relative;
}

.client-form,
.client-form2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 90%;
  position: relative;
  height: 80%;
  margin-bottom: 1.5rem;
  gap: 1rem;
}

.col75 input[type="text"] {
  /* width: 80%; */
  /* padding: 0.5rem; */
  width: 220px;
  padding: 0.6rem;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  resize: vertical;
  font-size: 0.8rem;
  font-weight: 400;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0 2px 1px;
  border: none;
  font-family: "Poppins";
  outline-color: #f8ac1d;
}

.col75 input {
  /* width: 405px; */
  width: 92.5%;
  /* padding: 8px; */
  padding: 0.6rem;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  resize: vertical;
  font-size: 0.8rem;
  font-weight: 400;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0 2px 1px;
  border: none;
  font-family: "Poppins";
  outline-color: #f8ac1d;
}

.col75 textarea {
  width: 80%;
  height: 10px;
  padding: 8px;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  resize: vertical;
  font-size: 15px;
  font-weight: 400;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0 2px 1px;
  border: none;
  font-family: "Poppins";
  /* fontSize: ".8rem", // Adjust the font size here
  fontFamily:'Poppins',
  opacity:'0.6', */
  font-size: 0.9rem;
  font-family: "Poppins";
  outline-color: #f8ac1d;
}

.css-mohuvp-dummyInput-DummyInput {
  height: 1px;
  position: absolute;
  margin-top: -3rem;

  /* ---------------------------------------------------------------------------------------------------set height  0 for solve conflict of multipl dropdown by aayush -------------------------------------------------------------------------------------------- */
}

.col75 textarea::placeholder {
  font-size: 0.8rem;
  font-family: "Poppins";
  opacity: 0.6;
}

.col75 input[type="text"]:focus {
  box-shadow: 0px 0px 5px #f8ac1d80;
  /* outline: none; */
}

.col75 input[type="text"]:hover {
  box-shadow: 0px 0px 5px #f8ac1d80;
}

.col75 input:hover {
  box-shadow: 0px 0px 5px #f8ac1d80;
}

.col75 textarea:hover {
  /* border: 1px solid #f8ac1d;
  border: none;
  outline: none; */
  box-shadow: 0px 0px 5px #f8ac1d80;
}

.col75 textarea[type="text"]:focus {
  border: 1px solid #f8ac1d;
}

.req-client-information-section
  .req-client-form
  .row
  .col75
  select[type="text"]:focus {
  border: 1px solid #f8ac1d;
  outline: none;
}

.req-client-form {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.6rem;
}

.col75 p {
  color: #1d1d1d;
  font-size: 0.8rem;
  font-weight: 400;
  font-family: "Poppins";
}

.col25 label {
  padding: 0px 0px 0px 0;
  display: inline-block;
  color: #272626;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: "Poppins";
}

.col25 {
  float: left;
  width: 20%;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.col75 {
  float: left;
  width: 80%;
}

.req-elevator-section-right .col75 {
  width: 92%;
}

.req-client-information-section .req-client-form .row {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 0px;
}

.req-client-information-section .req-client-form .row .col75 select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid red;
  resize: vertical;
  font-size: 14px;
  font-weight: 400;
}

.req-membership-information-section .req-client-form2 .row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.req-membership-information-section .client-form2 .row .col75 input {
  border: none;
}

.elevator-section {
  width: 100%;
}

.elevator-engg-detail-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 25%;
}

.elevator-detail-row {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0px;
}

.col-elevator25 label {
  padding: 0px 0px 0px 0;
  display: inline-block;
  color: #343434;
  font-size: 0.9rem;
  font-weight: 500;
}

.col-elevator25 {
  float: left;
  width: 50%;
  margin-top: 0px;
}

.grid-form-container {
  background-color: white;
  width: 46%;
  height: 70%;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  justify-content: space-around;
}

.grid-form-container2 {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-top: 3.3rem;
  align-items: center;
  gap: 1.7rem;

  /* height: 100%;   */
  /* change the height media query */
}

.col75 input::placeholder {
  opacity: 0.6;
  font-size: 0.8rem;
  font-family: "Poppins";
  text-transform: capitalize;
}

.grid-form-container2 .buttons {
  display: flex;
  gap: 16px;
  width: 100%;
}

.engg-form {
  display: flex;
}

.elevator-section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 0px;
  margin-top: 14px;
}

.Assign-engg-detail-section {
  width: 60%;
  border-radius: 6px;
  padding: 20px 0px;
  /* background: #fff9ef; */
  position: relative;
}

/* .grid-form-container .sm-box .col75 select {
  width: 50%;
  padding: 10px 10px;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
} */

/* .grid-form-container{ */
/* background: red;
  width: 50%;
} */

.col-elevator75 input {
  border: none;
  font-family: poppins;
}

.col-elevator75 input[type="text"]:focus {
  outline: none;
}

.sub-engg-detail-section {
  font-size: 16px;
  font-weight: 700;
  gap: 2px;
  display: flex;
  flex-direction: column;
}

.engg-photo-section {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 0.5rem;
  margin-top: 10px;
}

.assign-button {
  padding: 12px 50px;
  border-radius: 5px;
  border: 1px solid #f8ac1d;
  background: #f8ac1d;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 5rem;
  width: 100%;
}

/* assign ticket modal css ends */

.status-section-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fef3de;
  padding: 10px 0px;
  border-radius: 5px;
  width: 100px;
  color: #f8ac1d;
  cursor: pointer;
}

.dropdowns {
  position: relative;
  width: 327px;
  background: #ffffff;
  height: 34px;
}

.custom-class {
  width: 248px;
  margin-bottom: 3px;
}

.dropdown__text:after {
  content: "";
  transition: all 0.3s;
  border: solid #ccc;
  border-width: 0 1px 1px 0;
  float: right;
  margin-top: 8px;
  margin-right: 6px;
  padding: 5px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.dropdowns.active .dropdown__text:after {
  margin-top: 1px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown__items {
  position: relative;
  visibility: hidden;
  opacity: 0;
  max-height: 0px;
  transition: max-height 0.6s ease;
}

.dropdowns.active .dropdown__items {
  visibility: visible;
  height: auto;
  max-height: 290px;
  opacity: 1;
  background: white;
  z-index: 1;
}

.dropdown__item {
  cursor: pointer;
  padding: 8px 9px;
  font-size: 15px;
  color: black;
}

.dropdown__item:hover {
  color: #f8ac1d;
  background: #fef3de;
}

/* ticket section status button propety changes dynamically start*/
.assignColor {
  background: #fef3de;
  color: #f8ac1d;
}

.assignNameColor {
  background: #ecffe0;
  color: #389300;
}

.assignResolved {
  background: #ffffff;
  color: #343434;
}

.cancelRequest {
  background: #f88379;
  color: #cc0000;
}

/* ticket section status button propety changes dynamically ends*/

/* dynamic ticket modal property starts styling */
.col-dynamic {
  border: none !important;
  outline: none !important;
}

.col-dynamic input[type="text"]:focus {
  outline: none !important;
  border: none !important;
}

.shadow-dynamic {
  /* border: none!important; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0 2px 1px;
  outline: none;
  border: none;
}

/* dynamic ticket modal property starts ending */

.css-13cymwt-control {
  cursor: pointer !important;
  transition: all 0.2s !important;
  background-color: #ffffff !important;
  border-color: #c1c1c1 !important;
  border-radius: 7px !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0px 8px !important;
  font-size: 14px !important;
}

.css-qbdosj-Input {
  margin: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

.css-1nmdiq5-menu {
  font-size: 14px !important;
  color: #191919 !important;
}

.css-1nmdiq5-menu:hover {
  font-size: 14px !important;
}

/*----------------------------------------------------------------------------changes by aayush malviya end --------------------------------------------------------------------- */

/*------------------------------------------------------ dashboard css ends ------------------------------------------------------------------------*/

/*------------------------------------------------------ Service Request Page css starts ------------------------------------------------------------------------*/

.Request-Container {
  width: 100%;
  margin-top: 6%;
}

.calender-section {
  width: 60%;
  /* height: 37.5rem; */
}

.dynamic-background {
  background: rgba(0, 0, 0, 0.2);
}

/* react calender css over ride starts */
.react-calendar {
  max-width: 100% !important;
  background: white;
  border: none !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 2.2em !important;
  width: 100% !important;
  box-shadow: 0px 0px 3px #00000029 !important;
  border-radius: 7px !important;
  padding-bottom: 2rem;
  height: 100%;
}

.react-calendar__month-view__weekNumbers {
  display: none !important;
}

element.style {
  display: flex;
  align-items: flex-end;
  padding: 30px;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
  padding: 5px !important;
}

.react-calendar__tile {
  padding: 0.5rem 0px !important;
  text-align: center !important;
  font: inherit !important;
  font-size: 1rem !important;
  margin-top: 20px;
}

.react-calendar__tile--active {
  background: #ffffff !important;
}

.react-calendar__tile--now {
  background: #ffffff !important;
}

.react-calendar__month-view__days .react-calendar__tile--active abbr {
  background: #f8ac1d !important;
  padding: 3px 0px !important;
  border-radius: 50%;
  align-items: center;
  color: #1d1d1d !important;
  display: inline-block !important;
  width: 50px !important;
  height: 50px !important;
  text-align: center !important;
}

.react-calendar__month-view__days .react-calendar__tile--now abbr {
  background: #fef3de !important;
  padding: 3px 0px !important;
  border-radius: 50%;
  color: #f8ac1d !important;
  display: inline-block !important;
  width: 50px !important;
  height: 50px !important;
  text-align: center !important;
}

.react-calendar__month-view__days .react-calendar__tile:enabled:hover abbr {
  background: #f8ac1d !important;
  border-radius: 50%;
  color: #1d1d1d !important;
  display: inline-block !important;
  width: 50px !important;
  height: 50px !important;
  text-align: center !important;
}

.react-calendar__month-view__days .react-calendar__tile abbr {
  display: inline-block !important;
  width: 50px !important;
  height: 50px !important;
}

.react-calendar__year-view__months__month abbr:hover {
  padding: 20px;
  background: #fef3de;
  width: 40%;
  display: inline-block;
  border-radius: 8px;
  color: #f8ac1d;
}

.react-calendar__year-view__months__month abbr {
  padding: 20px;
  width: 40%;
  display: inline-block;
}

.react-calendar__tile:enabled:hover {
  background-color: #ffffff !important;
}

.react-calendar__tile:enabled:focus {
  background-color: #ffffff !important;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none !important;
}

.react-calendar__navigation {
  height: 44px;
  margin-bottom: 0em !important;
  margin-top: 1rem !important;
  border-bottom: 1px solid #00000029 !important;
  align-items: center !important;
}

.react-calendar__navigation__arrow {
  font-size: 2rem !important;
  margin-bottom: 1rem !important;
  align-items: center !important;
  justify-content: center !important;
  display: flex;
}

.react-calendar__navigation__label__labelText {
  font-size: 1.3rem;
  color: #343434 !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #000000 !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #ffffff !important;
}

.event-detail-section {
  width: 37%;
}

.calender-events-section {
  display: flex;
  justify-content: space-between;
}

.swap-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 18px;
  color: #343434;
  cursor: pointer;
}

.date-swap-icon {
  background: #ffffff;
  padding: 18px 0px;
  border-radius: 8px;
  box-shadow: 0px 0px 3px #00000029;
}

.request-task-detail {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 3px #00000029;
  margin-top: 1rem;
}

.service-assign {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 6px;
  margin-top: 10px;
}

.jon-type {
  display: flex;
  gap: 1rem;
}

.profile-pic {
  border-radius: 50%;
}

.dots3 {
  cursor: pointer;
}

.name-3dots {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.pic-3dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-time span {
  font-size: 13px;
  font-weight: 500;
  color: #f8ac1d;
}

.date-time p {
  font-size: 14px;
  font-weight: 600;
  color: #343434;
}

.date-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #c1c1c1;
  padding: 0px 14px;
  margin-right: 0px;
}

.name-jon {
  padding: 0px 0px;
  margin-left: -25px;
}

.name-jon p {
  font-size: 16px;
  font-weight: 500;
  color: #343434;
}

.jon-type p {
  font-size: 12px;
  font-weight: 400;
  color: #343434;
}

.parent-div {
  z-index: 2;
  position: relative;
}

.parent-div-task-request {
  height: 30rem;
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin-top: 10px;
  padding: 4px;
}

.parent-div-task-request:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d;
  /* Change the color to your desired hover color */
}

/* set scroll behaviour  */
.parent-div-task-request::-webkit-scrollbar {
  width: 2px;
  /* width of the entire scrollbar */
}

.parent-div-task-request::-webkit-scrollbar-track {
  background: 0;
  /* color of the tracking area */
  margin-top: 20px;
  margin-bottom: 15px;
}

.parent-div-task-request::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 1px solid #ffffff;
  /* creates padding around scroll thumb */
  transition: background-color 0.3s ease;
  /* Adjust the transition duration as needed */
}

/* react calender css over ride ends */

.image-border-collapse {
  right: -18%;
  border: 3px solid #fff6f6;
  border-radius: 50%;
  display: flex;
  position: relative;
  margin-right: 8px;
}

.image-border-collapse2 {
  border: 3px solid #fff6f6;
  border-radius: 50%;
  display: flex;
  position: relative;
}

.service-request-section-button {
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  background: #ffffff;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  margin-left: 4px;
}

.service-request-section-button:hover {
  background: #fef3de;
  color: #f8ac1d;
  transition: all 0.1s;
}

.animation-all {
  animation-duration: 1s;
  animation-timing-function: ease-in 0.2s all;
}

@keyframes slideRightToLeft {
  from {
    margin-left: 100%;
    width: 300%;
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    margin-left: 0%;
    width: 100%;
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideLeftToRight {
  from {
    margin-left: -100%;
    width: -100%;
    opacity: 0;
    transform: translateX(-1100px);
    /* Move element to the left */
  }

  to {
    margin-left: 0%;
    width: 100%;
    opacity: 1;
    transform: translateX(0);
    /* Reset horizontal position */
  }
}

@keyframes slideToTop {
  from {
    margin-top: -100%;

    width: 100%;
    opacity: 0;
    transform: translateY(-20px);
    /* Move element to the left */
  }

  to {
    margin-top: 0%;
    width: 100%;
    opacity: 1;
    transform: translateY(0);
    /* Reset horizontal position */
  }
}

/*------------------------------------------------------ Service Request Page css ends ------------------------------------------------------------------------*/

/* --------------------------------------------------message box css starts--------------------------------------------------------------------------------------- */
.engg-message {
  transition: all 0.5s, background 2s 0.5s linear;
  margin-top: 0.3rem;
}

.message-parent-div {
  box-shadow: rgba(100, 100, 111, 0.4) 0px 10px 40px 0px;
  border-radius: 10px;
  width: 280px;
  height: 24.5rem;
  position: absolute;
  z-index: 9999999;
  /* background: #ffffff; */
  margin-top: -3px;
  margin-left: -15.1rem;
  background-color: #fffbf4;
}

.messagebox-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 9px 0px 0px;
  border-radius: 5px;

  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 0 14px 1px; */
}

.pro-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 500;
  gap: 6px;
  color: #343434;
}

.featured-icon {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1rem;
  color: #f8ac1d;
  width: 100px;
  height: 30px;

  cursor: pointer;
}

.sender-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.reciver-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reciver-message,
.sender-message {
  max-width: 70%;
  background: #c1c1c180;
  color: #343434;
  border-radius: 5px;
  padding: 10px 10px;
  font-size: 0.9rem;
  text-wrap: wrap;
  line-height: 1.1;
  text-align: center;
}

.sender-message {
  color: #f8ac1d;
  background: #fef3de;
  margin-left: 8.4rem;
  margin-top: 0.2rem;
  word-wrap: break-word;
}

.reciver-side {
  padding: 10px 6px;
  margin-top: 0.5rem;
  margin-right: 0.3rem;
  word-wrap: break-word;
}

.reciver-message {
  color: #000;
  background: #aca6a6;
  /* margin-left: 8.4rem; */
  margin-top: 0.5rem;
  margin-left: 0.3rem;
  word-wrap: break-word;
}

.send-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.send-data,
.send-attachments {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  font-size: 1rem;
}

.send-attachments {
  gap: 10px;
  /* margin-top: 3rem; */
  color: #f8ac1d;
}

.message-input {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0 14px 1px;
  bottom: 0px;
  position: absolute;
  width: 100%;
  border-radius: 10px;
}

.input-field-styling input {
  outline: none;
  border: none;
}

.send-text {
  padding: 6px 3px;
}

.message-body {
  height: 21rem;
  overflow-x: scroll;
}

.loader {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* set scroll behaviour  */
.message-body:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d;
  /* Change the color to your desired hover color */
}

/* set scroll behaviour  */
.message-body::-webkit-scrollbar {
  width: 2px;
  /* width of the entire scrollbar */
}

.message-body::-webkit-scrollbar-track {
  background: #ffffff;
  /* color of the tracking area */
  margin-top: 5px;
  margin-bottom: 5px;
}

.message-body::-webkit-scrollbar-thumb {
  background-color: 0;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 1px solid #ffffff;
  /* creates padding around scroll thumb */
}

/* set body scroll property ends */

/* --------------------------------------------------message box css ends--------------------------------------------------------------------------------------- */

/* -----------This is Collect cash model css by Raj --------- */

.parent-collect-div {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  width: 250px;
  position: absolute;
  background: #ffffff;
  margin-top: 3.2rem;
  margin-left: -13rem;

  /* height: auto; */
  height: 20vh;
  max-height: 27rem;
}

.child-collect-div {
  padding: 12px 10px;
}

.collect-body input {
  margin-top: 1vw;
  margin-left: 1rem;
  width: 12rem;
  font-size: 0.9rem;
  outline: none;

  border: none;
  padding: 7px 0px;
  border-bottom: 1px solid #707070;
}

.collect-body button {
  margin-left: 1rem;
  margin-top: 2.2vh;
  width: 12rem;
  font-size: 0.9rem;
  border-radius: 6px;
  color: #444444;
  background-color: transparent;
  border: 1px solid #f8ac1d;
  height: 2.4rem;
  cursor: pointer;
}

.collect-body button:hover {
  background-color: #f8ac1d;
}

.closeIconCollectCash {
  position: absolute;
  left: 87%;
  top: 4%;
  cursor: pointer;
  color: #444444;
}

/* --------------------------------------------------notifiction box css starts--------------------------------------------------------------------------------- */
.parent-Notification-div {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  width: 350px;
  position: absolute;
  background: #ffffff;
  margin-top: 3rem;
  margin-left: -19rem;
  height: auto;
  max-height: 27rem;
}

.activeNotification {
  color: #f8ac1d;
}

.notification-bg-line {
  height: 2px;
  /* width: 20%; */
  background-color: #f8ac1d;
  position: absolute;
  transition: 0.5s ease;
  z-index: 9999;
}

.child-notification-div {
  padding: 12px 10px;
}

.notification-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 600;
  gap: 3px;
}

.notification-heading p:last-child {
  background: #fef3de;
  color: #f8ac1d;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  text-align: center;
}

.notification-navigators {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  align-items: center;
  font-weight: 500;
  /* border-bottom: 1px solid #c1c1c1; */
  padding: 6px 12px;
  margin-top: 0.9rem;
}

.notification-buttons {
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}

.notification-buttons p:last-child {
  background: #fef3de;
  color: #f8ac1d;
  padding: 1px 6px;
  border-radius: 4px;
  font-size: 0.7rem;
  text-align: center;
}

.notification-data {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 10px 0px;
  border-radius: 10px;
}

.notification-data:hover {
  background: #f7fafd;
  cursor: pointer;
  transition: 0.1s;
  transform: scale(1.0001);
}

.user-notification-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  text-wrap: wrap;
  gap: 10px;
}

.notification-buttons-operations {
  display: flex;
  font-size: 0.8rem;
  font-weight: 500;
  gap: 8px;
}

.noti-dec-time p:first-child {
  font-size: 0.8rem;
  font-weight: 500;
}

.noti-dec-time p:nth-child(2) {
  font-size: 0.7rem;
  font-weight: 500;
}

.notification-buttons-operations p {
  background: #ffff;
  color: #343434;
  padding: 2px 6px;
  border-radius: 5px;
  font-size: 0.7rem;
  cursor: pointer;
  border: 1px solid #f8ac1d;
  font-weight: 500;
}

.notification-buttons-operations p:nth-child(2) {
  font-size: 10px;
}

.notification-buttons-operations p:hover {
  background: #f8ac1d;
}

.notification-archives {
  height: 20.9rem;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.notification-archives:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d;
  /* Change the color to your desired hover color */
}

/* set scroll behaviour  */
.notification-archives::-webkit-scrollbar {
  width: 2px;
  /* width of the entire scrollbar */
}

.notification-archives::-webkit-scrollbar-track {
  background: 0;
  /* color of the tracking area */
}

.notification-archives::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 1px solid #ffffff;
  /* creates padding around scroll thumb */
  transition: background-color 0.3s ease;
  /* Adjust the transition duration as needed */
}

/* --------------------------------------------------notifiction box css ends----------------------------------------------------------------------------------- */

/*---------------  mesage box input css starts  ------------------------------ */

.slick-prev,
.slick-next {
  display: none !important;
}

.message-box-crouser {
  margin: -2px 0px;
}

.message-box-crouser {
  font-size: 1.2rem;
}

.message-text {
  position: absolute;
  bottom: 15px;
  max-height: 10rem;
  width: 70%;
}

.text-area-message-whatsapp {
  width: 97%;
  outline: none;
  border: none;
  border-radius: 10px;
  position: relative;
  padding: 4px 10px;
  top: 4px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #444444;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 4px 1px;
   */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* .text-area-message-whatsapp:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d; 
} */
/* set scroll behaviour  */
.text-area-message-whatsapp::-webkit-scrollbar {
  width: 2px;
  /* width of the entire scrollbar */
}

.text-area-message-whatsapp::-webkit-scrollbar-track {
  background: 0;
  /* color of the tracking area */
  margin-top: 7px;
  margin-bottom: 5px;
}

.text-area-message-whatsapp::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 1px solid #f8ac1d;
  /* creates padding around scroll thumb */
  transition: background-color 0.3s ease;
  /* Adjust the transition duration as needed */
}

/* --------------------------------------------------notifiction box css ends----------------------------------------------------------------------------------- */

.user-attachment4 {
  position: absolute;
  right: 8px;
  display: flex;
  gap: 10px;
  top: 5px;
  font-size: 1rem;
  /* padding: 0px 0px; */
  color: #f8ac1d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18%;
}

.agdam {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  position: relative;
  width: 94%;
  margin-top: -15px;
  /* border-top: 1px solid #C1C1C180; */
  /* z-index: 0; */
}

.send-messsage {
  background: #fef3de;
  border-radius: 50%;
  text-align: center;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .sendmessage-icon {
  margin: 0px 0px -2px 3px;
}

.sendvoice-icon {
  margin: 0px 0px -2px 3px;
} */

/*---------------  mesage box input css ends  ------------------------------ */
/* -------------------------KANBAN-SECTION STARTS {BY-AMIT}------------------------------- */

.kanban-section {
  height: 83vh;
  display: flex;
  animation: changeKanban 1.5s ease forwards;
}

@keyframes changeKanban {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.Unresolved-section,
.Assigned-section,
.Completed-section {
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin-left: 1.5%;
  margin-right: 1.5%;
  margin-top: 1%;
  margin-bottom: 5%;
}

.Unresolved {
  color: #c10000;
  font-size: 1.125rem;
  text-align: center;
  animation: changeText 0.7s linear forwards;
}

@keyframes changeText {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

.Assigned {
  color: #f8ac1d;
  font-size: 1.125rem;
  text-align: center;
}

.Completed {
  color: #389300;
  font-size: 1.125rem;
  text-align: center;
}

.unresolved-data-item {
  background: #fffafa 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #c100004d;
  border: 1px solid #c100004d;
  border-radius: 10px;
  opacity: 1;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 11%;
  padding-right: 11%;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 2%;
}

.data-section {
  display: flex;
  justify-content: space-between;
}

.assigned-data-item {
  background: #fffdf8 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #f8ac1d4d;
  border: 1px solid #f8ac1d4d;
  border-radius: 10px;
  opacity: 1;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 11%;
  padding-right: 11%;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 2%;
}

.completed-data-item {
  background: #f9fff5 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #3893004d;
  border: 1px solid #3893004d;
  border-radius: 10px;
  opacity: 1;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 11%;
  padding-right: 11%;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 2%;
}

.data-label {
  font-size: 0.875rem;
  font: bold;
  font-weight: 500;
}

.data-value {
  font-size: 0.875rem;
}

.kanban-Unresolved-section-scroll,
.kanban-Assigned-section-scroll,
.kanban-Completed-section-scroll {
  margin-top: 1rem;
  gap: 1rem;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/* set scroll behaviour  */

.kanban-Unresolved-section-scroll:hover::-webkit-scrollbar-thumb {
  border: 1px solid #c100004d;
  /* Change the color to your desired hover color */
}

.kanban-Assigned-section-scroll:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d4d;
  /* Change the color to your desired hover color */
}

.kanban-Completed-section-scroll:hover::-webkit-scrollbar-thumb {
  border: 1px solid #3893004d;
  /* Change the color to your desired hover color */
}

.kanban-Unresolved-section-scroll::-webkit-scrollbar,
.kanban-Assigned-section-scroll::-webkit-scrollbar,
.kanban-Completed-section-scroll::-webkit-scrollbar {
  width: 2px;
  /* width of the entire scrollbar */
}

.kanban-Unresolved-section-scroll::-webkit-scrollbar-track,
.kanban-Assigned-section-scroll::-webkit-scrollbar-track,
.kanban-Completed-section-scroll::-webkit-scrollbar-track {
  /* width of the entire scrollbar */
  margin-top: 23px;
  margin-bottom: 23px;
}

.unresolved-data-item:hover {
  box-shadow: 0px 0px 10px #c100004d;
}

.assigned-data-item:hover {
  box-shadow: 0px 0px 10px #f8ac1d4d;
}

.completed-data-item:hover {
  box-shadow: 0px 0px 10px #3893004d;
}

/* ------------------------KANBAN-SECTION ENDS------------------------------------ */

/* -------------------------------- custom calender css starts ------------------------------------------ */

.calendar-box {
  text-align: center;
  width: 100%;
}

.ui-datepicker-header,
.calendar-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.calendar {
  background-color: white;
  border-radius: 8px;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 37%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: none;
  top: 26% !important;
  left: 0% !important;
  height: 100%;
}

.header {
  background-color: #ffffff;
  color: #343434;
  display: flex;
  justify-content: space-around;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #c1c1c180;
}

#prevBtn,
#nextBtn {
  background: none;
  border: none;
  color: #343434;
  cursor: pointer;
  font-size: 12px;
}

#monthYear {
  font-size: 16px;
  /* date font */
  font-weight: 500;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 12px;
  padding: 10px 16px;
}

.day {
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
  font-size: 0.9rem;
  color: black;
}

.day:hover {
  background: #f8ac1d;
  border-radius: 50%;
}

.ui-datepicker-prev {
  order: 1;
}

.day.current {
  /* background-color: #FEF3DE; */
  color: #f8ac1d;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.day.current:hover {
  background-color: white;
}

.ui-datepicker-next {
  order: 3;
}

.day.selected {
  background-color: #fef3de;
  color: #f8ac1d;
  border-radius: 50%;
  height: 30px;
  width: 30px;
}

.ui-datepicker-title {
  order: 2;
}

#dateInput {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #c1c1c1;
  border-radius: 4px;
  resize: vertical;
  font-size: 0.8rem;
  font-weight: 400;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0 2px 1px;
  border: none;
  font-family: "Poppins";
  outline-color: #f8ac1d;
}

.ui-datepicker {
  position: absolute;
}

#dateInput:hover {
  box-shadow: 0px 0px 5px #f8ac1d80;
  border: none;
}

/* -------------------------------- custom calender css ends ------------------------------------------ */

.cover-image-skeleton {
  background: linear-gradient(90deg, #eeeeee 25%, #dedede 50%, #eeeeee 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
  border-radius: 5px;
  position: relative;
  height: 18px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.task-dot-on-complete {
  height: 8px;
  width: 8px;
  background-color: #46a70a;
  border-radius: 50%;
}

.skill-box .skill-bar-ontask {
  height: 2px;
  width: 84%;
  border-radius: 6px;
  margin-bottom: 40px;
  background: rgba(0, 0, 0, 0.1);
}

.skill-box .skill-per-ontask {
  position: relative;
  display: block;
  height: 100%;
  /* manupulate the pointer on the progress bar */
  width: 98%;
  border-radius: 6px;
  background: #c1c1c1;
  animation: progress 0.4s ease-in-out forwards;
  opacity: 0;
}

.tasks-heading-on-no-task {
  position: relative;
  top: 11px;
  margin-bottom: 1.2rem;
  font-size: 1.1rem;
}

.timeout-data {
  color: red;
}

/* ---------------------------------------------------------Report Csss aayush malviya Start------------------------------------------------------------------------------------- */

.ReportTable {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 20px;
}

.IssuesDoor {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
}

.IssueDoors {
  height: 20%;
  width: 100%;

  /* background-color: red; */
  /* background-color: red; */
}

.IssuesDoor1 {
  height: 20%;
  width: 100%;
  border: 2px solid #f8ac1dad;
  border-radius: 10px 10px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.IssuesDoor1 h5 {
  margin-bottom: 0.2rem;
  margin-left: 1rem;
  text-align: left;
  font-family: "Poppins";
  letter-spacing: 0px;
  color: #444444;
  text-transform: capitalize;
  opacity: 1;
  font-weight: 500;
  font-size: 0.9rem;
}

.RedIsue {
  background: #c10000b0 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 0px 0px;
  opacity: 1;
  height: 40%;
  width: 25%;
  display: flex;
  margin-bottom: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem;
}

.RedIsue > h5 {
  white-space: nowrap;
  font-size: 0.8rem;
  font-family: "Poppins";
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 200;
  opacity: 1;
}

.RedIsue h5 span {
  /* margin-left: 0.1rem; */
  font-weight: 600;
}

.IssuesDoor2 {
  height: 25%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
}

.IssuesDoor2L {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.IssueDoor2 h5,
.IssuesDoor2L h5 {
  margin-top: 0.6rem;
}

.ClientImg {
  height: 4.5rem;
  width: 17%;
  background-color: #fef3de;
  border-radius: 3px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  position: relative;
  justify-content: center;
}

.ClientImg img {
  height: 100%;

  width: 100%;
}

.ClientImg h5 {
  font-size: 0.5rem;
  text-align: center;
  letter-spacing: 0px;
  color: #444444;
  font-family: "Poppins";
  opacity: 1;
  font-weight: 900;
}

.ClientName {
  width: 85%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(4, 20%);
  grid-gap: 0.2rem;
}

.ClientName h5 {
  font-family: "Poppins";
  /* font-size: 0.8rem; */
  font-family: "Poppins";
  font-weight: 400;
  font-size: 0.9rem;

  letter-spacing: 0px;
  color: #444444;
  opacity: 1;
}

/* 
.ClientName h5 span{
  margin-left: .5rem;
} */

.IssuesDoor3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.3rem;
  padding: 0.8rem;
}

.IssueDoor3L h5 {
  font-family: "Poppins";
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  font-size: 0.9rem;
  letter-spacing: 0px;
  color: #444444;
  opacity: 1;
}

.IssueDoor3L h5:nth-child(2) {
  font-weight: 600;
}

.IssueDoor3R h5:nth-child(2) {
  font-weight: 600;
}

.IssueDoor3R h5 {
  font-family: "Poppins";
  font-size: 0.8rem;
  font-weight: 400;
  text-align: center;
  font-size: 0.9rem;
  letter-spacing: 0px;
  color: #444444;
  opacity: 1;
}

.IssuesDoor4 {
  text-align: left;
  letter-spacing: 0px;
  color: #444444;
  padding: 0.5rem;
}

.IssuesDoor4 h5 {
  font-size: 0.9rem;
  font-family: "Poppins";
  font-weight: 500;
  text-align: start;
}

.IssuesDoor4 h5 {
  font-size: 0.9rem;
  font-family: "Poppins";
  font-weight: 500;
  text-align: center;
}

.ReportNavigation {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CarouselButtons {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.5rem;
  position: relative;
}

.CarouselButtons p {
  font-size: 0.8rem;
  width: 100%;
}

.CarouselButtonsL {
  height: 100%;
  /* width: 20%; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.CarouselButtonsR {
  height: 100%;
  /* width: 20%; */

  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.cursor {
  cursor: pointer;
}

.McRoom {
  width: 100%;
  height: 83%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  position: relative;
  padding-top: 1rem;
  /* top: 20%; */
}

.McRoomCard {
  height: 90%;
  width: 40%;
  border: 1px solid #707070;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-card {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  /* border-radius: 10px; */
}

.single-card img {
  height: 100%;
  width: 100%;
  /* border-radius: 13px; */
}

.report-img-count {
  height: 28px;
  width: 50px;
  background: #f8ac1d;
  position: absolute;
  left: 76%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-card-overlay {
  height: 100%;
  position: absolute;
  width: 100%;
  background: #0000006b;
  border-radius: 13px;
  display: none;
  align-items: center;
  justify-content: center;
}

.single-card:hover .single-card-overlay {
  display: flex;
  transition: 0.45s ease;
}

.single-card-overlay img {
  width: 60%;
  height: 60%;
}

.report-icon-container {
  height: 50px;
  width: 50px;
  position: relative;
  background-color: #f8ac1d;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  align-items: center;
  box-shadow: 1px 2px 5px #00000029;
  justify-content: center;
  object-fit: cover;
  object-position: center;
}

.McRoomCard p {
  font-size: 0.8rem;
  white-space: nowrap;
  font-weight: 400;
  letter-spacing: 2px;
  color: #444444;
  text-align: center;
}

.last-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.McRoom h5 {
  font-size: 0.9rem;
  letter-spacing: 0px;
  color: #444444;
  opacity: 1;
  font-family: "Poppins";
  font-weight: 400;
}

.gallery {
  font-size: 5rem;
  color: #f8ac1d;
}

.CarTopShift {
  width: 100%;
  max-height: 90vh;
  overflow-y: scroll;
  box-sizing: border-box;
}

.CardShiftCards {
  width: 100%;
  position: relative;
}

.IssueNotResolved {
  display: flex;
  padding: 1rem 3rem;
  justify-content: space-between;
  align-items: center;
}

.IssueResolved h5 {
  text-align: left;
  font-family: "Poppins";
  font-size: 0.9rem;
  letter-spacing: 0px;
  font-weight: 500;
  color: #444444;
  opacity: 1;
}

.IssueResolved h6 {
  text-align: left;
  /* font: normal normal normal 12px/20px Helvetica Neue; */
  letter-spacing: 0px;
  color: #444444;
  font-weight: 300;
  font-size: 0.8rem;
  opacity: 1;
}

.IssueResolved {
  display: flex;
  padding: 1rem 3rem;
  justify-content: space-between;
  align-items: center;
}

.IssueNotResolved h6 {
  text-align: left;
  /* font: normal normal normal 12px/20px Helvetica Neue; */
  letter-spacing: 0px;
  color: #444444;
  font-weight: 300;
  font-size: 0.8rem;
  opacity: 1;
}

.IssueNotResolved h5 {
  text-align: left;
  font-family: "Poppins";
  font-size: 0.9rem;
  letter-spacing: 0px;
  font-weight: 500;
  color: #444444;
  opacity: 1;
}

.SparePartChange,
.SparepartReq {
  display: flex;
  padding: 0rem 3rem;
  /* justify-content: space-between; */
  flex-direction: column;
  align-items: center;
}

.SparePartChange h5,
.SparepartReq h5 {
  width: 100%;
  text-align: left;
  font-family: "Poppins";
  font-size: 0.8rem;
  letter-spacing: 0px;
  font-weight: 500;
  color: #444444;
  opacity: 1;
}

.SparePartChangeB,
.SparepartReqB {
  display: flex;
  width: 100%;
}

.SparePartChangeB h5,
.SparepartReqB h5 {
  text-align: left;
  font-size: 0.9rem;
  font-family: "Poppins";
  letter-spacing: 0px;
  font-weight: 400;
  color: #444444;
  opacity: 1;
}

.SparePartChangeB h5 span,
.SparepartReqB h5 span {
  text-align: left;
  font-size: 0.8rem;
  font-family: "Poppins";
  letter-spacing: 0px;
  color: #f8ac1d;
  font-weight: 500;
  opacity: 1;
}

.Amount {
  display: flex;
  justify-content: space-between;
  padding: 1rem 8rem 1rem 3rem;
  height: 17%;
}

.Amount h5 {
  text-align: left;
  /* font: normal normal medium 14px/21px Poppins; */
  letter-spacing: 0px;
  color: #444444;
  opacity: 1;
  font-weight: 450;
  font-size: 0.9rem;
}

.Amount > h5:nth-child(2) {
  text-align: left;
  /* font: normal normal medium 14px/21px Poppins; */
  font-weight: 500;
  letter-spacing: 0px;
  color: #f8ac1d;
  opacity: 1;
}

.Invoice {
  width: 100%;
  height: 100%;
  padding: 1rem 2rem;
  position: relative;
  overflow-y: auto;
}

.Invoice h5 {
  width: 100%;
  text-align: left;
  font-family: "Poppins";
  font-size: 0.9rem;
  letter-spacing: 0px;
  font-weight: 500;
  color: #444444;
  opacity: 1;
}

.InvoiceTable {
  width: 100%;
}

.InvoiceTableRow {
  display: flex;
  gap: 1rem;
}

.InvoiceTableRow h5 {
  margin-top: 0.5rem;
  text-align: left;
  letter-spacing: 0px;
  color: #444444;
  font-weight: 300;
  font-size: 0.8rem;
  opacity: 1;
}

.InvoiceTableRow h5 span {
  color: #f8ac1d;
  font-weight: 500;
}

.InvoiceFooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.InvoiceFooterL {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.InvoiceFooterR {
  display: flex;
}

.InvoiceFooterRow {
  display: flex;
}

.Yellow_Color {
  color: #f8ac1d;
  font-size: 2rem;
}

.Rating {
  height: 80%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  flex-direction: column;
}

.RatingStar {
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.RatingContainer {
  width: 100%;
  padding: 1rem 1rem;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
}

.RatingContainerRow {
  /* height: 20%; */

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.RatingContainerRowL {
  width: 70%;
  position: relative;
}

.RatingButton {
  display: flex;
  gap: 0.5rem;
  width: 25%;
  height: 25px;
}

.RatingButton button {
  padding: 0rem 0.6rem;
  text-transform: capitalize;
  background-color: #f8ac1ddb;
  border: 1px solid #f8ac1d;
  opacity: 1;
  border-radius: 3px;
  text-align: left;
  font-family: "Poppins";
  /* font: normal normal normal 10px/16px Poppins; */
  letter-spacing: 0px;
  color: #444444;
  opacity: 1;
  font-size: 0.6rem;
}

.RatingButton > button:nth-child(2) {
  padding: 0rem 0.8rem;
  border: 1px solid #f8ac1d;
  opacity: 1;
  background-color: transparent;
  text-transform: capitalize;
}

.RatingContainerRow h5 {
  position: relative;
  text-align: left;
  font-size: 0.9rem;
  font-family: "Poppins";
  font-weight: 400;
  letter-spacing: 0px;
  color: #444444;
  opacity: 1;
}

.CarouselScroll {
  height: 1%;
  width: 100%;
  margin: 0.5rem 0rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Progress1 {
  height: 100%;
  background-color: #00000029;
  width: 50%;
  position: relative;
  border-radius: 15px;
}

.Progress2 {
  height: 100%;
  background-color: #f8ac1d;
  width: 16.66%;
  margin-left: 0%;
  position: relative;
  border-radius: 15px;
}

.ComponentNames {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1rem;
}

.ComponentNames p {
  color: #444444;
  font-size: 0.7rem;
  align-items: center;
  font-family: "Poppins";
  /* margin-left: 2.5rem; */
  width: 100%;
  margin-top: 0.5rem;
  text-align: center;
  /* white-space:nowrap; */
}

.NextComponentName {
  min-width: 72px;
  font-size: 0.6rem !important;
}

.ticket-card {
  /* padding: 0 1em; */

  height: 6rem;
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: #00000029 3px 3px 5px -1px, #00000029 3px 5px 10px -1px;
  border-radius: 10px;
  opacity: 1;
  position: relative;
  padding-top: 1rem;
  padding-left: 1rem;
  position: relative;
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.ticket-card:hover {
  box-shadow: 0.5px 1px 2px #f8ac1d80;
  border: 1px solid #f8ac1d80;
}

.ticket-table {
  font-family: "Poppins";
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.ticket-sub-card-row-left {
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ticket-sub-card-row-right {
  width: 25%;
}

.ticket-sub-card-row {
  display: flex;
  gap: 1rem;
}

.ticket-sub-card-row-right h5,
.ticket-sub-card-row-left h5 {
  font-size: 0.7rem;
  color: #444444;
  text-transform: uppercase;
}

.ticket-sub-card-row-left h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ticket-sub-card-row-right h5,
.ticket-sub-card-row-left h5 {
  font-weight: 500;
}

.ticket-card-bottom {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem 0rem 0.2rem;
}

.ticket-card-bottom h5 {
  text-align: left;
  /* font: normal normal medium 14px/21px Poppins; */
  font-family: "Poppins";
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: #f8ac1d;
  opacity: 1;
  font-weight: 400;
}

.ticket-card-bottom-starting-slot {
  color: #389300 !important;
}

.ticket-card-bottom-end-slot {
  color: #c10000 !important;
}

.yellow_color {
  color: #f8ac1d;
}

.service-card {
  /* padding: 0 1em; */

  height: 6rem;
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: #00000029 3px 3px 5px -1px, #00000029 3px 5px 10px -1px;
  border-radius: 10px;
  position: relative;
  padding: 1rem;
  padding-left: 24px;
  position: relative;
  margin-bottom: 1rem;
}

.service-card-selected {
  box-shadow: 0.5px 1px 2px #f8ac1d80;
  border: 1px solid #f8ac1d80;
}

.service-card:hover {
  box-shadow: 0.5px 1px 2px #f8ac1d80;
  border: 1px solid #f8ac1d80;
}

.service-table {
  font-family: "Poppins";
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.service-table tbody tr td,
.service-table tbody tr th {
  font-size: 0.8rem;
  color: #444444;
  text-transform: uppercase;
}

.service-table tbody tr th {
  font-weight: 500;
}

.service-card-bottom {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2rem 0rem 0.2rem;
}

.service-card-bottom h5 {
  text-align: left;
  /* font: normal normal medium 14px/21px Poppins; */
  font-family: "Poppins";
  font-size: 0.8rem;
  letter-spacing: 0px;
  color: #f8ac1d;
  opacity: 1;
  font-weight: 400;
}

.service-card-bottom > h5:nth-child(1) {
  color: #389300;
}

.service-card-bottom > h5:nth-child(2) {
  color: #c10000;
}

.service-card-bottom > h5:nth-child(3) {
  color: #444444;
  font-weight: 600;
}

.yellow_color {
  color: #f8ac1d;
}

.EngChatBox-Dash {
  width: 18rem;
  height: 30rem;
  background-color: #fffbf4;
  border-radius: 5px;
  margin-top: 32px;
  margin-left: -17.9rem;
  position: absolute;
  z-index: 99999;
  box-shadow: 10px 10px 25px #00000029;
}

.EngChatBoxHead-Dash {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem;
  border-bottom: 1px solid #00000029;
}

.EngChatBoxIcons-Dash {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.4rem;
}

.EngChatBox-Dash h6 {
  text-wrap: nowrap;
  font-size: 1.1rem;
  font-size: 0.8rem;
  color: #444444;
  font-weight: 500;
  font-family: "Poppins";
  line-height: 1.3rem;
}

.EngChatMsg-Dash {
  height: 85%;
  width: 100%;
  position: relative;
  padding: 0.5rem;
}

.SubEngChatMsg-Dash {
  height: 94%;
  width: 100%;
  padding: 0rem 0.4rem;
  padding-bottom: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.engchatmsg-sender-side-dash {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 0.4rem;
}

.engchatmsg-reciver-message-dash,
.engchatmsg-sender-message-dash {
  max-width: 70%;
  border-radius: 5px;
  padding: 0.5rem 0.5rem;
  font-size: 0.9rem;
  text-wrap: wrap;
  line-height: 1.1;
  text-align: start;
  font-family: "Poppins";
}

.engchatmsg-sender-message-dash {
  color: #444444;

  /* background: #fef3de; */
  margin-left: 8.4rem;
  margin-top: 0.2rem;
  word-wrap: break-word;
  background-color: #fef3de;
  font-family: "Poppins";
  box-shadow: 1px 2px 5px #00000029;
  opacity: 1;
}

.engchatmsg-reciver-side-dash {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.4rem;
}

.engchatmsg-reciver-side-dash {
  /* padding: 10px 6px; */
  margin-top: 0.5rem;
  font-family: "Poppins";
  margin-right: 0.3rem;
  word-wrap: break-word;
}

.engchatmsg-reciver-message-dash {
  color: #000;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 2px 5px #00000029;
  opacity: 1;
  margin-top: 0.5rem;
  font-family: "Poppins";
  margin-left: 0.3rem;
  word-wrap: break-word;
}

.eng-card-message-text-dash {
  position: absolute;
  bottom: 15px;
  max-height: 10rem;
  width: 100%;
  padding: 1rem;
}

.text-area-message-eng-card-dash {
  width: 85%;
  outline: none;
  border: none;
  border-radius: 50px;
  box-shadow: inset 1px 2px 3px #00000029;
  position: relative;
  padding: 15px 12px;
  top: -45px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #444444;
  padding-right: 80px;
  margin-bottom: 10px;
}

/* .text-area-message-eng-card:hover::-webkit-scrollbar-thumb {
  border: 1px solid #f8ac1d; 
} */
/* set scroll behaviour  */
.text-area-message-eng-card-dash::-webkit-scrollbar {
  width: 1px;
  /* width of the entire scrollbar */
}

.text-area-message-eng-card-dash::-webkit-scrollbar-track {
  background: 0;
  /* color of the tracking area */
  margin-top: 7px;
  margin-bottom: 5px;
}

.text-area-message-eng-card-dash::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 1px solid #f8ac1d;
  /* creates padding around scroll thumb */
  transition: background-color 0.3s ease;
  /* Adjust the transition duration as needed */
}

.agdam-eng-card-dash {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
  position: relative;
  width: 100%;
}

.user-attachment4-eng-card-dash {
  position: absolute;
  height: 100px;
  right: 20px;
  display: flex;
  gap: 15px;
  font-size: 1.5rem;
  /* padding: 0px 0px; */
  color: #f8ac1d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  top: -65px;
  cursor: pointer;
}

.send-messsage-eng-card-dash {
  background: #fef3de;
  border-radius: 50%;
  text-align: center;
  padding: 10px;
  margin-top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-area-message-eng-card-dash ::placeholder {
  color: #4444441f;
  opacity: 0.5;
}

/* ---------------------------------------------------ayush css End  eng chat end aayush csss */

/* css for thr message box inside the Engg crouser starts -------------------------------- */

.loader {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  gap: 10px;
}

.box {
  height: 50px;
  width: 50px;
  margin-top: 40%;
  border-radius: 50%;
  border: 3px solid;
  border-color: orange transparent;
  animation: spin 2s infinite ease-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* css for thr message box inside the Engg crouser ends -------------------------------- */

/*-----------------------------------------add engg button and add Engg Modal css in the top bar starts --------------------------------------------- */
.add-Engg-button {
  font-size: 0.8rem;
  border: 1px solid #f8ac1d;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #444444;
  font-weight: 500;
  padding: 5px 14px;
  cursor: pointer;
  border-radius: 7px;
}

.add-engg-wrapper {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  z-index: 5;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
}

.add-engg-modal {
  height: 80%;
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 85%;
  background: white;
  z-index: 6;
  border-radius: 5px;
  transform: translate(-50%, -50%);
}

/*-------------add engg button  css in the top bar end --------------------------------- */

.EnggAddressSection {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  width: 45%;
  height: 75vh;
  position: relative;
}

.EnggDetailSection {
  width: 100%;
  height: 30%;
  position: relative;
}

.EnggDetailHeading {
  height: 25px;
  width: 100%;
  position: relative;
}

.BankWorkSection {
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 45%;
}

.outerMainMoodule {
  display: flex;
  justify-content: center;
  align-items: flex-startF;
  gap: 2rem;
  padding: 2rem 0.7rem;
  position: relative;
  height: 100%;
  width: 100%;
}

.EnggDetailInputField {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  gap: 2rem;
}

.personalDetailSec {
  height: 80%;
  width: 70%;
  display: flex;
  gap: 0.8rem;
  white-space: nowrap;
  justify-content: center;
  flex-direction: column;
}

.personalDetailSec .PersonalDetailInput {
  display: flex;
  gap: 1rem;
}

.EngCardDetailsL img {
  object-fit: fill;
  object-position: top;
}

.EnggDetailInputField .imageUploadDiv {
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  height: 70%;
  width: 20%;
  overflow: hidden;
  position: relative;
}

.EnggDetailInputField .imageUploadDiv input {
  display: none;
}

.personalDetailSec .PersonalDetailInput input {
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  padding: 10px;
  font-family: "poppins";
  width: 50%;
}

/* for erorr border */
.errorBorder {
  border: 1px solid #cf352e !important;
}

.personalDetailSec .PersonalDetailInput input:focus {
  border: 1px solid #fff;
  outline: 1px solid #f8ac1dad;
}

.personalDetailSec .PersonalDetailInputEmail input:focus {
  border: 1px solid #fff;
  outline: 1px solid #f8ac1dad;
}

.ExtraCiricularSectionInputFields .EnggAddressInput input:focus {
  border: 1px solid #fff;
  outline: 1px solid #f8ac1dad;
}

.ExtraCiricularSectionInputFields .PersonalDetailInput input:focus {
  border: 1px solid #fff;
  outline: 1px solid #f8ac1dad;
}

.inputWithAttachment.changeBorderInputColor {
  border: 1px solid #f8ac1dad;
  /* outline: 1px solid red; */
}

.inputWithAttachment6 {
  border: 1px solid #f8ac1dad;
  /* outline: 1px solid red; */
}

.checkbox.style-c .input {
  outline: none;
  border: none;
}

.personalDetailSec .PersonalDetailInputEmail input {
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  padding: 10px;
  font-family: "poppins";
  /* width: 100%; */
  width: 50%;
}

.PersonalDetailInputEmail {
  display: flex;
  gap: 1rem;
}

.EnggDetailHeading {
  border-bottom: 1px solid #444444;
  padding-bottom: 4px;
  font-size: 1rem;
  font-weight: 500;
}

.ExtraCiricularSectionInputFields {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 22px;
}

.ExtraCiricularSectionInputFields .EnggAddressInput input {
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  padding: 10px;
  font-family: "poppins";
  width: 100%;
}

.ExtraCiricularSectionInputFields .PersonalDetailInput {
  display: flex;
  width: 100%;
  gap: 0.5rem;
}

.ExtraCiricularSectionInputFields .PersonalDetailInput input {
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  padding: 10px;
  font-family: "poppins";
  width: 100%;
}

.mainPersonalDetialSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.inputWithAttachment {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  padding: 0px 3px;
  font-family: "poppins";
  width: 100%;
}

.inputWithAttachment6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  padding: 0px 3px;
  font-family: "poppins";
  width: 100%;
}

/* 44444444444444444444444444444444444444444444444444444444444444 */
.inputWithAttachment2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid red;
  border-radius: 10px;
  padding: 0px 3px;
  font-family: "poppins";
  width: 100%;
}

.PersonalDetailInput .inputWithAttachment input {
  border: none;
}

.PersonalDetailInput .inputWithAttachment6 input {
  border: none;
}

.PersonalDetailInput .inputWithAttachment2 input {
  border: none;
}

.inputWithAttachment .css-b62m3t-container {
  width: 100%;
}

.inputWithAttachment6 .css-b62m3t-container {
  width: 100%;
}

.inputWithAttachment2 .css-b62m3t-container {
  width: 100%;
}

.addEnggButtons {
  margin-left: 26%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  height: 5vh;
  width: 30;
  position: absolute;
  bottom: 3%;
  right: 5%;
}

.addEnggButtons {
  margin-left: 26%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  height: 5vh;
  width: 30;
  position: absolute;
  bottom: 6%;
  right: 5%;
}




/* ---------------------edit engg form------------------------------ */

.buttoncssTricks {
  background: #0000;
  border: 1px solid #f8ac1d;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  padding: 12px 50px;
  transition: background 0.1s ease-in-out;
  transition-delay: 0.1s;
}

.buttoncssTricks:hover {
  background: #f8ac1d;
  color: #000;
}

/* --------------------------------------------------- */

/* CSS */
.button-69,
.button-69-cancel {
  background-color: initial;
  border-radius: 5px;
  border-style: none;
  color: #444444;
  cursor: pointer;
  display: inline-block;  
  font-size: 1rem;
  font-weight: 500;
  padding: 0.8rem 2.5rem;
  text-align: center;
  background: #f8ac1d;
  font-weight: 600;
  font-style: "poppins";
}

.button-69-cancel {
  background: white;
  border: 1px solid #f8ac1d;
}

.button-69:hover {
  opacity: 0.7;
}

.workExperienceCheckbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  width: 100%;
}

.checkboxes__item {
  margin-top: 3px;
  margin-right: -10px;
}

.smallifcCodeSuggestion {
  width: 100%;
  height: 9px;
}

.smallifcCodeSuggestion p {
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 6px;
}

/* pdf container for client table */

.doc-container {
  height: 50px;
  width: 90px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  top: 3%;
  right: 2%;
  z-index: 99;
  background-color: white;
  font-size: 1.5rem;
}

.doc-container img {
  height: 30px;
  width: 30px;
}

.CurrentComponentName p {
  color: #f8ac1d;
  font-size: 0.9rem;
  text-align: center;
  white-space: nowrap;
}

.addFileName {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.addFileName p {
  color: #f8ac1d;
  font-size: 0.7rem;
}

.addSelectRole {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.addSelectRole p {
  color: #f8ac1d;
  font-size: 0.7rem;
}

/*-----------------------------------------add engg button and add Engg Modal css in the top bar starts --------------------------------------------- */

/* -------------------------------------------------------css for client modal data and wrraper and container css write on top with other cards---------------------------------------------------------------------------------------------- */

/* -------------------------------------------------------end of css for client modal data and wrraper and container css write on top with other cards---------------------------------------------------------------------------------------------- */
.client-modal-profile-container {
  height: 15%;
  width: 100%;
  position: relative;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}

.client-modal-card-container {
  height: 85%;
  /* background-color: slategray; */
  width: 100%;
  border-radius: 0.8rem;
  border: 1px solid #e5e5e5;

  position: relative;
  padding: 1rem;
}

.client-modal-profile-container-left {
  width: 20%;
  height: 100%;
  display: flex;
  cursor: pointer;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
}

.client-modal-profile-container-right {
  width: 50%;
  height: 100%;
  display: flex;
  margin-right: 1rem;
  align-items: center;
  justify-content: space-around;
}

.client-modal-img-container {
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 10px;
  background: #fff;
}

.client-modal-text-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 0.5rem;
  justify-content: start;
}

.client-modal-text-container h5 {
  text-transform: capitalize;
}

.client-modal-dropdown {
  height: 2.8rem;
  width: 6rem;
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

/*----------------------------- some changes by Raj  ----------*/

.dropdown-icon-container {
  height: 100%;
  width: 100%;
  display: flex;
  color: #8e8e8e;
  align-items: center;
  justify-content: center;
  position: relative;
  text-transform: capitalize;
  padding: 0 5px;
}

.dropdown-icon-container-img {
  margin-top: 0.1rem;
  display: flex;
  margin-left: -1rem;
  align-items: center;
  gap: 0.3rem;
}

.dropdown-icon-container h6 {
  color: #dedede;
  text-transform: capitalize;
  font-weight: 400;
}

.dropdown-icon-container p {
  font-size: 0.8rem;
  cursor: pointer;
}

.icon-size {
  font-size: 2rem;
  color: #000;
  position: absolute;
}

/* ------Add Some code by Raj data = 30/4/2024-------------- */

.client-modal-drodown-options {
  min-height: 120px;
  width: 100%;
  background: #ffffff;
  z-index: 9999;
  position: absolute;
  border: 1px solid #8e8e8e;
  border-radius: 10px;
  margin-top: 0.5rem;
  border-radius: 5px;
}

.client-modal-dropdown-option {
  display: flex;
  gap: 13px;
  align-items: center;
  margin-top: 0.4rem;
  width: 100%;
  padding-top: 0.4rem;
  height: 1.6rem;
  flex-direction: column;
  border-bottom: 1px solid lightgrey;
}

.second-dropdown .client-modal-dropdown-option {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 0;
  height: 2.6rem;
  border-bottom: 1px solid lightgrey;
  padding-top: 9px;
}

.second-dropdown .client-modal-dropdown-option p {
  border-bottom: none;
  margin-top: 0;
}

.second-dropdown .client-modal-dropdown-option:nth-child(4) {
  border-bottom: none;
  padding-top: 12px;
  height: 2.8rem;
}

.third-dropdown .client-modal-dropdown-option:nth-child(6) {
  border-bottom: none;
}

.client-modal-dropdown-option:nth-child(4) {
  border-bottom: none;
}

.second-dropdown .client-modal-dropdown-option:nth-child(1):hover {
  color: #0f351d;
  background-color: #d6f8bf;
}

.second-dropdown .client-modal-dropdown-option:nth-child(2):hover {
  color: #ff7f00;
  background-color: #f3dcc6;
}

.second-dropdown .client-modal-dropdown-option:nth-child(3):hover {
  color: #f8ac1d;
  background-color: #fee2ae;
}

.second-dropdown .client-modal-dropdown-option:nth-child(4):hover {
  color: #8e8e8e;
  background-color: #e5e5e5;
}

.client-modal-drodown-options p {
  font-size: 0.9rem;
  margin-top: -0.375rem;
  color: #bababa;
  text-align: center;
  transition: transform 0.1s;
  cursor: pointer;
  text-transform: capitalize;
}

.client-modal-drodown-options p:hover {
  transition: all 0.1s;
  color: #f8ac1dad;
}

/* -------------------CSS for third-dropdown---------------- */

.third-dropdown .client-modal-drodown-options {
  width: auto;
  padding: 5px 0px;
  width: 11rem;
}

.third-dropdown .client-modal-dropdown-option {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 2rem;
  border-bottom: 1px solid lightgrey;
  padding-top: 9px;
  text-align: center;
  margin-left: 0px;
}

.third-dropdown .client-modal-dropdown-option p {
  border-bottom: none;
}

.green-padding {
  padding: 0.4375rem 1rem;
  border-radius: 3.125rem;
}

/* ---------------------------------------------------------------make by aayush for report image preview----------------------------------------------------------------------------------- */
.images {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: #00000029;
}

.image-container {
  height: 500px;
  width: 500px;
  position: relative;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
}

.image-container img {
  height: 100%;
  width: 100%;
}

.report-image-prev,
.report-image-next {
  height: 40px;
  width: 40px;
  background: transparent;
  cursor: pointer;
  cursor: pointer;
}

.report-image-next {
  margin-left: 1rem;
}

.report-image-prev {
  margin-right: 5.6rem;
}

.report-image-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.image-container-bottom {
  height: 50px;
  width: 100%;
  background-color: #1d1d1d80;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2rem;
}

.image-container p {
  color: #f5f5f5;
  font-family: "Poppins";
  text-align: right;
  width: 430px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1rem;
  overflow: hidden;
}

.learn-next {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
}

.learn-next {
  width: 12rem;
  height: auto;
  cursor: pointer;
}

.learn-next .circlenext {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #f8ac1d;
  border-radius: 1.625rem;
  cursor: pointer;
}

.learn-next .circlenext .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #444444;
}

.learn-next .circlenext .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
  /* transform: rotate(-90deg); */
}

.learn-next .circlenext .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #444444;
  border-right: 0.125rem solid #444444;
  transform: rotate(45deg);
}

.learn-next .button-text-next {
  position: absolute;
  left: 50%;
  top: 25%;
  white-space: nowrap;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 0.8em;
  font-weight: 600;
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
}

.learn-next:hover .circlenext {
  width: 60%;
}

.learn-next:hover .circlenext .icon.arrow {
  background-color: #444444;
  transform: translate(1rem, 0);
}

.learn-next:hover .button-text-next {
  color: #444444;
  opacity: 1;
}

.learn-previous {
  width: 7.2rem;
  height: auto;
  display: inlin-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  left: -25px;
}

.learn-previous .circleprev {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #f8ac1d;
  right: -67px;
  border-radius: 1.625rem;
}

.learn-previous .circleprev .iconprev {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.learn-previous .circleprev .iconprev.arrowprev {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: calc(100% - 1.625rem - 0.125rem);
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.learn-previous .circleprev .iconprev.arrowprev::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  left: -0.009rem;
  right: 0.1rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #444444;
  border-right: 0.125rem solid #444444;
  transform: rotate(-135deg);
}

.learn-previous .button-text-prev {
  position: absolute;
  left: 18px;
  top: 25%;
  white-space: nowrap;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 0.8em;
  font-weight: 600;
  transition: all 0.45s cubic-bezier(0.75, 0, 0.076, 1);
}

.learn-previous:hover .circleprev {
  width: 100%;
  right: 0;
}

.learn-previous:hover .circleprev .iconprev.arrowprev {
  background: #444444;
  transform: translate(-1rem, 0);
}

.learn-previous:hover .button-text-prev {
  color: #444444;
  opacity: 1;
}

/* -------------------------------------------------------------------------all media query here bro ----------------------------------------------------------------------------------- */

/* @media only screen and (min-width: 769px) and (max-width: 1280) {
  .wrapper {
    display: none;
  }

} */

@media only screen and (min-width: 1550px) and (max-width: 1885px) {
  .dropdown-content {
    position: absolute;
    z-index: 1;
    margin-left: -7rem;
    width: 160px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 4px;
    background-color: #fff;
    top: 11%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1550px) {
  .tasks-section {
    width: 35%;
  }

  .Report-section {
    width: 33%;
    animation: changeText 1s linear forwards;
  }

  .location-section {
    width: 33%;
    animation: changeText 1s linear forwards;
  }

  .ticket-card {
    margin-bottom: 0;
  }

  .ticket-table tbody tr td,
  .ticket-table tbody tr th {
    font-size: 0.7rem;
  }

  .left-side-heading {
    font-size: 1.2rem;
  }

  .top-icon-bell,
  .filter-icon,
  .filter-top-icon,
  .admin-filter-top-icon,
  .plus-icon {
    padding: 7px 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    box-shadow: 1px 2px 5px #00000029;
    font-size: 25px;
    color: #343434;
    height: 36px;
    width: 77px;
    cursor: pointer;
  }

  .top-icon {
    padding: 1.2rem 0.5rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    border-radius: 7px;
    cursor: pointer;
    height: 35px;
    box-shadow: 1px 2px 5px #00000029;
  }

  .right-side-icons {
    gap: 0.7rem;
  }

  .top-icon:hover .search-input {
    padding: 0rem 0.1rem;
  }

  .inputSearchWritten {
    padding: 0 0;
  }

  .iconColor {
    color: #343434;
    height: 1.2rem;
  }

  .ticket-card {
    padding: 1rem;
    padding-right: 1rem;
  }

  .ticket-card-bottom {
    margin-top: 0.5rem;
    padding: 0rem;
  }

  .service-card-bottom {
    margin-top: 0.5rem;
    padding: 0rem;
  }

  .ticket-sub-card-row {
    gap: 1.5rem;
  }

  .ticket-sub-card-row-right h5,
  .ticket-sub-card-row-left h5 {
    font-size: 0.6rem;
  }

  .task-top-section p {
    font-size: 1rem;
  }

  .task-description-section {
    height: 220px;
    max-height: 240px;
  }

  .more-descriptive-report {
    height: 240px;
  }

  .ClientName h5 {
    font-size: 0.7rem;
  }

  .IssueDoor3L h5 {
    font-size: 0.7rem;
  }

  .IssueDoor3R h5 {
    font-size: 0.7rem;
  }

  .IssuesDoor4 h5 {
    font-size: 0.7rem;
    text-align: start;
  }

  .IssueDoors {
    height: 20%;
    width: 100%;
  }

  .IssuesDoor4 {
    height: 20%;
  }

  .IssuesDoor1 h5 {
    font-size: 0.6rem;
  }

  .RedIsue {
    margin-bottom: 1.4rem;
  }

  .CarouselButtons {
    width: 90%;
  }

  .ComponentNames p {
    /* font-size: 0.3rem; */
  }

  .CurrentComponentName p {
    font-size: 0.7rem;
  }

  .iconSize {
    height: 0.8rem;
  }

  .location-section .more-descriptive {
    height: 230px;
  }

  .ReportTable h5 {
    font-size: 0.6rem;
  }

  .ReportTable h6 {
    font-size: 0.5rem;
  }

  .ReportTable h5 span {
    font-size: 0.6rem;
  }

  .membership-form-row p {
    white-space: nowrap;
  }

  .membership-form-col1 {
    width: 50%;
  }

  .req-elevator-row p {
    white-space: nowrap;
  }

  .req-elevator-col1 {
    width: 33%;
  }

  .doc-container {
    height: 30px;
    width: 40px;
    top: 4%;
    right: 3%;
  }

  .doc-container img {
    height: 20px;
    width: 20px;
  }

  .my_table-container table th,
  .service-request-table table td,
  .my_table-container table td,
  .service-request-table table th,
  .service-schedule-table table td,
  .service-schedule-table table th {
    font-size: 0.8rem;
  }

  .dropdown-content {
    top: 7.5%;
    position: absolute;
    z-index: 1;
    margin-left: -7rem;
    width: 160px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 4px;
    background-color: #fff;
  }

  /* -------------------------------------------------------------------------------------add eng modal responsive here----------------------------------------------------------- */
  .add-engg-modal {
    height: 95%;
    width: 85%;
  }

  .outerMainMoodule {
    gap: 1.5rem;
    padding: 0.8rem 0.4rem;
  }

  .EnggAddressSection {
    gap: 0.5rem;
    width: 45%;
    height: 85vh;
  }

  .addform-modal-container {
    height: 95vh;
  }

  .cross-icon {
    right: 4px;
  }
}

/* 
  @media only screen and (min-width: 1201px) and (max-width:1280px){
  
  .wrapper{
    display: none;
  }
  }  */

/* ----------------------------------------------------------- css will Resum on 2nd Updates --------------------------------------------- */

/*  map container css starts in task location section inside the dashboard */
.map-container {
  width: 100%;
  height: 100%;
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

#GMapsID div div a div img {
  display: none;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

.gmnoprint {
  display: none;
}

.gm-style > div > div > a > div > img {
  display: none;
}

/*  map container css ends in task location section inside the dashboard */

/* select department css starts */
.department-main-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 5rem;
  row-gap: 2em;
  margin-top: 2rem;
  padding: 10px;
}

.selected-border {
  border: 2px solid #f8ac1dad;
}

.department-main-div .department-name-div {
  padding: 12px;
  border-radius: 10px;
  background: radial-gradient(
    rgb(244, 242, 242) 30%,
    rgba(216, 210, 210, 0.587)
  );
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  filter: opacity(1);
  cursor: pointer;
  width: 100%;
  justify-content: start;
}

.department-name-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 100%;
}

.department-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffc1;
}

.department-tag {
  font-size: 14px;
  color: #1d1d1d;
}

.department-name-div .department-icon {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  padding: 8px 8px;
  border-radius: 50%;
}

.check-tick {
  width: 20px;
  height: 20px;
  color: rgb(248, 172, 29);
  position: absolute;
  top: -8px;
  right: -8px;
}

.Proceed-button {
  margin-top: 1rem;
  padding: 1.2rem 6rem;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  background: #f8ac1d 0% 0% no-repeat padding-box;
  color: #1d1d1d;
  font-size: 16px;
}

@media only screen and (max-width: 1500px) {
  .department-main-div {
    column-gap: 2rem;
    row-gap: 1.5em;
    padding: 0px 20px;
  }
}

/* select department css ends */

.user-notification-image .icon-styling {
  border: 1px solid #444444;
  border-radius: 50%;
  padding: 0px 9px;
}

.css-y7kf8-placeholder {
  grid-area: 1 / 1 / 2 / 3;
  color: rgb(128, 128, 128);
  margin-left: 2px;
  margin-right: 2px;
  font-size: 0.8rem;
  /* margin-bottom: 9px!important; */
  font-family: Poppins;
  opacity: 0.6;
}

.css-7sud1x-control {
  justify-content: center;
  align-items: center;
}

.css-y7kf8-placeholder {
  margin-bottom: 6px;
}

.css-1hb7zxy-IndicatorsContainer {
  position: relative;
  top: -4px;
}

/*service history css start */
/* --------------------Rahul Kumar---------------------------------- */
.service-history-wrapper {
  display: flex;
  width: 100%;
  padding: 1rem;
  flex-direction: column;
  margin-top: 1rem;
  text-transform: capitalize;
}

.service-history-client-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4rem;
}

.service-history-heading > h6 {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  color: #1d1d1d;
  font-weight: 500;
}

.service-icon-color {
  color: #fac665;
  font-size: 1.5rem;
}

.service-history-hr {
  height: 1px;
  background-color: #707070;
  margin: auto;
  opacity: 0.4;
}

.hr-client-width {
  width: 90%;
}

.hr-architect-width {
  width: 88%;
}

.hr-salesman-width {
  width: 87%;
}

.service-history-heading {
  display: flex;
  width: 100%;
  align-items: center;
}

.service-history-client-details-child-wrappper {
  display: grid;
  grid-template-columns: repeat(5, 2.4fr);
  margin-top: 1rem;
}

.service-history-client-heading {
  color: #8e8e8e;
  font-size: 1rem;
}

.service-history-client-child-color {
  color: #444444;
  font-size: 1rem;
  width: 80%;
}

.sosIcon {
  width: 23px;
  height: 23px;
  object-fit: cover;
}

.sosrequest_table_view {
  margin-top: 6vw;
  height: 78vh;
}

/* .sub_table_view {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 9;
  z-index: 999;
  height: 4rem;
} */

.main-container_sos {
  /* background-color: lightpink; */
  width: 78vw;
  margin-left: 3rem;
}

.sosrequest_table_view_inside {
  margin-top: 1vw;
  height: 100%;
  width: 100%;
  background-color: white;
  position: relative;
  /* background-color: yellow; */
  background: #ffffff;
  box-shadow: 0px 0px 3px #c1c1c1;
  border-radius: 10px;
  padding: 1rem 2rem;
}

.xcelIcon {
  color: green;
  cursor: pointer;
}

.pdfIcon {
  color: #f40f02;
  cursor: pointer;
}

/* client form responsive brekpoint min width 769 or max width 1550px  by raj  */

@media only screen and (min-width: 769px) and (max-width: 1550px) {
  .client-form-details-heading {
    font-weight: 500;
    font-size: 0.8rem;
  }

  .client-salesman-details {
    padding: 1rem 2rem 0 2rem !important;
  }

  .option-container {
    position: absolute;
    border: 1px solid #70707080;
    border-radius: 10px;
    color: #8e8e8e;
    font-size: 1rem;
    width: 100%;
    height: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.6rem;
    opacity: 1;
    margin-top: 0.4rem;
    font-family: "Poppins";
    background-color: #fff;
    z-index: 10;
  }

  .option-container > ul {
    list-style-type: none;
    opacity: 0.54;
    font-size: 0.8rem !important;
  }

  .client-form-input-wrapper {
    padding-top: 0.5rem;
    display: grid;
    grid-template-columns: 46.5% 46.5%;
    grid-column-gap: 1.1rem;
    grid-row-gap: 0.8rem;
    margin-top: 0.5rem;
  }

  /* .input-field {
    font-size: 13px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #44444462;
    opacity: 1;
    padding: 3px 0;
    background-color: transparent;
    outline: none;
    font-family: "Poppins";
    color: #1d1d1d;
    cursor: default;
    font-weight: 400;
    margin-top: -1.2rem !important;
  } */

  .input-label {
    position: absolute;
    top: 0;
    left: 0;
    letter-spacing: 0.91px;
    color: #1d1d1d;
    transition: all 0.3s ease;
    pointer-events: none;
    font-size: 0.85rem !important;
    margin-top: 0.09rem;
  }

  /* --------------------------------------------------- */

  .client-form-left-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    width: 38% !important;
  }

  .client-form-right-container {
    width: 100% !important;
  }

  .client-form-container {
    display: flex !important;
    flex-direction: row !important;
    column-gap: 10rem !important;
  }

  .quotation {
    color: #444444;
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .inputLabel {
    position: absolute;
    font-family: "Poppins";
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.3s;
    opacity: 0.5;
    font-size: 0.7rem;
  }

  .filename {
    cursor: pointer;
    font-size: 0.7rem;
    font-family: "Poppins";
  }

  .calendarIcon {
    width: 0.9rem;
    height: auto;
  }

  .calendarContainer {
    position: absolute;
    z-index: 5;
    width: 17.5rem;
  }

  .clientDateInput {
    outline: none;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #44444473;
    cursor: text;
    width: 100%;
    padding-bottom: 0.5rem;
    padding-top: 0.55rem;
    font-size: 0.8rem;
    color: #444444;
  }

  .clientDateContainer {
    position: relative;
    display: flex;
    align-items: center;
    font-family: "Poppins";
    margin-top: -0.35rem;
  }

  .dropdown-input {
    outline: none;
    border: none;
    border-bottom: 1px solid #1d1d1d;
    opacity: 0.5;
    padding-bottom: 0.4rem;
    width: 100%;
    font-family: "Poppins";
    cursor: pointer;
    position: relative;
    font-size: 0.7rem;
  }

  .add-client-modal {
    height: 93%;
    width: 85%;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 85%;
    background: white;
    z-index: 6;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    padding: 0 1rem;
    overflow: hidden;
  }

  .sub-menu-wrap {
    width: 190px;
  }
}

.logo-heading {
  transition: all 0.5s ease;
}

.logo-open-heading {
  margin-top: 14px;
  font-size: 1.1rem;
  font-weight: 500;
}

.logo-close-heading {
  margin-top: 30px;
  font-size: 0.9rem;
  font-weight: 400;
}

.no-rating {
  font-size: 1rem;
}

/* --------------------------client form responsive by raj end------------------------------------------------------------------------------- */

/*----------------------------------------------------------------------------- media query for final responsive ness here by aayush malviya 26-07-2024 -------------------------------------------------------------------------------------- */

@media only screen and (min-width: 769px) and (max-width: 1550px) {
  .top-bar {
    height: 60px;
    padding-left: 15.5rem;
  }

  .sidebarOpenWidth {
    width: 16.5rem;
  }

  .left-side-heading-close {
    margin-left: -6.7rem;
  }

  .user-info-open {
    width: 13rem;
    pointer-events: "auto";
    padding-left: 0.5rem;
  }

  .user-info-close {
    width: 4.5rem;
  }

  .nav-open {
    width: 12.8rem;
  }

  .logo-open {
    margin-left: -5.1rem;
  }

  .main-crouser {
    height: 8.5rem;
  }

  .message-box-crouser {
    font-size: 1.08rem;
  }

  .logo-image-open {
    width: 90px;
  }

  .logo-image-close {
    width: 54px;
  }

  .ineer-menue img {
    height: 45px;
    width: 45px;
  }

  .main-profile-item {
    font-size: 9px;
  }

  .main-profile-item p {
    font-size: 11px;
  }

  .main-menu-adjust {
    font-size: 0.7rem;
  }

  .icon,
  .link_text {
    font-size: 0.8rem;
  }

  .link {
    margin-top: 0.001rem;
  }

  .fixed-content-navbar {
    padding: 0px 0.4rem;
  }

  .dropdown3-menu {
    left: -10.5rem;
    margin-top: -3.3rem;
  }

  .dropdown1-menu {
    margin-top: 1.2rem;
  }

  /* .dropdown-menu {
    width: 150px;
  } */

  .tasks-heading {
    font-size: 17px;
  }

  .tasks-heading-on-no-task {
    font-size: 1rem;
  }

  .taskmain-info {
    font-size: 12px;
  }

  /* .skill-box {
    width: 90%;
  } */

  .dropdown2,
  .dropdown {
    margin-top: -1rem;
  }

  .dropdown1-menu {
    margin-left: -0.7rem;
    margin-top: 0.2rem;
  }

  .task-top-section p {
    font-size: 0.9rem;
  }

  .switch-button p {
    font-size: 14px;
  }

  .ticket-card {
    height: 5rem;
  }

  .service-card {
    height: 5rem;
  }

  .skill-box .skill-bar {
    margin-top: -3rem;
    position: absolute;
    width: 87%;
  }

  .skill-box .skill-bar-ontask {
    margin-top: -0.7rem;
    position: absolute;
    width: 90%;
  }

  .logo-open-heading {
    font-size: 1rem;
  }

  .logo-close-heading {
    font-size: 0.7rem;
  }

  .sub-menu {
    font-size: 0.8rem;
  }

  .engg-profile {
    font-size: 0.7rem;
  }

  .basic-info-profile {
    height: 40px;
    width: 40px;
    object-fit: cover;
    object-position: center;
  }

  .EngChatBox-Dash {
    width: 15rem;
    height: 24rem;
    margin-top: 31px;
    margin-left: -14.7rem;
  }

  .text-area-message-eng-card-dash {
    width: 85%;
    top: -47px;
    font-size: 0.6rem;
    margin-bottom: 13px;
  }

  .engchatmsg-reciver-message-dash,
  .engchatmsg-sender-message-dash {
    font-size: 0.7rem;
  }

  .file-icon {
    margin-top: -10px;
  }

  .send-messsage-eng-card-dash {
    padding: 5px;
    margin-top: -15px;
  }

  .no-rating {
    font-size: 0.8rem;
  }

  .aday:hover p {
    color: #191919;
    font-size: 1.2rem;
    font-weight: 500;
    margin-left: 0.8rem;
  }

  .dropdown1-menu {
    width: 130px;
  }

  .dropdown2-menu {
    width: 130px;
  }

  .dropdown3-menu {
    width: 180px;
  }

  .upper-sec p:first-child {
    font-size: 11px;
  }

  .ticket-card-bottom h5,
  .service-card-bottom h5 {
    font-size: 0.7rem;
  }

  .task-description-section {
    gap: 0.8rem;
  }

  .ticket-section-heading span:nth-child(1) {
    color: #191919;
    font-size: 1rem;
  }

  .dropdown-content-filter {
    margin-top: 3.2rem;
  }

  .kanban-button-location {
    font-size: 0.5rem;
  }

  .kanban-button-location {
    padding: 0.5rem 1rem;
    border: 1px solid #f8ac1d;
  }

  .dot-progress,
  .dot-progress2 {
    margin: -0.5rem -0.6rem;
  }

  .dot-progress2 {
    margin: -0.5rem -0.5rem;
  }

  .dropdown2 {
    margin-right: -0.8rem;
  }

  .dropdown {
    margin-left: -0.8rem;
  }

  .tasks-heading-on-no-task {
    font-size: 0.8rem;
  }

  .hover-icon-service {
    font-size: 0.65rem;
  }

  .McRoomCard {
    height: 80%;
  }

  .McRoomCard p {
    font-size: 0.6rem;
    margin-left: -0.5rem;
  }

  .CarTopShift {
    max-height: 90vh;
  }

  .RatingContainer {
    width: 100%;
    padding: 0.5rem 0.9rem;
    gap: 0.25rem;
  }

  .RatingButton button {
    padding: 0rem 0.6rem;
    font-size: 0.5rem;
  }

  .link {
    display: flex;
    color: #343434;
    padding: 10px 8px;
    gap: 10px;
    margin-top: 0.1rem;
  }

  .McRoom {
    padding-top: 0.1rem;
  }

  .table-skeletron {
    width: 72.5vw;
  }

  .client-child-modal-container {
    padding: 1rem;
  }

  /* ------------------------------------------all forms respopnsive by aayush--------------------------------------------------------------------------------------------- */
  .req-elevator-section {
    height: 78%;
  }

  .req-client-section {
    height: 32%;
    padding-top: 0.7rem;
  }

  .req-elevator-details {
    height: 25%;
  }

  .modal-container {
    height: 97vh;
  }

  .req-elevator-section-right {
    height: 70%;
    padding-left: 6.56rem;
  }

  .grid-form-container2 {
    height: 90%;
    padding-top: 1.64rem;
    gap: 1rem;
  }

  .add-engg-modal {
    height: 95%;
    width: 78%;
  }

  .footer-section {
    margin-top: -90px;
    margin-right: 20px;
  }

  /* ------------------------------------------end all forms respopnsive by aayush--------------------------------------------------------------------------------------------- */
}

@media only screen and (min-width: 1371px) and (max-width: 1550px) {
  .req-client-section {
    height: 40%;
  }

  .req-elevator-section {
    height: 60%;
  }

  /* .buttons {
    margin-top: 5rem;
  } */

  .buttons {
    margin-bottom: 3.8rem;
  }

  .req-elevator-section {
    height: 55%;
  }

  .req-client-section {
    height: 45%;
    padding-top: 0.7rem;
  }

  .req-elevator-section-right {
    height: 100%;
    padding-left: 6.56rem;
  }

  .grid-form-container2 {
    height: 100%;
    gap: 2rem;
    align-items: flex-end;
    /* justify-content: flex-end; */
  }

  .add-client-modal {
    height: 85%;
  }

  .client-form-input-wrapper {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: 46.5% 46.5%;
    grid-column-gap: 1.1rem;
    grid-row-gap: 1.2rem;
    margin-top: 1rem;
  }

  .client-salesman-details {
    padding: 2.4rem 2rem 0 2rem !important;
  }

  .add-engg-modal {
    height: 90%;
    width: 78%;
  }

  .add-client-modal {
    height: 95%;
  }
}

/*----------------------------------------------------------------------------- end of media query for final responsive ness here by aayush malviya 26-07-2024 -------------------------------------------------------------------------------------- */

.sparePartContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.sparePartHeading {
  font-size: 16px;
  color: #343434;
  font-weight: 500;
}

.sunSparePartHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  width: 90%;
  margin-top: 10px;
  color: #343434;
  font-weight: 500;
}

.sparePartNames {
  font-size: 15px;
  width: 90%;
  margin-top: 6px;
  height: 17vh;
  overflow-y: auto;
}

.sparePartNames::-webkit-scrollbar-thumb {
  background-color: #f8ac1d;
}

.sparePartNames:hover::-webkit-scrollbar {
  width: 2px;
}

.sparePartNames::-webkit-scrollbar {
  width: 0px;
}

.spartpartnameswithParts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  width: 90%;
  margin-top: 8px;
}







/* -------------------------- css get Engginer Attendance starts---------------- */
.attendancepopup-main-heading{
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
  gap: 16px;
}
.attendancepopup-heading{
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
  gap: 16px;
  justify-content: center;
}

.attendancepopup-heading:hover{
  color: #f8ac1d;
}
/* -------------------------- css get Engginer Attendance Ends ---------------- */


